import React from 'react';
import './BlogPage.scss';
import search from '../../Assets/Icon/search.svg';
import { InputBox, SelectBox } from '../../Components';
import { ReactSVG } from 'react-svg';
import add from '../../Assets/Icon/add.svg';
import addCircle from '../../Assets/Icon/add-circular-outlined-button.svg';
import { toast } from "react-toastify";
import RichTextEditor from 'react-rte';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import {
    getMyblogsAction,
    getTopicListAction,
    postBlogAction,
    blogUpdateAction
} from "../../Redux/Actions";

const IMAGE_WIDTH_LIMIT = 600;
const IMAGE_HEIGHT_LIMIT = 300;

const ShowBoxs = [
    {id: 1, name: 'DRAFT'},
    {id: 2, name: 'PENDING'},
    {id: 3, name: 'PUBLISHED'},
]

class BlogPage extends React.Component {
    state = {
        searchText: '',
        depth: 0,
        selectedBlog: {},
        showBox: 'PUBLISHED',
        Blogs: [],        
        value: RichTextEditor.createEmptyValue()
    }
    componentDidMount() {
        this.props.getMyblogsAction();
        this.props.getTopicListAction();
    }

    static getDerivedStateFromProps(props, state) {
        return {
            depth: props.depth
        }
    }

    startNewBlog = () => {
        this.selectBlog({
            id: 0,
            title: '',
            status: 'draft',
            views: 0,
            topic: {
                id: 0,
                name: ""
            },
            category: '',
            keywords: '',
            picture: null,
            upload_blogImg: null,
            content: ''
        })
    }

    selectBlog = (item) => {
        this.setState({
            selectedBlog: item,
            value: RichTextEditor.createValueFromString(item.content, 'html')
        })
        this.props.updateTitle('push', 'Blog', 2);
    }

    pickFileForPhoto = (e) => {
        e.preventDefault();
        if (!e.target.files[0]) return;

        let imageFile = e.target.files[0];
        let fr = new FileReader();
        let self = this;
        fr.onload = () => {
            let img = new Image();
            img.onload = function() {
                if (img.width < IMAGE_WIDTH_LIMIT || img.height < IMAGE_HEIGHT_LIMIT) {
                    alert(`Please select image file over ${IMAGE_WIDTH_LIMIT}*${IMAGE_HEIGHT_LIMIT}`);
                    return;
                }
                self.getImage(imageFile);
            };
            img.src = fr.result;
        };
        fr.readAsDataURL(e.target.files[0]);
    }

    getImage = (img) => {
        const { selectedBlog } = this.state;
        this.setState({selectedBlog: {...selectedBlog, picture: URL.createObjectURL(img), upload_blogImg: img}});
    }

    saveBlog = (status) => {
        let { selectedBlog, Blogs, showBox } = this.state;
        this.props.loader(true)
        const formData = new FormData()
        if(selectedBlog.upload_blogImg){
            formData.append('picture', selectedBlog.upload_blogImg);
        }
        formData.append('topic_id', selectedBlog.topic.id);
        formData.append('title', selectedBlog.title);
        formData.append('content', selectedBlog.content);
        formData.append('status', status);

        if(selectedBlog.id === 0){
            this.props.postBlogAction(formData).then(()=>{
                if(this.props.postBlogStatus){
                    this.props.loader(false);
                    toast("Successfully!")
                    this.props.getMyblogsAction();
                } else {
                    this.props.loader(false);
                }
    
            })
        } else {            
            formData.append('_method', 'PUT');
            this.props.blogUpdateAction(formData, selectedBlog.id).then(()=>{
                if(this.props.blogUpdateStatus){
                    this.props.loader(false);
                    toast("Successfully!")
                    this.props.getMyblogsAction();
                } else {
                    this.props.loader(false);
                }
    
            })
        }
        this.props.formatTitle();
    }

    onChangeEditor = (e) => {
        this.setState({value: e})
        this.setState({
            selectedBlog: {
                ...this.state.selectedBlog,
                content: e.toString('html')
            }
        })        
    }

    render() {
        const {myBlogs, topicList} = this.props
        const { searchText, depth, selectedBlog, showBox } = this.state;
        const list = myBlogs.filter(blog => blog.title.toLowerCase().indexOf(searchText.toLowerCase()) > -1 && blog.status.toLowerCase() === showBox.toLowerCase());
        return (
            <div className="blog-page">
                <div className={`blog-list v-r ${depth === 0 ? 'activate' : ''}`}>
                    <InputBox className="search-contact" value={searchText} icon={search} placeholder="Search..." onChange={val=>this.setState({searchText:val})} />
                    <div className="filter-area v-c h-c">
                        <SelectBox value={showBox} className="box-select" onChange={val => this.setState({showBox: val.name})} options={ShowBoxs}/>
                        <div className="new-message v-c" onClick={e => this.startNewBlog()}> <ReactSVG src={addCircle} className="svg-icon" /> NEW BLOG </div>
                    </div>
                    <div className="blogs v-r scroll-area">
                        {list.map((item, index) => <div className={`blog-container ${selectedBlog.id === item.id ? 'selected' : ''}`} key={index} onClick={e => this.selectBlog(item)}>
                            <div className="blog v-r h-c">
                                <div className="info v-r h-c">
                                    <div className={`name-area `}>
                                        <h4 className="v-c">{item.title}</h4>
                                    </div>
                                    <p className="blog-details v-c">
                                        <span>Status: </span>
                                        <span className="detail-value">&nbsp;{item.status}</span>
                                        <span>Views: </span>
                                        <span className="detail-value">&nbsp;0</span>
                                    </p>
                                </div>
                            </div>
                        </div>)}
                    </div>
                </div>
                <div className={`content-area v-r ${depth >= 1 ? 'activate' : ''}`}>
                    {(selectedBlog.id >= 0) && <div className="content-header">{selectedBlog.id === 0 ? 'Create New Blog' : selectedBlog.title}</div>}
                    {(selectedBlog.id >= 0) && <div className="content-edit v-r">
                        <div className="guide-area v-c">
                            <p>Blog Guide</p>
                            <ReactSVG src={add} className="svg-icon" />
                        </div>
                        <div className="option-container v-c">
                            <div className="option-group v-r">
                                <h2 className="option-title">Topic</h2>
                                <SelectBox className="option-select" value={selectedBlog.topic.name} options={topicList} onChange={val => this.setState({selectedBlog: {...selectedBlog, topic: val}})}/>
                            </div>
                            {/* <div className="option-group v-r">
                                <h2 className="option-title">Keywords</h2>
                                <InputBox className="option-select" value={selectedBlog.title} placeholder="Type keywords" onChange={val => this.setState({selectedBlog: {...selectedBlog, keywords: val}})}/>
                            </div> */}
                        </div>
                        <div className="option-group v-r">
                            <h2 className="option-title">Title</h2>
                            <InputBox className="option-select" value={selectedBlog.title} placeholder="Type title" onChange={val => this.setState({selectedBlog: {...selectedBlog, title: val}})}/>
                        </div>
                        <div className="option-group v-r">
                            <h2 className="option-title">Upload blog cover (Minium {IMAGE_WIDTH_LIMIT}*{IMAGE_HEIGHT_LIMIT}) </h2>
                            <input type="file" accept="image/*" id="image_picker" style={{display:'none'}} onChange={e => this.pickFileForPhoto(e)}></input>
                            {!selectedBlog.picture ? <div className="no-image v-r v-c h-c">
                                <label htmlFor="image_picker"><ReactSVG src={addCircle} className="svg-icon" /></label>
                            </div> : <div className="image-container v-r">
                                <img src={selectedBlog.picture} alt="selectedImg" />
                                <label htmlFor="image_picker"><p>Edit</p></label>
                            </div>}
                        </div>
                        <div className="option-group v-r">
                            <h2 className="option-title">Content</h2>
                            <RichTextEditor
                                value={this.state.value}
                                onChange={this.onChangeEditor}
                            />
                            {/* <textarea value={selectedBlog.content} placeholder="Type content" onChange={val => this.setState({selectedBlog: {...selectedBlog, content: val.target.value}})}/> */}
                        </div>
                        {selectedBlog.status !== 'published' && <div className="option-container btn-container">
                            {selectedBlog.topic.name && selectedBlog.title && selectedBlog.picture && selectedBlog.content?
                                <button className="btn-2 btn-2-outline" onClick={e => this.saveBlog('draft')}>Save as Draft</button>
                                :
                                <button className="btn-2 ">Save as Draft</button>
                            }
                            
                            {selectedBlog.topic.name && selectedBlog.title && selectedBlog.picture && selectedBlog.content?
                                <button className="btn-2 primary-btn" onClick={e => this.saveBlog('pending')}>Submit to Review</button>
                                :
                                <button className="btn-2" >Submit to Review</button>
                            }
                        </div>}
                    </div>}
                </div>
            </div>
        );
    }
}

const mapStateToProps = (state) => ({
    myBlogs: state.Core.myBlogs,
    topicList: state.Core.topicList,
    postBlogStatus: state.Auth.postBlogStatus,
    blogUpdateStatus: state.Auth.blogUpdateStatus
});

export default connect(mapStateToProps, {
    getMyblogsAction,
    getTopicListAction,
    postBlogAction,
    blogUpdateAction,
})(withRouter(BlogPage));