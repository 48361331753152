import React from 'react';
import './BlockSchedulePage.scss';
import Calendar from 'react-calendar';
import left from '../../Assets/Icon/left.svg';
import right from '../../Assets/Icon/right.svg';
import { ReactSVG } from 'react-svg';
import moment from 'moment';
import bin from '../../Assets/Icon/rubbish-bin.svg';
import { toast } from "react-toastify";
import _ from 'lodash'
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import {
    getMypofileInfoAction,
    getBlockScheduleListAction,
    blockScheduleUpdateAction,
    cancelLessonAction
} from "../../Redux/Actions";

const HelpParams = [
    'booked', 'available', 'unavailable'
];

const Hours = [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23];

class BlockSchedulePage extends React.Component {
    state = {
        selected_date: '',
        week_data: [],
        schedule_data: [],
        tutor_id: '',
    }

    componentDidMount() {
        this.props.loader(true);
        this.selectDate(new Date());
        if(_.isEmpty(this.props.myProfileInfo)){
            this.props.getMypofileInfoAction().then(()=>{
                this.getTutorID();
            })
        } else {
            this.getTutorID();
        }
    }

    getTutorID = () => {
        if(this.props.myProfileInfo){
            this.setState({tutor_id: this.props.myProfileInfo.id})
            this.getBlockScheduleList()
        }
    }

    getBlockScheduleList = () => {
        this.props.getBlockScheduleListAction().then(()=> {                    
            var schedule_data = []
            if(this.props.blockScheduleList.length > 0){
                this.props.blockScheduleList.map((data, index)=>{
                    schedule_data[index] = {id: data.id, day: data.sched.split(' ')[0], hour: Number(data.sched.split(' ')[1].split(':')[0]), selected: false, status: "unavailable"}
                })
            }
            this.setState({schedule_data});
            this.props.loader(false);
        });
    }

    selectDate = (date) => {
        let week = [];
        for (let i = 0; i < 7; i++) {
            let temp = moment(date).startOf('week').add(i, 'days');
            week.push({
                whole_day: temp.format("YYYY-MM-DD"),
                day_only: temp.format("D"),
                week_day: temp.format("ddd")
            })
        }
        this.setState({ 
            selected_date : date,
            week_data: week
        })
    }

    checkSchedule = (d, h) => {
        let { schedule_data } = this.state;
        let currentIndex = schedule_data.findIndex(temp => temp.day === d && temp.hour === h);
        let prevIndex = schedule_data.findIndex(temp => temp.day === d && temp.hour === h - 1);
        let nextIndex = schedule_data.findIndex(temp => temp.day === d && temp.hour === h + 1);

        let last_time = null;
        let current = currentIndex === -1 ? '' : schedule_data[currentIndex].status;
        let prev = prevIndex === -1 ? '' : schedule_data[prevIndex].status;
        let next = nextIndex === -1 ? '' : schedule_data[nextIndex].status;
        if (current !== prev && current === next && current.length > 0) {
            for (let i = h + 1; i < 24; i++) {
                let tempIndex = schedule_data.findIndex(temp => temp.day === d && temp.hour === i);
                let tempVal = tempIndex === -1 ? '' : schedule_data[tempIndex].status;
                if (current === tempVal) {
                    last_time = i;
                } else {
                    break;
                }
            }
        }
        return {
            current, prev, next, last_time,
            selected: currentIndex === -1 ? false : schedule_data[currentIndex].selected,
        }
    }

    updateSchedule = (d, h, current) => {
        if (current.length > 0) return;
        let { schedule_data } = this.state;
        schedule_data.push({
            day: d,
            hour: h,
            status: 'unavailable',
            selected: false
        })
        this.setState({schedule_data})
        this.updateBlockSchedule(d, h);
    }

    selectSchedule = (e, d, h, current) => {
        e.stopPropagation();
        if (current === 'booked') return;
        let { schedule_data } = this.state;
        let index = schedule_data.findIndex(temp => temp.day === d && temp.hour === h);
        schedule_data[index].selected = !schedule_data[index].selected;
        this.setState({schedule_data});
    }

    delSchedule = (d, h) => {
        let { schedule_data } = this.state;
        let index = schedule_data.findIndex(temp => temp.day === d && temp.hour === h);
        let deleteId = schedule_data[index].id
        schedule_data.splice(index, 1);
        this.setState({schedule_data});
        this.deleteBlockSchedule(deleteId);
    }

    getHour = (hour) => {
        return `${hour <= 12 ? hour : hour === 24 ? 12 : hour - 12} ${hour < 12 ? 'am' : hour === 24 ? 'am' : 'pm'}`;
    }

    checkSelectedCount = () => {
        let { schedule_data } = this.state;
        return (schedule_data.filter(temp => temp.selected === true) || []).length;
    }

    deleteSelected = () => {
        let { schedule_data } = this.state;
        let filtered = schedule_data.filter(temp => temp.selected === true) || [];
        if (filtered.length > 0) {
            for (let item of filtered) {
                let index = schedule_data.findIndex(temp => temp.day === item.day && temp.hour === item.hour);
                schedule_data.splice(index, 1);
            }
        }
        this.setState({schedule_data});
    }

    updateBlockSchedule = (d, h) => {
        this.props.loader(true);
        var deploy = {
            student_id: this.state.tutor_id,
            timezone: moment.tz(moment.utc(), moment.tz.guess(true)).utcOffset()/60 ,
            sched: d + ' ' + h + ':' + '00',
            hours: 1,
            client_note: "note"
        }
        this.props.blockScheduleUpdateAction(deploy).then(()=> {
            const {blockScheduleUpdateStatus, blockScheduleUpdateErr} = this.props
            if(blockScheduleUpdateStatus){
                toast(blockScheduleUpdateStatus)
                this.getBlockScheduleList();
            } else {
                this.props.loader(false);
                toast(blockScheduleUpdateErr)
            }
        })
    }

    deleteBlockSchedule = (deleteId) => {
        this.props.loader(true);

        const formData = new FormData();
        formData.append('action', 'cancel');
        formData.append('reason', 'reason');
        formData.append('_method', 'PUT');

        this.props.cancelLessonAction(formData, deleteId).then(()=>{
            const {cancelLessonStatus} = this.props
            if(cancelLessonStatus){
                toast(cancelLessonStatus);
                this.getBlockScheduleList();
            } else {
                this.props.loader(false);
                toast('sorry, failed!');
            }
        })
    }

    render() {
        const { selected_date, week_data } = this.state;
        return (
            <div className="blockschedule-page">
                <div className="pre-setting v-r">
                    <div className="calendar-area">
                        <Calendar
                            className="sub-calendar"
                            onChange={this.selectDate}
                            value={selected_date || new Date()}
                            calendarType="US"
                            prevLabel={<ReactSVG src={left} className="svg-icon" />}
                            nextLabel={<ReactSVG src={right} className="svg-icon" />}
                        />
                        <div className="help-area v-r">
                            {HelpParams.map((item, index) => <div className="help-item v-c" key={index}>
                                <div className={`help-color ${item}`} />
                                <p>{item}</p>
                            </div>)}
                        </div>
                    </div>
                    {this.checkSelectedCount() > 0 && <div className="v-c delete-all show-web-flex" onClick={e => this.deleteSelected()}>
                        <ReactSVG src={bin} className="svg-icon" />
                        <p>Delete Selected</p>
                    </div>}
                    <p className="pre-explain">Here, you can block off times that you are unavailable to tutor your clients. Please keep this up to date as much as possible. This allows you to block a specific day/time and essentially schedule yourself during a time you are typically available but will not be able to tutor or otherwise be unavailable.</p>
                    {this.checkSelectedCount() > 0 && <div className="v-c delete-all show-mobile-flex" onClick={e => this.deleteSelected()}>
                        <ReactSVG src={bin} className="svg-icon" />
                        <p>Delete Selected</p>
                    </div>}
                </div>
                <div className="setting-area">
                    <p className="block-title">Click on the time slots you want to block</p>
                    {week_data.length > 0 && <table className="setting-table">
                        <thead>
                            <tr>
                                <th className="start-col"></th>
                                {week_data.map((item, index) => <th key={index} className={`header-col ${item.whole_day === moment(selected_date).format("YYYY-MM-DD") ? 'active' : ''}`}>
                                    <p>{item.week_day}</p>
                                    <p className="show-web">{item.day_only}</p>
                                </th>)}
                            </tr>
                        </thead>
                        <tbody>
                            {Hours.map((hour, hrIndex) => <tr key={hrIndex}>
                                <td className="start-col">{this.getHour(hour)}</td>
                                {week_data.map((item, index) => {
                                    let data = this.checkSchedule(item.whole_day, hour);
                                    return (
                                    <td key={index} className={`${data.current}`} onClick={e => this.updateSchedule(item.whole_day, hour, data.current)}>
                                        {data.current.length > 0 && <div onClick={e => this.selectSchedule(e, item.whole_day, hour, data.current)}
                                            className={`inner-schedule ${data.current} ${data.current === data.prev ? 'has-prev' : ''} ${data.current === data.next ? 'has-next' : ''} ${data.selected ? 'selected' : ''} `} >
                                            {data.current !== data.prev && <p>
                                                <span>{this.getHour(hour)} - </span>
                                                <span>{this.getHour(data.last_time ? data.last_time + 1 : hour + 1)}</span>
                                            </p>}
                                            {data.selected && <div className="del-btn" onMouseDown={e => this.delSchedule(item.whole_day, hour, item)}><ReactSVG className="svg-icon" src={bin}/></div>}
                                        </div>}
                                    </td>)
                                })}
                            </tr>)}
                        </tbody>
                    </table>}
                </div>
            </div>
        );
    }
}

const mapStateToProps = (state) => ({
    myProfileInfo: state.Core.myProfileInfo,
    blockScheduleList: state.Core.blockScheduleList,
    blockScheduleUpdateStatus: state.Auth.blockScheduleUpdateStatus,
    blockScheduleUpdateErr: state.Auth.blockScheduleUpdateErr,
    cancelLessonStatus: state.Auth.cancelLessonStatus,
});

export default connect(mapStateToProps, {
    getMypofileInfoAction,
    getBlockScheduleListAction,
    blockScheduleUpdateAction,
    cancelLessonAction
})(withRouter(BlockSchedulePage));