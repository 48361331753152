/**
 * Sidebar
 */
export const MOBILE_SIDEBAR_TOGGLE            = 'MOBILE_SIDEBAR_TOGGLE';
export const SIDEBAR_TOGGLE                   = 'SIDEBAR_TOGGLE';

/**
 * Modal
 */
export const VIDEO_MODAL_TOGGLE               = 'VIDEO_MODAL_TOGGLE';
export const CREDIT_MODAL_TOGGLE              = 'CREDIT_MODAL_TOGGLE';
export const QUALIFICATION_MODAL_TOGGLE       = 'QUALIFICATION_MODAL_TOGGLE';

/**
 * Join Lesson
 */
export const JOIN_LESSON_TOGGLE               = 'JOIN_LESSON_TOGGLE';

/**
 * Authentication
 */
export const AUTH_UPDATE_TOKEN                = 'AUTH_UPDATE_TOKEN';
export const AUTH_UPDATE_PLAN                 = 'AUTH_UPDATE_PLAN';
export const AUTH_SIGN_OUT					  = 'AUTH_SIGN_OUT';

export const GET_MY_INFO                      = 'GET_MY_INFO';
export const GET_MY_INFO_SUCCESS              = 'GET_MY_INFO_SUCCESS';
export const GET_MY_INFO_FAIL                 = 'GET_MY_INFO_FAIL';

export const UPDATE_MY_INFO                   = 'UPDATE_MY_INFO';
export const UPDATE_MY_INFO_SUCCESS           = 'UPDATE_MY_INFO_SUCCESS';
export const UPDATE_MY_INFO_FAIL              = 'UPDATE_MY_INFO_FAIL';

export const UPDATE_PASSWORD                  = 'UPDATE_PASSWORD';
export const UPDATE_PASSWORD_SUCCESS          = 'UPDATE_PASSWORD_SUCCESS';
export const UPDATE_PASSWORD_FAIL             = 'UPDATE_PASSWORD_FAIL';

export const UPDATE_QUALIFICATION			  = 'UPDATE_QUALIFICATION';

/**
 * Other
 */
export const GET_ALERT_LIST                   = 'GET_ALERT_LIST';
export const GET_ALERT_LIST_SUCCESS           = 'GET_ALERT_LIST_SUCCESS';
export const GET_ALERT_LIST_FAIL              = 'GET_ALERT_LIST_FAIL';

export const ALERT_SHOW_INTEREST              = 'ALERT_SHOW_INTEREST';
export const ALERT_SHOW_INTEREST_SUCCESS      = 'ALERT_SHOW_INTEREST_SUCCESS';
export const ALERT_SHOW_INTEREST_FAIL         = 'ALERT_SHOW_INTEREST_FAIL';


/**
 * MY STUDENTS
 */
export const GET_MYSTUDENTS = "GET_MYSTUDENTS";
export const GET_MYSTUDENTS_SUCCESS = "GET_MYSTUDENTS_SUCCESS";
export const GET_MYSTUDENTS_FAIL = "GET_MYSTUDENTS_FAIL";

/**
 * MYS BLOGS
 */
export const GET_MYBLOGS = "GET_MYBLOGS";
export const GET_MYBLOGS_SUCCESS = "GET_MYBLOGS_SUCCESS";
export const GET_MYBLOGS_FAIL = "GET_MYBLOGS_FAIL";

/**
 * MY PROFILE INFO
 */
export const GET_MY_PROFILE_INFO = "GET_MY_PROFILE_INFO";
export const GET_MY_PROFILE_INFO_SUCCESS = "GET_MY_PROFILE_INFO_SUCCESS";
export const GET_MY_PROFILE_INFO_FAIL = "GET_MY_PROFILE_INFO_FAIL";

/**
 * PENDING_LESSON
 */
export const GET_PENDING_LESSON = "GET_PENDING_LESSON";
export const GET_PENDING_LESSON_SUCCESS = "GET_PENDING_LESSON_SUCCESS";
export const GET_PENDING_LESSON_FAIL = "GET_PENDING_LESSON_FAIL";

/**
 * COMPLETED_LESSON
 */
export const GET_COMPLETED_LESSON = "GET_COMPLETED_LESSON";
export const GET_COMPLETED_LESSON_SUCCESS = "GET_COMPLETED_LESSON_SUCCESS";
export const GET_COMPLETED_LESSON_FAIL = "GET_COMPLETED_LESSON_FAIL";

/**
 * CITIES
 */
export const GET_CITIES = "GET_CITIES";
export const GET_CITIES_SUCCESS = "GET_CITIES_SUCCESS";
export const GET_CITIES_FAIL = "GET_CITIES_FAIL";

/**
 * SCHOOLS
 */
export const GET_SCHOOLS = "GET_SCHOOLS";
export const GET_SCHOOLS_SUCCESS = "GET_SCHOOLS_SUCCESS";
export const GET_SCHOOLS_FAIL = "GET_SCHOOLS_FAIL";

/**
 * SUBJECTS
 */
export const GET_SUBJECTS = "GET_SUBJECTS";
export const GET_SUBJECTS_SUCCESS = "GET_SUBJECTS_SUCCESS";
export const GET_SUBJECTS_FAIL = "GET_SUBJECTS_FAIL";

/**
 * Levels
 */
export const GET_LEVELS = "GET_LEVELS";
export const GET_LEVELS_SUCCESS = "GET_LEVELS_SUCCESS";
export const GET_LEVELS_FAIL = "GET_LEVELS_FAIL";

/**
 * Level and Subject
 */
 export const GET_LEVEL_SUBJECTS = "GET_LEVEL_SUBJECTS";
 export const GET_LEVEL_SUBJECTS_SUCCESS = "GET_LEVEL_SUBJECTS_SUCCESS";
 export const GET_LEVEL_SUBJECTS_FAIL = "GET_LEVEL_SUBJECTS_FAIL";

/**
 * TOPICLIST
 */
export const GET_TOPICLIST = "GET_TOPICLIST";
export const GET_TOPICLIST_SUCCESS = "GET_TOPICLIST_SUCCESS";
export const GET_TOPICLIST_FAIL = "GET_TOPICLIST_FAIL";

/**
 * ALERTS
 */
export const GET_ALERTS = "GET_ALERTS";
export const GET_ALERTS_SUCCESS = "GET_ALERTS_SUCCESS";
export const GET_ALERTS_FAIL = "GET_ALERTS_FAIL";

/**
 *BLOCK SCHEDULE LIST
 */
export const GET_BLOCK_SCHEDULE_LIST = "GET_BLOCK_SCHEDULE_LIST";
export const GET_BLOCK_SCHEDULE_LIST_SUCCESS = "GET_BLOCK_SCHEDULE_LIST_SUCCESS";
export const GET_BLOCK_SCHEDULE_LIST_FAIL = "GET_BLOCK_SCHEDULE_LIST_FAIL";

/**
 *PAYMENT HISTORY
 */
export const GET_PAYMENT_HISTORY = "GET_PAYMENT_HISTORY";
export const GET_PAYMENT_HISTORY_SUCCESS = "GET_PAYMENT_HISTORY_SUCCESS";
export const GET_PAYMENT_HISTORY_FAIL = "GET_PAYMENT_HISTORY_FAIL";

/**
 *BIWEEKLY
 */
export const GET_BIWEEKLY = "GET_BIWEEKLY";
export const GET_BIWEEKLY_SUCCESS = "GET_BIWEEKLY_SUCCESS";
export const GET_BIWEEKLY_FAIL = "GET_BIWEEKLY_FAIL";

/**
 * BANK DETAIL
 */
export const GET_BANK_DETAIL = "GET_BANK_DETAIL";
export const GET_BANK_DETAIL_SUCCESS = "GET_BANK_DETAIL_SUCCESS";
export const GET_BANK_DETAIL_FAIL = "GET_BANK_DETAIL_FAIL";

/**
 * MESSAGE DATA
 */
export const GET_MESSAGE = "GET_MESSAGE";
export const GET_MESSAGE_SUCCESS = "GET_MESSAGE_SUCCESS";
export const GET_MESSAGE_FAIL = "GET_MESSAGE_FAIL";

/**
 * MESSAGE USER LIST
 */
export const GET_MESSAGE_USER_LIST = "GET_MESSAGE_USER_LIST";
export const GET_MESSAGE_USER_LIST_SUCCESS = "GET_MESSAGE_USER_LIST_SUCCESS";
export const GET_MESSAGE_USER_LIST_FAIL = "GET_MESSAGE_USER_LIST_FAIL";





//---------------------------POST METHOD--------------------

/**
 * PROFILE UPDATE
 */
export const GET_PROFILE_UPDATE = "GET_PROFILE_UPDATE";
export const GET_PROFILE_UPDATE_SUCCESS = "GET_PROFILE_UPDATE_SUCCESS";
export const GET_PROFILE_UPDATE_FAIL = "GET_MY_PROFILE_INFO_FAIL";


/**
 * SCHEDULE LESSON
 */
export const GET_SCHEDULE_LESSON = "GET_SCHEDULE_LESSON";
export const GET_SCHEDULE_LESSON_SUCCESS = "GET_SCHEDULE_LESSON_SUCCESS";
export const GET_SCHEDULE_LESSON_FAIL = "GET_SCHEDULE_LESSON_FAIL";

/**
 * CANCEL LESSON
 */
export const GET_CANCEL_LESSON = "GET_CANCEL_LESSON";
export const GET_CANCEL_LESSON_SUCCESS = "GET_CANCEL_LESSON_SUCCESS";
export const GET_CANCEL_LESSON_FAIL = "GET_CANCEL_LESSON_FAIL";

/**
 * DELETE TUTOR
 */
export const GET_DELETE_TUTOR = "GET_DELETE_TUTOR";
export const GET_DELETE_TUTOR_SUCCESS = "GET_DELETE_TUTOR_SUCCESS";
export const GET_DELETE_TUTOR_FAIL = "GET_DELETE_TUTOR_FAIL";

/**
 * POST BLOG
 */
export const GET_POST_BLOG = "GET_POST_BLOG";
export const GET_POST_BLOG_SUCCESS = "GET_POST_BLOG_SUCCESS";
export const GET_POST_BLOG_FAIL = "GET_POST_BLOG_FAIL";

/**
 * BLOG UPDATE
 */
export const GET_BLOG_UPDATE = "GET_BLOG_UPDATE";
export const GET_BLOG_UPDATE_SUCCESS = "GET_BLOG_UPDATE_SUCCESS";
export const GET_BLOG_UPDATE_FAIL = "GET_BLOG_UPDATE_FAIL";

/**
 * TUTOR NOTE
 */
export const GET_TUTOR_NOTE = "GET_TUTOR_NOTE";
export const GET_TUTOR_NOTE_SUCCESS = "GET_TUTOR_NOTE_SUCCESS";
export const GET_TUTOR_NOTE_FAIL = "GET_TUTOR_NOTE_FAIL";

/**
 * BLOCK SCHEDULE UPDATE
 */
export const GET_BLOCK_SCHEDULE_UPDATE = "GET_BLOCK_SCHEDULE_UPDATE";
export const GET_BLOCK_SCHEDULE_UPDATE_SUCCESS = "GET_BLOCK_SCHEDULE_UPDATE_SUCCESS";
export const GET_BLOCK_SCHEDULE_UPDATE_FAIL = "GET_BLOCK_SCHEDULE_UPDATE_FAIL";

/**
 * ALERT INTEREST
 */
export const GET_ALERT_INTEREST = "GET_ALERT_INTEREST";
export const GET_ALERT_INTEREST_SUCCESS = "GET_ALERT_INTEREST_SUCCESS";
export const GET_ALERT_INTEREST_FAIL = "GET_ALERT_INTEREST_FAIL";

/**
 * SIGN OUT
 */
export const GET_AUTH_SIGN_OUT = "GET_AUTH_SIGN_OUT";
export const GET_AUTH_SIGN_OUT_SUCCESS = "GET_AUTH_SIGN_OUT_SUCCESS";
export const GET_AUTH_SIGN_OUT_FAIL = "GET_AUTH_SIGN_OUT_FAIL";

/**
 * UPDATE BANK ACCOUNT
 */
export const GET_UPDATE_BANK = "GET_UPDATE_BANK";
export const GET_UPDATE_BANK_SUCCESS = "GET_UPDATE_BANK_SUCCESS";
export const GET_UPDATE_BANK_FAIL = "GET_UPDATE_BANK_FAIL";

/**
 * WITHDRAW MONEY
 */
export const GET_WITHDRAW_MONEY = "GET_WITHDRAW_MONEY";
export const GET_WITHDRAW_MONEY_SUCCESS = "GET_WITHDRAW_MONEY_SUCCESS";
export const GET_WITHDRAW_MONEY_FAIL = "GET_WITHDRAW_MONEY_FAIL";

/**
 * CREATE MESSAGE
 */
export const GET_CREATE_MESSAGE = "GET_CREATE_MESSAGE";
export const GET_CREATE_MESSAGE_SUCCESS = "GET_CREATE_MESSAGE_SUCCESS";
export const GET_CREATE_MESSAGE_FAIL = "GET_CREATE_MESSAGE_FAIL";

/**
 * PORT TO FROG
 */
 export const GET_PORT_TO_FROG = "GET_PORT_TO_FROG";
 export const GET_PORT_TO_FROG_SUCCESS = "GET_PORT_TO_FROG_SUCCESS";
 export const GET_PORT_TO_FROG_FAIL = "GET_PORT_TO_FROG_FAIL";
