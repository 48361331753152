import React from 'react';
import './ReceiptPage.scss';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { InputBox, RoundButton, SelectBox } from '../../Components';
import search from '../../Assets/Icon/search.svg';
import left from '../../Assets/Icon/left.svg';
import right from '../../Assets/Icon/right.svg';
import logo from '../../Assets/Header/logo.png';

const ReceiptData = [
    { id: 1, amount: '$92.25', bonus: '$0', old_credit: '$35.00', new_credit: '$35.00', status: '$35.00', note: 'Session booked with Alexander Himstead on 2017-11-09 16:30:00, charged on stored payment', date: '2017-11-11 00:10:31'},
    { id: 2, amount: '$92.25', bonus: '$0', old_credit: '$35.00', new_credit: '$35.00', status: '$35.00', note: 'Session booked with Alexander Himstead on 2017-11-09 16:30:00, charged on stored payment', date: '2017-11-11 00:10:31'},
    { id: 3, amount: '$92.25', bonus: '$0', old_credit: '$35.00', new_credit: '$35.00', status: '$35.00', note: 'Session booked with Alexander Himstead on 2017-11-09 16:30:00, charged on stored payment', date: '2017-11-11 00:10:31'},
    { id: 4, amount: '$92.25', bonus: '$0', old_credit: '$35.00', new_credit: '$35.00', status: '$35.00', note: 'Session booked with Alexander Himstead on 2017-11-09 16:30:00, charged on stored payment', date: '2017-11-11 00:10:31'},
    { id: 5, amount: '$92.25', bonus: '$0', old_credit: '$35.00', new_credit: '$35.00', status: '$35.00', note: 'Session booked with Alexander Himstead on 2017-11-09 16:30:00, charged on stored payment', date: '2017-11-11 00:10:31'},
    { id: 6, amount: '$92.25', bonus: '$0', old_credit: '$35.00', new_credit: '$35.00', status: '$35.00', note: 'Session booked with Alexander Himstead on 2017-11-09 16:30:00, charged on stored payment', date: '2017-11-11 00:10:31'},
    { id: 7, amount: '$92.25', bonus: '$0', old_credit: '$35.00', new_credit: '$35.00', status: '$35.00', note: 'Session booked with Alexander Himstead on 2017-11-09 16:30:00, charged on stored payment', date: '2017-11-11 00:10:31'},
    { id: 8, amount: '$92.25', bonus: '$0', old_credit: '$35.00', new_credit: '$35.00', status: '$35.00', note: 'Session booked with Alexander Himstead on 2017-11-09 16:30:00, charged on stored payment', date: '2017-11-11 00:10:31'},
    { id: 9, amount: '$92.25', bonus: '$0', old_credit: '$35.00', new_credit: '$35.00', status: '$35.00', note: 'Session booked with Alexander Himstead on 2017-11-09 16:30:00, charged on stored payment', date: '2017-11-11 00:10:31'},
    { id: 10, amount: '$92.25', bonus: '$0', old_credit: '$35.00', new_credit: '$35.00', status: '$35.00', note: 'Session booked with Alexander Himstead on 2017-11-09 16:30:00, charged on stored payment', date: '2017-11-11 00:10:31'},
    { id: 11, amount: '$92.25', bonus: '$0', old_credit: '$35.00', new_credit: '$35.00', status: '$35.00', note: 'Session booked with Alexander Himstead on 2017-11-09 16:30:00, charged on stored payment', date: '2017-11-11 00:10:31'},
    { id: 12, amount: '$92.25', bonus: '$0', old_credit: '$35.00', new_credit: '$35.00', status: '$35.00', note: 'Session booked with Alexander Himstead on 2017-11-09 16:30:00, charged on stored payment', date: '2017-11-11 00:10:31'},
    { id: 13, amount: '$92.25', bonus: '$0', old_credit: '$35.00', new_credit: '$35.00', status: '$35.00', note: 'Session booked with Alexander Himstead on 2017-11-09 16:30:00, charged on stored payment', date: '2017-11-11 00:10:31'},
]

class ReceiptPage extends React.Component { 
    state = {
        id: '',
        data: ReceiptData,
        filteredList: ReceiptData,
        
        searchText: '',
        currentPage: 1,
        totalPage: 1,
        displayCount: 10,

        depth: 0,
    }

    static getDerivedStateFromProps(props, state) {
        const id = (props.match.params.id && props.match.params.id.length > 0) ? props.match.params.id : '';
        return {
            id: id,
            depth: props.depth
        };
    }

    componentDidMount() {
        this.filterData('', 10)
    }

    componentDidUpdate(prevProps, prevState) {
        const prevID = (prevProps.match.params.id && prevProps.match.params.id.length > 0) ? prevProps.match.params.id : '';
        const newID = (this.props.match.params.id && this.props.match.params.id.length > 0) ? this.props.match.params.id : '';
        if (prevID === newID && prevID !== '' && newID !== '') {
            if (this.state.depth !== 1 && prevState.depth === 0) this.props.updateTitle('push', 'Receipt', 2);
        }
    }

    changeDisplayCount = (val) => {
        const { searchText } = this.state;
        this.filterData(searchText, val);
    }

    changeSearchText = (val) => {
        const { displayCount } = this.state;
        this.filterData(val, displayCount);
    }

    changeCurrentPage = (direction) => {
        const { currentPage, totalPage } = this.state;
        if ((direction === 'previous' && currentPage === 1) || (direction === 'next' && currentPage === totalPage)) return;
        this.setState({
            currentPage: direction === 'next' ? currentPage + 1 : currentPage - 1
        })
    }

    filterData = (searchText, displayCount) => {
        const { data } = this.state;
        const filteredList = data.filter(item => item.note.indexOf(searchText) > -1);
        const totalPage = Math.ceil(filteredList.length / displayCount);

        this.setState({
            searchText: searchText,
            filteredList: filteredList,
            totalPage: totalPage,
            displayCount: displayCount,
            currentPage: 1
        })
    }

    viewItem = (item) => {
        this.props.history.push(`/receipt/${item.id}`);
    }

    render() {
        const { searchText, displayCount, filteredList, currentPage, totalPage, depth } = this.state;
        const startIndex = displayCount * (currentPage - 1);
        const displayList = filteredList.slice(startIndex, startIndex + displayCount);
        return (
            <div className="receipt-page v-r">
                {depth === 0 && <div className="options-area">
                    <InputBox className="search-input" value={searchText} icon={search} placeholder="Search..." onChange={val=>this.changeSearchText(val)} />
                    <div className="count-area v-c">
                        <RoundButton icon={left} className="show-mobile-flex" onClick={e => this.changeCurrentPage('previous')}/>
                        <p className="page-count">Page {currentPage} of {totalPage  }</p>
                        <RoundButton icon={left} className="arrow-btn show-web-flex" onClick={e => this.changeCurrentPage('previous')}/>
                        <RoundButton icon={right} className="arrow-btn" onClick={e => this.changeCurrentPage('next')}/>
                        <div className="combo-area v-c">
                            <p>Items</p>
                            <SelectBox value={displayCount} onChange={val => this.changeDisplayCount(val)}/>
                        </div>
                    </div>
                </div>}
                {depth === 0 && <div className="data-area">
                     <table className="table table-bordered table-striped">
                        <thead>
                            <tr>
                                <th>AMOUNT</th>
                                <th>BONUS (DISCOUNT)</th>
                                <th>OLD CREDIT</th>
                                <th>NEW CREDIT</th>
                                <th>PAYMENT STATUS</th>
                                <th>NOTE</th>
                                <th>DATE</th>
                                <th>ACTION</th>
                            </tr>
                        </thead>
                        <tbody>
                            {displayList.map((item, index) => <tr key={index}>
                                <td>{item.amount}</td>
                                <td>{item.bonus}</td>
                                <td>{item.old_credit}</td>
                                <td>{item.new_credit}</td>
                                <td>{item.status}</td>
                                <td>{item.note}</td>
                                <td>{item.date}</td>
                                <td>
                                    <div className="view-btn" onClick={e => this.viewItem(item)}>View</div>
                                </td>
                            </tr>)}
                        </tbody>
                    </table>
                    <div className="shoe-mobile-flex v-r">
                        {displayList.map((item, index) => <div className="mobile-row v-r" key={index}>
                            <div className="data-row v-c">
                                <div className="left-col v-r"> <div className="col-header">AMOUNT</div> <p>{item.amount}</p> </div>
                                <div className="right-col v-r"> <div className="col-header">BONUS (DISCOUNT)</div> <p>{item.bonus}</p> </div>
                            </div>
                            <div className="data-row v-c">
                                <div className="left-col v-r"> <div className="col-header">NEW CREDIT</div> <p>{item.new_credit}</p> </div>
                                <div className="right-col v-r"> <div className="col-header">OLD CREDIT</div> <p>{item.old_credit}</p> </div>
                            </div>
                            <div className="data-row v-c">
                                <div className="left-col v-r"> <div className="col-header">PAYMENT STATUS</div> <p>{item.status}</p> </div>
                                <div className="right-col v-r"> <div className="col-header">DATE</div> <p>{item.date}</p> </div>
                            </div>
                            <div className="data-row v-c">
                                <div className="right-col v-r"> <div className="col-header">NOTE</div> <p>{item.note}</p> </div>
                            </div>
                            <div className="data-row v-c">
                                <div className="right-col v-r"> <div className="col-header">ACTION</div> <p className="view-btn" onClick={e => this.viewItem(item)}>View</p> </div>
                            </div>
                        </div>)}
                    </div>
                </div>}
                {depth === 1 && <div className="receipt-detail v-r">
                    <div className="detail-row">    
                        <div className="detail-block">
                            <h1>Sale Receipt</h1>
                            <div className="paragraph">
                                <p>Date: Nov 11, 2017</p>
                                <p>Receipt #: 179658</p>
                            </div>
                        </div>
                    </div>
                    <div className="detail-row" style={{borderBottom: 'none'}}>
                        <div className="detail-block left-block">
                            <h1>FrogTutoring, LLC</h1>
                            <div className="paragraph">
                                <p>1751 River Run Suite 200</p>
                                <p>Fort Worth, TX 76107</p>
                            </div>
                            <div className="paragraph">
                                <p>Phone: (817) - 717 1286</p>
                                <p>Fax: (877) 904 0134</p>
                            </div>
                            <div className="paragraph">
                                <p>Email: info@frogtutoring.com</p>
                            </div>
                        </div>
                        <div className="detail-block">
                            <h1>Zian Test Client</h1>
                            <div className="paragraph">
                                <p>76008</p>
                            </div>
                            <div className="paragraph">
                                <p>Phone: 6822255718</p>
                            </div>
                            <div className="paragraph">
                                <p>Email: omenerd@yahoo.com</p>
                            </div>
                        </div>
                    </div>
                    <div className="pay-table v-r">
                        <div className="pay-header v-c">
                            <h2 className="first-col">PAID</h2>
                            <h2>BONUS</h2>
                            <h2 className="last-col">STATUS</h2>
                        </div>
                        <div className="pay-data v-c">
                            <p className="first-col">$5</p>
                            <p>$0</p>
                            <p className="last-col completed">Completed</p>
                        </div>
                    </div>
                    <div className="receipt-footer">
                        <img src={logo} alt="logo" />
                        <p>Thank you for your business!</p>
                    </div>
                    <button className="btn-2 btn-2-outline print-btn">Print</button>
                </div>}
            </div>
        );
    }
}

function mapStateToProps(state) {
    return {
    }
}

export default connect(mapStateToProps, {})(withRouter(ReceiptPage));