
import {
    // GET_PROFILE_UPDATE,
    // GET_PROFILE_UPDATE_SUCCESS,
    // GET_PROFILE_UPDATE_FAIL,
    GET_SCHEDULE_LESSON,
    GET_SCHEDULE_LESSON_SUCCESS,
    GET_SCHEDULE_LESSON_FAIL,
    GET_CANCEL_LESSON,
    GET_CANCEL_LESSON_SUCCESS,
    GET_CANCEL_LESSON_FAIL,
    GET_DELETE_TUTOR,
    GET_DELETE_TUTOR_SUCCESS,
    GET_DELETE_TUTOR_FAIL,
    GET_POST_BLOG,
    GET_POST_BLOG_SUCCESS,
    GET_POST_BLOG_FAIL,
    GET_BLOG_UPDATE,
    GET_BLOG_UPDATE_SUCCESS,
    GET_BLOG_UPDATE_FAIL,
    GET_TUTOR_NOTE,
    GET_TUTOR_NOTE_SUCCESS,
    GET_TUTOR_NOTE_FAIL,
    GET_BLOCK_SCHEDULE_UPDATE,
    GET_BLOCK_SCHEDULE_UPDATE_SUCCESS,
    GET_BLOCK_SCHEDULE_UPDATE_FAIL,
    GET_ALERT_INTEREST,
    GET_ALERT_INTEREST_SUCCESS,
    GET_ALERT_INTEREST_FAIL,
    GET_UPDATE_BANK,
    GET_UPDATE_BANK_SUCCESS,
    GET_UPDATE_BANK_FAIL,
    GET_WITHDRAW_MONEY,
    GET_WITHDRAW_MONEY_SUCCESS,
    GET_WITHDRAW_MONEY_FAIL,
    GET_AUTH_SIGN_OUT,
    GET_AUTH_SIGN_OUT_SUCCESS,
    GET_AUTH_SIGN_OUT_FAIL,
    GET_CREATE_MESSAGE,
    GET_CREATE_MESSAGE_FAIL,
    GET_CREATE_MESSAGE_SUCCESS,
    GET_PORT_TO_FROG,
    GET_PORT_TO_FROG_FAIL,
    GET_PORT_TO_FROG_SUCCESS,
} from "../Type";

import {
    // profileUpdate,
    scheduleLesson,
    cancelLesson,
    deleteTutor,
    postBlog,
    blogUpdate,
    tutorNote,
    blockScheduleUpdate,
    alertInteres,
    updateBank,
    withdrawMoney,
    signOut,
    createMessage,
    portToFrog
} from "../../Api/Auth";


export const scheduleLessonAction = (payload) => async (dispatch) => {
    dispatch({ type: GET_SCHEDULE_LESSON });
    const res = await scheduleLesson(payload);
    if (res && res.data && res.data.is_success) {
        dispatch({ type: GET_SCHEDULE_LESSON_SUCCESS, payload: res.data });
    } else {
        dispatch({ type: GET_SCHEDULE_LESSON_FAIL, payload: res.data });
    }
};

export const cancelLessonAction = (data, id) => async (dispatch) => {
    dispatch({ type: GET_CANCEL_LESSON });
    const res = await cancelLesson(data, id);
    if (res && res.data) {
        dispatch({ type: GET_CANCEL_LESSON_SUCCESS, payload: res.data });
    } else {
        dispatch({ type: GET_CANCEL_LESSON_FAIL, payload: res.data });
    }
};

export const deleteTutorAction = (payload) => async (dispatch) => {
    dispatch({ type: GET_DELETE_TUTOR });
    const res = await deleteTutor(payload);
    if (res && res.data) {
        dispatch({ type: GET_DELETE_TUTOR_SUCCESS, payload: res.data });
    } else {
        dispatch({ type: GET_DELETE_TUTOR_FAIL, payload: res.data });
    }
};

export const postBlogAction = (payload) => async (dispatch) => {
    dispatch({ type: GET_POST_BLOG });
    const res = await postBlog(payload);
    if (res && res.data && res.data.data) {
        dispatch({ type: GET_POST_BLOG_SUCCESS, payload: res.data });
    } else {
        dispatch({ type: GET_POST_BLOG_FAIL, payload: res.data });
    }
};

export const blogUpdateAction = (payload, blog_id) => async (dispatch) => {
    dispatch({ type: GET_BLOG_UPDATE });
    const res = await blogUpdate(payload, blog_id);
    if (res && res.data && res.data.data) {
        dispatch({ type: GET_BLOG_UPDATE_SUCCESS, payload: res.data });
    } else {
        dispatch({ type: GET_BLOG_UPDATE_FAIL, payload: res.data });
    }
};

export const tutorNoteAction = (payload, lesson_id) => async (dispatch) => {
    dispatch({ type: GET_TUTOR_NOTE });
    const res = await tutorNote(payload, lesson_id);
    if (res && res.data && res.data.is_success ) {
        dispatch({ type: GET_TUTOR_NOTE_SUCCESS, payload: res.data });
    } else {
        dispatch({ type: GET_TUTOR_NOTE_FAIL, payload: res.data });
    }
};

export const blockScheduleUpdateAction = (payload) => async (dispatch) => {
    dispatch({ type: GET_BLOCK_SCHEDULE_UPDATE });
    const res = await blockScheduleUpdate(payload);
    if (res && res.data.is_success ) {
        dispatch({ type: GET_BLOCK_SCHEDULE_UPDATE_SUCCESS, payload: res.data });
    } else {
        dispatch({ type: GET_BLOCK_SCHEDULE_UPDATE_FAIL, payload: res.data });
    }
};

export const alertInterestAction = (payload) => async (dispatch) => {
    dispatch({ type: GET_ALERT_INTEREST });
    const res = await alertInteres(payload);
    if (res && res.data ) {
        dispatch({ type: GET_ALERT_INTEREST_SUCCESS, payload: res.data });
    } else {
        dispatch({ type: GET_ALERT_INTEREST_FAIL, payload: res.data });
    }
};

export const updateBankAction = (payload) => async (dispatch) => {
    dispatch({ type: GET_UPDATE_BANK });
    const res = await updateBank(payload);
    if (res && res.data && res.data.is_success) {
        dispatch({ type: GET_UPDATE_BANK_SUCCESS, payload: res.data });
    } else {
        dispatch({ type: GET_UPDATE_BANK_FAIL, payload: res.data });
    }
};

export const withdrawMoneyAction = (payload) => async (dispatch) => {
    dispatch({ type: GET_WITHDRAW_MONEY });
    const res = await withdrawMoney(payload);
    if (res && res.data && res.data.is_success) {
        dispatch({ type: GET_WITHDRAW_MONEY_SUCCESS, payload: res.data });
    } else {
        dispatch({ type: GET_WITHDRAW_MONEY_FAIL, payload: res.data });
    }
};

export const signOutAction = (payload) => async (dispatch) => {
    dispatch({ type: GET_AUTH_SIGN_OUT });
    const res = await signOut(payload);
    if (res && res.data) {
        dispatch({ type: GET_AUTH_SIGN_OUT_SUCCESS, payload: res.data });
    } else {
        dispatch({ type: GET_AUTH_SIGN_OUT_FAIL, payload: null });
    }
};

export const createMessageAction = (payload) => async (dispatch) => {
    dispatch({ type: GET_CREATE_MESSAGE });
    const res = await createMessage(payload);
    if (res && res.data && res.data.data) {
      dispatch({ type: GET_CREATE_MESSAGE_SUCCESS, payload: res.data.data});
    } else {
      dispatch({ type: GET_CREATE_MESSAGE_FAIL, payload: res.data });
    }
};

export const portToFrogAction = (payload) => async (dispatch) => {
    dispatch({ type: GET_PORT_TO_FROG });
    const res = await portToFrog(payload);
    if (res && res.success ) {
        dispatch({ type: GET_PORT_TO_FROG_SUCCESS, payload: res.data });
    } else {
        dispatch({ type: GET_PORT_TO_FROG_FAIL, payload: res.data });
    }
};







