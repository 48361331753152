import React from 'react';
// import Modal from 'react-modal';
import { Modal } from 'react-bootstrap';
import { ReactSVG } from 'react-svg'
import add from '../../Assets/Icon/add.svg';

import './SubmitNoteModal.scss';

export default class SubmitNoteModal extends React.Component {
    state = {
        reason: ''
    }

    confirm = (status) => {
        this.props.submitNote(status)
    }

    closeModal = () => {
        this.props.closeModal();
    }

    render() {
        const {isSubmitNoteModal, cancelTitle} = this.props
        const {reason} = this.state
    return (
        <Modal show={isSubmitNoteModal} className="submit-note-modal-component">
            <div className="submit-note-content">
                <div className="close-btn"> <ReactSVG src={ add } className='icon-close' onClick={ () => this.closeModal() }/> </div>
                <div className="content-area v-r">
                    <h1>Submit Note?</h1>
                    <h2>I confirm that I completed a lesson with the student. I am aware that this report will be forwarded to the email inbox of the client and my supervisor. I am aware that any falsification of this information will lead to immediate termination.</h2>
                    <div className="confirm-btn-area">
                        <div className="confirm-btn" onClick={()=>this.confirm(true)}>
                            Submit Note
                        </div>
                        <div className="confirm-btn" onClick={()=>this.confirm(false)}>
                            Cancel Lesson
                        </div>
                    </div>
                </div>
            </div>
        </Modal>
    )
  };
};