import React from 'react';
import './JoinLesson.scss';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { toggleJoinLesson, getPendingLessonAction } from '../../Redux/Actions';

class JoinLesson extends React.Component {
    timer = null;
    timer2 = null;
    state = {
        isOpen: false,
        info: {}, // seconds
        second: 120000,
        selectedLesson: ''
    }

    componentDidMount() {
        this.props.getPendingLessonAction().then(()=>{
            const {pendingLesson} = this.props
            if(pendingLesson){
                pendingLesson.map((item, index)=>{
                    var time = Math.floor((new Date(item.sched) - new Date()) / 1000)
                    if(time > 0 && time < 1800){
                        this.setState({isOpen: true,  second: time, selectedLesson: item})
                        clearInterval(this.timer2);
                        this.timer2 = null;
                    }
                })
            }
        })
    }

    componentDidUpdate() {
        const { isOpen } = this.state;
        if (isOpen) {
            if (!this.timer) {
                this.timer = setInterval(() => {
                    let { second } = this.state;
                    if (second > 0) {
                        this.setState({ second: this.state.second - 1 })
                    } else {
                        clearInterval(this.timer);
                        this.timer = null;
                        this.props.toggleJoinLesson(false);
                    }
                }, 1000);
            }
        } else {
            clearInterval(this.timer);
            this.timer = null;
        } 
    }

    // static getDerivedStateFromProps(props, state) {
    //     return {
    //         isOpen: props.isOpen,
    //         info: props.info || {},
    //         second: props.isOpen !== state.isOpen ? 10 : state.second
    //     }
    // }

    joinLesson = () => {
        window.open(this.state.selectedLesson.tutor_online_link);
        
        clearInterval(this.timer);
        this.timer = null;
        // this.props.toggleJoinLesson(false);
        this.setState({isOpen: false})
    }

    formatTime = (sec) => {
        let second = sec;
        const hour = Math.floor(second / 3600);
        second = second - hour * 3600;
        const minute = Math.floor(second / 60);
        second = second - minute * 60;
        return `${hour < 10 ? '0'+hour : hour}:${minute < 10 ? '0'+minute : minute}:${second < 10 ? '0'+second : second}`;
    }

    render() {
        const { isOpen, second } = this.state;
        return (
            <div className={`joinlesson-component ${isOpen ? 'active' : 'inactive'} `}>
                <p>Online tutoring starts in {this.formatTime(second)}</p>
                <button className="join-btn" onClick={e => this.joinLesson()}>Join Live Lesson</button>
            </div> 
        );
    }
}

const mapStateToProps = (state) => ({
    pendingLesson: state.Core.pendingLesson,
    isOpen: state.Lesson.isOpen,
    info: state.Lesson.info,
});
  
export default connect(mapStateToProps, { 
    getPendingLessonAction,
    toggleJoinLesson,  
})(withRouter(JoinLesson));
