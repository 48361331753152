import React from 'react';
import './RatePlanPage.scss';
import { InputBox, Toggle } from '../../Components';
import card from '../../Assets/Icon/credit-card.svg';
import { ReactSVG } from 'react-svg';
import left from '../../Assets/Icon/arrow-left.svg';

const plans = [
    { plan: 'Hourly rate for less then 2hrs per lesson', iv: '$49.5', iii: '$47.5', ii: '$45.5', i: '$43.5' },
    { plan: 'Hourly rate for two hours or more per lesson', iv: '$44.5', iii: '$42.5', ii: '$40.5', i: '$38.5' },
    { plan: 'Estimated tutoring hours', iv: '12', iii: '26', ii: '46', i: '12' },
    { plan: 'Amount', iv: '$534', iii: '$1105', ii: '$1863', i: '$2112' },
]

const planButtons = [4, 3, 2, 1]

export default class RatePlanPage extends React.Component {
    state = {
        activeColumn: 0,
        selectedPlan: 0,
        depth: 0,

        card_number: '',
        month: '',
        year: '',
        cvv2: '',
        coupon_code: '',
        referal_code: '',

        check_sign: false,
    }

    componentDidMount() {
        this.setState({
            depth: this.props.depth
        })
    }

    static getDerivedStateFromProps(props, state) {
        return {
            depth: props.depth
        }
    }

    overButton = (num) => {
        this.setState({ activeColumn: num })
    }

    leaveButton = (num) => {
        if (num === this.state.activeColumn) {
            this.setState({activeColumn: 0})
        }
    }

    selectPlan = (num) => {
        this.setState({ selectedPlan: num})
        this.props.updateTitle('push', 'Make Payment');
    }

    updateValue = (field, value) => {
        this.setState({[field]: value});
    }

    goBack = () => {
        this.props.updateTitle('pop');
    }

    render() {
        const { activeColumn, selectedPlan, depth, card_number, month, year, cvv2, coupon_code, referal_code, check_sign } = this.state;
        return (
            <div className="rateplan-page v-r">
                {depth === 0 && <div className="container plan-area">
                    <h1>Select Your Rate Plan</h1>
                    <div className="table-responsive" style={{paddingRight:'15px'}}>
                        <table className="table table-bordered">
                            <thead>
                                <tr>
                                    <th>Plans</th>
                                    <th className={`${selectedPlan === 4 ? 'disabled' : activeColumn === 4 ? 'active-col' : ''}`} onMouseOver={e => this.overButton(4)} onMouseLeave={e => this.leaveButton(4)}>
                                        <div className="top-border" />
                                        Purple IIV
                                    </th>
                                    <th className={`${selectedPlan === 3 ? 'disabled' : activeColumn === 3 ? 'active-col' : ''}`} onMouseOver={e => this.overButton(3)} onMouseLeave={e => this.leaveButton(3)}>
                                        <div className="top-border" />
                                        Purple III
                                    </th>
                                    <th className={`${selectedPlan === 2 ? 'disabled' : activeColumn === 2 ? 'active-col' : ''}`} onMouseOver={e => this.overButton(2)} onMouseLeave={e => this.leaveButton(2)}>
                                        <div className="top-border" />
                                        Purple II
                                    </th>
                                    <th className={`${selectedPlan === 1 ? 'disabled' : activeColumn === 1 ? 'active-col' : ''}`} onMouseOver={e => this.overButton(1)} onMouseLeave={e => this.leaveButton(1)}>
                                        <div className="top-border" />
                                        Purple I
                                    </th>
                                </tr>
                            </thead>
                            <tbody>
                                {plans.map((item, index) => <tr key={index}>
                                    <td>{item.plan}</td>
                                    <td className={`${selectedPlan === 4 ? 'disabled' : activeColumn === 4 ? 'active-col' : ''}`} onMouseOver={e => this.overButton(4)} onMouseLeave={e => this.leaveButton(4)}>{item.iv}</td>
                                    <td className={`${selectedPlan === 3 ? 'disabled' : activeColumn === 3 ? 'active-col' : ''}`} onMouseOver={e => this.overButton(3)} onMouseLeave={e => this.leaveButton(3)}>{item.iii}</td>
                                    <td className={`${selectedPlan === 2 ? 'disabled' : activeColumn === 2 ? 'active-col' : ''}`} onMouseOver={e => this.overButton(2)} onMouseLeave={e => this.leaveButton(2)}>{item.ii}</td>
                                    <td className={`${selectedPlan === 1 ? 'disabled' : activeColumn === 1 ? 'active-col' : ''}`} onMouseOver={e => this.overButton(1)} onMouseLeave={e => this.leaveButton(1)}>{item.i}</td>
                                </tr>)}
                                <tr>
                                    <td></td>
                                    {planButtons.map((item, index) => <td key={index} className={`${selectedPlan === item ? 'disabled' : activeColumn === item ? 'active-col' : ''}`}  onMouseOver={e => this.overButton(item)} onMouseLeave={e => this.leaveButton(item)}>
                                        <button className={`btn-2 btn-2-outline select-btn ${selectedPlan === item ? 'disabled' : ''}`} disabled={selectedPlan === item} onClick={e => this.selectPlan(item)} onMouseOver={e => this.overButton(item)} onMouseLeave={e => this.leaveButton(item)}>{selectedPlan === item ? 'Current Plan' : 'Select Plan'}</button>
                                        <div className="bottom-border" />
                                    </td>)}
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>}
                {depth === 1 && <div className="container payment-area">
                    <h1 className="show-web">Make Payment</h1>
                    <ReactSVG src={left} className="svg-icon back-icon show-web" onClick={e => this.goBack()} />
                    <div className="main-content">
                        <div className="row">
                            <div className="col-lg-12 form-group">
                                <label>Card Number</label>
                                <InputBox icon={card} value={card_number} placeholder="Card Number" onChange={val => this.updateValue('card_number', val)}/>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-lg-4 form-group">
                                <label>Month</label>
                                <InputBox value={month} placeholder="Month" onChange={val => this.updateValue('month', val)}/>
                            </div>
                            <div className="col-lg-4 form-group">
                                <label>Year</label>
                                <InputBox value={year} placeholder="Year" onChange={val => this.updateValue('year', val)}/>
                            </div>
                            <div className="col-lg-4 form-group">
                                <label>CVV2</label>
                                <InputBox value={cvv2} placeholder="CVV2" onChange={val => this.updateValue('cvv2', val)}/>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-lg-8 form-group">
                                <label>Coupon Code</label>
                                <InputBox value={coupon_code} placeholder="12345676" onChange={val => this.updateValue('coupon_code', val)}/>
                            </div>
                            <div className="col-lg-4 form-group v-c">
                                <button className="btn-2 btn-2-outline">Check Code</button>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-lg-12 form-group">
                                <label>Referal Code</label>
                                <InputBox value={referal_code} placeholder="dsf2343213jb§134" onChange={val => this.updateValue('referal_code', val)}/>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-lg-12 form-group toggle-area">
                                <Toggle value={check_sign} className="custom-toggle" onChange={val => this.updateValue('check_sign', val)} />
                                <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.</p>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-lg-12 form-group">
                                <button className="btn-2 primary-btn">Make Payment</button>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-lg-12 form-group policy">
                                <label>Cancellation Policy</label>
                                <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.</p>
                            </div>
                        </div>
                    </div>
                </div>}
            </div>
        );
    }
}