import React from 'react';
import './CheckSection.scss';
import { ReactSVG } from 'react-svg';
import { CheckBox } from '../';
import down from '../../Assets/Icon/arrow-down.svg'

export default class CheckSection extends React.Component {
    state = {
        show_all: false,
        // data: this.props.data || []
    }

    updateData = (item, val) => {
        // let { data } = this.state;
        let { data } = this.props;
        let index = data.findIndex(temp => temp.id === item.id);
        data[index].checked = val;
        // this.setState({data});
        this.props.onChange(data);
    }

    render() {
        const { data } = this.props;
        const { className, title } = this.props;
        const {  show_all } = this.state;
        return (
            <div className={`checksection-component v-r ${className || ''} `}>
                <div className="checksection-name v-c" onClick={e => this.setState({show_all: !show_all})}>
                    <div className="checksection-title">{title}</div>
                    <ReactSVG src={down} className={`svg-icon ${show_all ? 'reversed' : ''}`}/>
                </div>
                {show_all && <div className="checksection-content">
                    {data.map((item, index) => <div className="checksection-item v-c" key={index}>
                        <CheckBox className="check-box" value={item.checked} onChange={val => this.updateData(item, val)} />
                        <p>{item.name}</p>
                    </div>)}
                </div>}
            </div>
        )
    };
};