import React from 'react';
import './CreditModal.scss';
import { Modal } from 'react-bootstrap';
import { toggleCreditModal } from '../../Redux/Actions';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import add from '../../Assets/Icon/add.svg';
import { ReactSVG } from 'react-svg'
import envelope from '../../Assets/Icon/envelope.svg';
import facebook from '../../Assets/Icon/facebook-logo.png';
import twitter from '../../Assets/Icon/twitter-logo.png';
import {
    EmailShareButton,
    FacebookShareButton,
    TwitterShareButton,
} from "react-share";

class CreditModal extends React.Component {
    state = {
        isOpen: false,
        picture: '',
        id: ''
    }

    static getDerivedStateFromProps(props, state) {
        return {
            isOpen: props.isOpen,
            picture: props.isOpen !== state.isOpen && props.creditModalInfo && props.creditModalInfo.picture?  `${process.env.REACT_APP_IMAGE_DOMAIN}${props.creditModalInfo.picture}` : state.picture,
            id: props.isOpen !== state.isOpen && props.creditModalInfo && props.creditModalInfo.id?  props.creditModalInfo.id : state.id,
        }
    }

    closeModal = () => {
        this.props.toggleCreditModal(false);
    }

    onHide = () => {
    }

    copyMyLink = () => {
        this.myUrl.select();
        document.execCommand('copy');
    }

    render() {
        const { id, isOpen, picture } = this.state;
        const ShareURL = `https://gradegetter.com/id=${id}`;
            // process.env.REACT_APP_PUBLIC_DOMAIN
        return (
            <Modal show={isOpen} onHide={this.onHide} className="credit-modal-component">
                <div className="credit-content">
                    <div className="close-btn"> <ReactSVG src={ add } className='icon-close' onClick={ () => this.closeModal() }/> </div>
                    <div className="img-area">
                        <div className="bg" />
                        {picture && <img className="picture" src={picture} alt="avatar" />}
                    </div>
                    <div className="content-area v-r">
                        <h1>Share Better Grades</h1>
                        <p>Give $25 in GradeGetter credit to a friend and get $25 in credit for yourself after your friend completes their first lesson.</p>
                        <h2>Share via link</h2>
                        <div className="url-area url-btn v-c">
                            <input className="url-input" value={ShareURL} readOnly ref={(input) => this.myUrl = input}/>
                            <div className="predend-text" onClick={e => this.copyMyLink()}>COPY</div>
                        </div>
                        <div className="v-c btn-area">
                            <div className="share-btn url-btn v-c">
                                <EmailShareButton url={ShareURL} className="share-btn-content">
                                    <ReactSVG src={ envelope } className='svg-icon'/>
                                    <p>Share via Email</p>
                                </EmailShareButton>
                            </div>
                            <div className="share-btn url-btn v-c">
                                <FacebookShareButton url={ShareURL} className="share-btn-content">
                                    <img src={facebook} alt='facebook' />
                                    <p>Share via Facebook</p>
                                </FacebookShareButton>
                            </div>
                            <div className="share-btn url-btn v-c">
                                <TwitterShareButton url={ShareURL} className="share-btn-content">
                                    <img src={twitter} alt='twitter' />
                                    <p>Share via Twitter</p>
                                </TwitterShareButton>
                            </div>
                        </div>
                    </div>
                </div>
            </Modal>
        );
    }
}

function mapStateToProps(state) {
    return {
        isOpen: state.Modal.isCreditModalOpen,
        creditModalInfo: state.Modal.creditModalInfo,
    }
}
  
export default connect(mapStateToProps, { toggleCreditModal })(withRouter(CreditModal));
