import React from 'react';
import './ClientFeedback.scss';
import StarRatings from 'react-star-ratings';
import { StarSVGViewBox, StarSVGIconPath } from '../../Constant';

export default class ClientFeedback extends React.Component {
    render() {
        const { item, className } = this.props;
        const nameArray = item.name.split(' ');
        const initialName = nameArray[0][0] + (nameArray[1][0] ? nameArray[1][0] : nameArray[0][1]);
        return (
            <div className={`clientfeedback-component ${className || ''}`}>
                <div className={`initial initial-${item.level}`}>{initialName}</div>
                <div className="user-info">
                    <div className="basic"> 
                        <div><span className="name">{item.name}</span> from {item.address}</div>
                        <div>{item.comment_date}</div>
                    </div>
                    <div className="sub-info">
                        <div className="subject">Knowledge: </div>
                        <StarRatings
                            rating={item.knowledge}
                            starDimension="16px"
                            starSpacing="2.14px"
                            starRatedColor="#23A4EF"
                            starEmptyColor="#D3EDFC"
                            svgIconViewBox={StarSVGViewBox}
                            svgIconPath={StarSVGIconPath}
                        />
                    </div>
                    <div className="sub-info">
                        <div className="subject">Presentation: </div>
                        <StarRatings
                            rating={item.presentation}
                            starDimension="16px"
                            starSpacing="2.14px"
                            starRatedColor="#23A4EF"
                            starEmptyColor="#D3EDFC"
                            svgIconViewBox={StarSVGViewBox}
                            svgIconPath={StarSVGIconPath}
                        />
                    </div>
                    <div className="description">{item.description}</div>
                </div>
            </div>
        );
    }
}
