import React from 'react';
import './MyClientsPage.scss';
import search from '../../Assets/Icon/search.svg';
import { InputBox, Avatar } from '../../Components';
import { TutorList } from './TutorList';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import {
    getMystudentsAction,
} from "../../Redux/Actions";

class MyClientsPage extends React.Component {
    state = {
        Tutors: TutorList,
        searchText: '',
        depth: 0,
        selectedTutor: {},
    }

    static getDerivedStateFromProps(props, state) {
        return {
            depth: props.depth
        }
    }

    componentDidMount() {
        this.props.getMystudentsAction()
        if (this.props.match.params.id) {
            let index = this.props.myStudents.findIndex(temp => temp.id === parseInt(this.props.match.params.id, 10));
            this.selectTutor(this.props.myStudents[index]);
        };
    };

    selectTutor = async (tutor) => {
        let temp = tutor || {};
        this.setState({ 
            selectedTutor: temp,
        });
        await this.props.formatTitle();
        this.props.updateTitle('push', temp.name, 2);
    }

    scheduleLessn = (tutor_id) => {
        this.props.history.push(`/schedule-lesson/${tutor_id}`);
    }

    render() {
        const {myStudents} = this.props;
        const { searchText, depth, selectedTutor, Tutors } = this.state;
        const list = myStudents.filter(tutor => tutor.name.toLowerCase().indexOf(searchText.toLowerCase()) > -1);
        return (
            <div className="mytutors-page">
                <div className={`tutor-list v-r ${depth === 0 ? 'activate' : ''}`}>
                    <InputBox className="search-contact" value={searchText} icon={search} placeholder="Search..." onChange={val=>this.setState({searchText:val})} />
                    <div className="tutors v-r scroll-area">
                        {list.map((item, index) => <div className={`tutor-container ${selectedTutor.id === item.id ? 'selected' : ''}`} key={index} onClick={e => this.selectTutor(item)}>
                            <div className="tutor v-c">
                                <Avatar className="avatar" info={item} />
                                <div className="info v-c">
                                    <h4>{item.name}</h4>
                                </div>
                            </div>
                        </div>)}
                    </div>
                </div>
                <div className={`content-area v-r ${depth >= 1 ? 'activate' : ''}`}>
                    {selectedTutor.id > 0 && <div className="tutor-content v-r">
                        <div className="client-info v-c show-web-flex client-header">
                            <h4>{selectedTutor.name}</h4>
                            <button className="btn-2 btn-2-outline show-web-flex" style={{marginLeft:'10px', alignItems: 'center'}} onClick={e => this.scheduleLessn(selectedTutor.id)}>Book</button>
                        </div>
                        <div className="client-info">
                            <p className="client-property">Name</p>
                            <p className="client-value">
                                {selectedTutor.name}
                            </p>
                            <button className="btn-2 btn-2-outline show-mobile-flex book-btn" onClick={e => this.scheduleLessn(selectedTutor.id)}>Book</button>
                        </div>
                        <div className="client-info">
                            <p className="client-property">Email</p>
                            <p className="client-value">{selectedTutor.email}</p>
                        </div>
                        <div className="client-info">
                            <p className="client-property">Phone</p>
                            <p className="client-value">{selectedTutor.phone}</p>
                        </div>
                        <div className="client-info">
                            <p className="client-property">Tutoring rate</p>
                            <p className="client-value">${selectedTutor.tutor_rate}</p>
                        </div>                       
                        <div className="client-info">
                            <p className="client-property">Client information</p>
                            <p className="client-value">{selectedTutor.client_information}</p>
                        </div>                       
                        <div className="client-info v-r">
                            <div className="client-info inside-info">
                                <p className="client-property">You can book for this client</p>
                                <div className="client-value">
                                    {/* {selectedTutor.tutor_can_book?
                                        "Client have enough credit to book"
                                        :
                                        "Client does not have enough credit to book"
                                    } */}
                                    Client has {selectedTutor.plan_remaining_hour} tutoring hour to book
                                </div>
                            </div>
                        </div>                        
                    </div>}
                </div>
            </div>
        );
    }
}

const mapStateToProps = (state) => ({
    myStudents: state.Core.myStudents,
});

export default connect(mapStateToProps, {
    getMystudentsAction,
})(withRouter(MyClientsPage));