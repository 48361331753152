import React from 'react';
import './MobileSidebar.scss';
import logo from '../../Assets/Header/logo.png';
import { NavLink, withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { toggleMobileSidebar, toggleCreditModal } from '../../Redux/Actions';
import { ReactSVG } from 'react-svg'
import giftbox from '../../Assets/Icon/giftbox.svg';
import { Menus } from '../Sidebar/Menus';
import { CSSTransition } from 'react-transition-group';
import arrowup from '../../Assets/Icon/arrow-up.svg';
import arrowdown from '../../Assets/Icon/arrow-down.svg';

class MobileSidebar extends React.Component {

    state = {
        isMobileOpen: false,
        isPrevOpen: false,
        menu: Menus
    }

    static getDerivedStateFromProps(props, state) {
        return {
            isMobileOpen: props.isMobileOpen,
            isPrevOpen: state.isMobileOpen,
        }
    }

    closeSidebar = () => {
      this.props.toggleMobileSidebar(false);
    }

    getCredit = () => {
      this.props.toggleMobileSidebar(false);
      this.props.toggleCreditModal(true);
    }

    toggleMenu = async (index) => {
        let temp = this.state.menu;
        temp[index].opened = !temp[index].opened;
        this.setState({
          menu: temp
        })
    }

    renderMenu = () => {
        return this.state.menu.map((item, index) => (
            item.children.length === 0 ? 
              item.id === 6 ? <NavLink exact isActive={(match, location) => {return (location.pathname==='/'||location.pathname.substring(0,7)==='/tutors')} } to={item.link} className="menu-item header-menu-item v-c" key={index} onClick={e => this.closeSidebar()}><ReactSVG src={item.icon} className="svg-icon start-icon" /><p>{item.name}</p></NavLink> 
                            : <NavLink exact to={item.link} className="menu-item header-menu-item v-c" key={index} onClick={e => this.closeSidebar()}><ReactSVG src={item.icon} className="svg-icon start-icon" /><p>{item.name}</p></NavLink> 
            : <div key={index} style={{position: 'relative'}}>
              <div className={`menu-header v-c ${item.subItemActive ? 'sub-active' : ''}`} key={index} onClick={e => this.toggleMenu(index)}>
                <ReactSVG src={item.icon} className="svg-icon start-icon" /> 
                <p>{item.name}</p>
                <ReactSVG src={item.opened ? arrowup : arrowdown} className="svg-icon end-icon" /> 
              </div>
              <CSSTransition in={item.opened} timeout={200} classNames="sub-menu">
                <div className={`sub-menu v-r`}>
                  {item.children.map((subitem, subindex) => <NavLink exact to={subitem.link} className="menu-item sub-menu-item" key={subindex} onClick={e => this.closeSidebar()}>
                    {subitem.name}
                  </NavLink>)}
                </div>
              </CSSTransition>
            </div>
        ));
    }

    render() {
        const { isMobileOpen, isPrevOpen } = this.state;
        return (
            <div className={`mobilesidebar-component ${isMobileOpen ? 'active' : isPrevOpen ? 'inactive' : ''}`}>
                <div className="mobilesidebar-wrapper">
                    <div className="mobilesidebar-content v-r">
                        <div className="timer">9:41</div>
                        <div className="padding"> <img src={logo} alt="logo" /> </div>
                        <div className="padding custom-links v-r">
                            <div className="get-30 v-c" onClick={e => this.getCredit()}>
                                <p>GET $25</p>
                                <ReactSVG src={giftbox} className="svg-icon"/>
                            </div>
                            <NavLink to="/schedule-lesson" className="btn-2 primary-btn v-c h-c" onClick={e => this.closeSidebar()}>Schedule a lesson</NavLink>
                        </div>
                        <div className="sidebar-menu v-r">
                            {this.renderMenu()}
                        </div>
                    </div>
                    <div className="mobilesidebar-block" onClick={ () => this.closeSidebar() }></div>
                </div>
            </div>
        );
    }
}

function mapStateToProps(state) {
  return {
    isMobileOpen: state.Sidebar.isMobileOpen,
  }
}

export default connect(mapStateToProps, { toggleMobileSidebar, toggleCreditModal })(withRouter(MobileSidebar));