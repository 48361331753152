import {
    GET_MYSTUDENTS,
    GET_MYSTUDENTS_SUCCESS,
    GET_MYSTUDENTS_FAIL,
    GET_MYBLOGS,
    GET_MYBLOGS_SUCCESS,
    GET_MYBLOGS_FAIL,
    GET_PROFILE_UPDATE,
    GET_PROFILE_UPDATE_SUCCESS,
    GET_PROFILE_UPDATE_FAIL,
    GET_MY_PROFILE_INFO,
    GET_MY_PROFILE_INFO_SUCCESS,
    GET_MY_PROFILE_INFO_FAIL,GET_PENDING_LESSON,
    GET_PENDING_LESSON_SUCCESS,
    GET_PENDING_LESSON_FAIL,
    GET_COMPLETED_LESSON,
    GET_COMPLETED_LESSON_SUCCESS,
    GET_COMPLETED_LESSON_FAIL,
    GET_CITIES,
    GET_CITIES_FAIL,
    GET_CITIES_SUCCESS,
    GET_SCHOOLS,
    GET_SCHOOLS_FAIL,
    GET_SCHOOLS_SUCCESS,
    GET_SUBJECTS,
    GET_SUBJECTS_FAIL,
    GET_SUBJECTS_SUCCESS,
    GET_LEVELS,
    GET_LEVELS_SUCCESS,
    GET_LEVELS_FAIL,
    GET_LEVEL_SUBJECTS,
    GET_LEVEL_SUBJECTS_SUCCESS,
    GET_LEVEL_SUBJECTS_FAIL,
    GET_TOPICLIST,
    GET_TOPICLIST_SUCCESS,
    GET_TOPICLIST_FAIL,
    GET_ALERTS,
    GET_ALERTS_SUCCESS,
    GET_ALERTS_FAIL,
    GET_BLOCK_SCHEDULE_LIST,
    GET_BLOCK_SCHEDULE_LIST_SUCCESS,
    GET_BLOCK_SCHEDULE_LIST_FAIL,
    GET_PAYMENT_HISTORY,
    GET_PAYMENT_HISTORY_SUCCESS,
    GET_PAYMENT_HISTORY_FAIL,
    GET_BIWEEKLY,
    GET_BIWEEKLY_SUCCESS,
    GET_BIWEEKLY_FAIL,
    GET_BANK_DETAIL,
    GET_BANK_DETAIL_SUCCESS,
    GET_BANK_DETAIL_FAIL,
    GET_MESSAGE,
    GET_MESSAGE_FAIL,
    GET_MESSAGE_SUCCESS,
    GET_MESSAGE_USER_LIST,
    GET_MESSAGE_USER_LIST_FAIL,
    GET_MESSAGE_USER_LIST_SUCCESS,
} from "../Type";

import {
    getMystudents,
    getMyblogs,
    profileUpdate,
    getMypofileInfo,
    getPendingLesson,
    getCompletedLesson,
    getCities,
    getSchools,
    getSubjects,
    getLevels,
    getTopicList,
    getAlerts,
    getBlockScheduleList,
    getPaymentHistory,
    getBiweekly,
    getBankDetail,
    getMessage,
    getMessageUserList,
    getLevelSubjects
} from "../../Api/Core";

  
export const getMystudentsAction = () => async (dispatch) => {
    dispatch({ type: GET_MYSTUDENTS });
    const res = await getMystudents();
    if (res && res.data && res.data.data) {
        dispatch({ type: GET_MYSTUDENTS_SUCCESS, payload: res.data.data });
    } else {
        dispatch({ type: GET_MYSTUDENTS_FAIL, payload: res.data });
    }
};

export const getMyblogsAction = () => async (dispatch) => {
    dispatch({ type: GET_MYBLOGS });
    const res = await getMyblogs();
    if (res && res.data && res.data.data) {
        dispatch({ type: GET_MYBLOGS_SUCCESS, payload: res.data.data });
    } else {
        dispatch({ type: GET_MYBLOGS_FAIL, payload: res.data });
    }
};

export const profileUpdateAction = (payload) => async (dispatch) => {
    dispatch({ type: GET_PROFILE_UPDATE });
    const res = await profileUpdate(payload);
    if (res && res.data.id) {
        dispatch({ type: GET_PROFILE_UPDATE_SUCCESS, payload: res.data });
    } else {
        dispatch({ type: GET_PROFILE_UPDATE_FAIL, payload: res.data });
    }
};

export const getMypofileInfoAction = () => async (dispatch) => {
    dispatch({ type: GET_MY_PROFILE_INFO });
    const res = await getMypofileInfo();
    if (res && res.data) {
        dispatch({ type: GET_MY_PROFILE_INFO_SUCCESS, payload: res.data });
    } else {
        dispatch({ type: GET_MY_PROFILE_INFO_FAIL, payload: res.data });
    }
};

export const getPendingLessonAction = () => async (dispatch) => {
    dispatch({ type: GET_PENDING_LESSON });
    const res = await getPendingLesson();
    if (res && res.data && res.data.data) {
      dispatch({ type: GET_PENDING_LESSON_SUCCESS, payload: res.data.data });
    } else {
      dispatch({ type: GET_PENDING_LESSON_FAIL, payload: res.data });
    }
};

export const getCompletedLessonAction = () => async (dispatch) => {
    dispatch({ type: GET_COMPLETED_LESSON });
    const res = await getCompletedLesson();
    if (res && res.data && res.data.data) {
      dispatch({ type: GET_COMPLETED_LESSON_SUCCESS, payload: res.data.data });
    } else {
      dispatch({ type: GET_COMPLETED_LESSON_FAIL, payload: res.data });
    }
};

export const getCitiesAction = (city, id) => async (dispatch) => {
    dispatch({ type: GET_CITIES });
    const res = await getCities(city, id);
    if (res && res.data && res.data.data) {
      dispatch({ type: GET_CITIES_SUCCESS, payload: res.data.data });
    } else {
      dispatch({ type: GET_CITIES_FAIL, payload: res.data });
    }
};

export const getSchoolAction = () => async (dispatch) => {
    dispatch({ type: GET_SCHOOLS });
    const res = await getSchools();
    if (res && res.data && res.data.data) {
      dispatch({ type: GET_SCHOOLS_SUCCESS, payload: res.data.data });
    } else {
      dispatch({ type: GET_SCHOOLS_FAIL, payload: res.data });
    }
};

export const getSubjectAction = (level_id) => async (dispatch) => {
    dispatch({ type: GET_SUBJECTS });
    const res = await getSubjects(level_id);
    if (res && res.data && res.data.data) {
        dispatch({ type: GET_SUBJECTS_SUCCESS, payload: res.data.data });
    } else {
        dispatch({ type: GET_SUBJECTS_FAIL, payload: res.data });
    }
};

export const getLevelAction = () => async (dispatch) => {
    dispatch({ type: GET_LEVELS });
    const res = await getLevels();
    if (res && res.data && res.data.data) {
      dispatch({ type: GET_LEVELS_SUCCESS, payload: res.data.data });
    } else {
      dispatch({ type: GET_LEVELS_FAIL, payload: res.data });
    }
};

export const getLevelSubjectAction = () => async (dispatch) => {
    dispatch({ type: GET_LEVEL_SUBJECTS });
    const res = await getLevelSubjects();
    if (res && res.success) {
        dispatch({ type: GET_LEVEL_SUBJECTS_SUCCESS, payload: res.data });
    } else {
        dispatch({ type: GET_LEVEL_SUBJECTS_FAIL, payload: res.data });
    }
};

export const getTopicListAction = () => async (dispatch) => {
    dispatch({ type: GET_TOPICLIST });
    const res = await getTopicList();
    if (res && res.data && res.data.data) {
        dispatch({ type: GET_TOPICLIST_SUCCESS, payload: res.data.data });
    } else {
        dispatch({ type: GET_TOPICLIST_FAIL, payload: res.data });
    }
};

export const getAlertsAction = () => async (dispatch) => {
    dispatch({ type: GET_ALERTS });
    const res = await getAlerts();
    if (res && res.data && res.data.data) {
        dispatch({ type: GET_ALERTS_SUCCESS, payload: res.data.data });
    } else {
        dispatch({ type: GET_ALERTS_FAIL, payload: res.data });
    }
};

export const getBlockScheduleListAction = () => async (dispatch) => {
    dispatch({ type: GET_BLOCK_SCHEDULE_LIST });
    const res = await getBlockScheduleList();
    if (res && res.data && res.data.data) {
        dispatch({ type: GET_BLOCK_SCHEDULE_LIST_SUCCESS, payload: res.data.data });
    } else {
        dispatch({ type: GET_BLOCK_SCHEDULE_LIST_FAIL, payload: res.data });
    }
};

export const getPaymentHistoryAction = () => async (dispatch) => {
    dispatch({ type: GET_PAYMENT_HISTORY });
    const res = await getPaymentHistory();
    if (res && res.data && res.data.data) {
        dispatch({ type: GET_PAYMENT_HISTORY_SUCCESS, payload: res.data.data });
    } else {
        dispatch({ type: GET_PAYMENT_HISTORY_FAIL, payload: res.data });
    }
};

export const getBiweeklyAction = (data) => async (dispatch) => {
    dispatch({ type: GET_BIWEEKLY });
    const res = await getBiweekly(data);
    if (res && res.data && res.data.data) {
        dispatch({ type: GET_BIWEEKLY_SUCCESS, payload: res.data.data });
    } else {
        dispatch({ type: GET_BIWEEKLY_FAIL, payload: res.data });
    }
};

export const getBankDetailAction = () => async (dispatch) => {
    dispatch({ type: GET_BANK_DETAIL });
    const res = await getBankDetail();
    if (res && res.data && res.data) {
        dispatch({ type: GET_BANK_DETAIL_SUCCESS, payload: res.data });
    } else {
        dispatch({ type: GET_BANK_DETAIL_FAIL, payload: res.data });
    }
};

export const getMessageAction = (id) => async (dispatch) => {
    dispatch({ type: GET_MESSAGE });
    const res = await getMessage(id);
    if (res && res.data && res.data.data) {
      dispatch({ type: GET_MESSAGE_SUCCESS, payload: res.data.data });
    } else {
      dispatch({ type: GET_MESSAGE_FAIL, payload: res.data });
    }
};

export const getMessageUserListAction = () => async (dispatch) => {
    dispatch({ type: GET_MESSAGE_USER_LIST });
    const res = await getMessageUserList();
    if (res && res.data && res.data.data) {
      dispatch({ type: GET_MESSAGE_USER_LIST_SUCCESS, payload: res.data.data });
    } else {
      dispatch({ type: GET_MESSAGE_USER_LIST_FAIL, payload: res.data });
    }
};

