import React from 'react';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { Header } from '../Components';
import { Helmet } from 'react-helmet';
import { getMypofileInfoAction, getCitiesAction, getMystudentsAction } from '../Redux/Actions';
import _ from 'lodash'

class Page extends React.Component {
    state = {
        titleList: [],
        depth: 0,
        profileInfo: '',
        user: '',
        myStudents_names: []
    };

    componentDidMount() {
        if(_.isEmpty(this.props.myStudents)) {
            this.props.getMystudentsAction().then(()=>{
                this.updateMyStudentsNames();
            });            
        } else {
            this.updateMyStudentsNames();
        }       

        let temp = this.state.titleList;
        temp.push(this.props.title);
        this.setState({
            titleList: temp,
            depth: 0
        });
    };

    updateMyStudentsNames = () => {
        const { myStudents } = this.props;        
        var myStudents_names = [];
        myStudents.map((data, index)=>{
            myStudents_names = [...myStudents_names, data.name]
        });
        this.setState({myStudents_names});
        
        if(_.isEmpty(this.props.myProfileInfo)){
            this.props.getMypofileInfoAction().then(()=> {
                this.props.getCitiesAction('', this.props.myProfileInfo.region_id).then(()=> {
                    this.updateProfileInfo();
                });
            });
        } else {
            this.updateProfileInfo();
        };
    }

    updateProfileInfo = () => {
        const {myProfileInfo} = this.props;        
        this.setState({
            profileInfo: myProfileInfo
        });
        this.intercomSetting(myProfileInfo);
    }

    updateTitle = (flag, newTitle = '', lengthLimit = 0) => {
        let { titleList, depth } = this.state;
        if (flag === 'push') {
            if (lengthLimit > 1 && titleList.length === lengthLimit) {
                titleList[depth] = newTitle;
            } else {
                depth++;
                titleList.push(newTitle);
            };
        } else {
            depth--;
            titleList.pop()
        };
        this.setState({
            titleList: titleList,
            depth: depth
        });
    };

    formatTitle = () => {
        let temp = [];
        temp.push(this.props.title);
        this.setState({
            titleList: temp,
            depth: 0
        });
    };

    loader = (value) =>{
        this.props.sendLoading(value)
    }

    headerProfileInfo = (payload) =>{
        this.setState({profileInfo: payload});
    };

    intercomSetting = (data) => {
        window.intercomSettings = {
            app_id: 'mx9avtti',
            user_id: data.id, // user id
            profile_pic: data.picture? process.env.REACT_APP_IMAGE_DOMAIN + data.picture : '', // url to profile picture
            first_name: data.first_name,
            last_name: data.last_name,
            phone: data.phone,
            email: data.email, // user email
            // city: _.find(this.props.cities, {id: Number(data.region_id)}).name ? _.find(this.props.cities, {id: Number(data.region_id)}).name : '', // user region
            city: data.city,
            account_type: 'tutor',
            account_status: data.is_verified, // is_verified field, if true -> verified, if not, unverified
            student_assigned: this.state.myStudents_names, // you put the student name here, you get from GET /connections api
            profile_title: data.profile_title, // from GET /me
            effort_score: data.effort_score, // from GET /me
        };
    };
    

    render() {
        const { titleList, depth, profileInfo } = this.state;
        const { page } = this.props;

        return (
            <React.Fragment>
                <Header titleArray={titleList} depth={depth} profileInfo={profileInfo}  updateTitle={(f, t) => this.updateTitle(f,t)}></Header>
                <Helmet>
                    <title>
                        Access Hundreds Of Online Tutoring Jobs | GradeGetter
                    </title>
                    <meta
                        property="description"
                        content="Become A Tutor In Under 30 Minutes And Access Hundreds Of Online Tutoring Jobs For K-12, Test Prep, and College"
                    />

                    <meta
                        itemprop="name"
                        content="Access Hundreds Of Online Tutoring Jobs | GradeGetter"
                    />
                    <meta
                        itemprop="description"
                        content="Become A Tutor In Under 30 Minutes And Access Hundreds Of Online Tutoring Jobs For K-12, Test Prep, and College"
                    />
                    <meta
                        itemprop="keywords"
                        content="online tutoring, Math tutors, private tutors"
                    />

                    <meta name="twitter:card" content="summary" />
                    <meta
                        name="twitter:title"
                        content="Access Hundreds Of Online Tutoring Jobs | GradeGetter"
                    />
                    <meta
                        name="twitter:description"
                        content="Become A Tutor In Under 30 Minutes And Access Hundreds Of Online Tutoring Jobs For K-12, Test Prep, and College"
                    />

                    <meta
                        property="og:title"
                        content="Access Hundreds Of Online Tutoring Jobs | GradeGetter"
                    />
                    <meta
                        property="og:description"
                        content="Become A Tutor In Under 30 Minutes And Access Hundreds Of Online Tutoring Jobs For K-12, Test Prep, and College"
                    />
                    <meta property="og:image" content="https://tutor.gradegetter.com/gradegetter.png" />
                    <meta property="og:url" content={window.location.origin} />
                    <meta
                        property="og:site_name"
                        content="Access Hundreds Of Online Tutoring Jobs | GradeGetter"
                    />
                    <meta property="og:locale" content="en_US" />
                    <meta property="og:type" content="website" />
                    </Helmet>
                <div className="content">
                    {page({updateTitle: (f, n, t) => this.updateTitle(f, n, t), loader: (v) => this.loader(v), headerProfileInfo: (v) => this.headerProfileInfo(v), depth: depth, formatTitle: () => this.formatTitle()})}
                </div>
            </React.Fragment>
        );
    }
}

const mapStateToProps = (state) => ({
    myProfileInfo: state.Core.myProfileInfo,
    cities: state.Core.cities,
    myStudents: state.Core.myStudents,
});

export default connect(mapStateToProps, {
    getMypofileInfoAction,
    getCitiesAction,
    getMystudentsAction
 })(withRouter(Page));
