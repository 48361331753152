import { REHYDRATE } from 'redux-persist';
import { AUTH_UPDATE_TOKEN, GET_MY_INFO, GET_MY_INFO_FAIL, GET_MY_INFO_SUCCESS, UPDATE_MY_INFO, UPDATE_MY_INFO_FAIL, UPDATE_MY_INFO_SUCCESS, AUTH_UPDATE_PLAN, UPDATE_PASSWORD, UPDATE_PASSWORD_SUCCESS, UPDATE_PASSWORD_FAIL, UPDATE_QUALIFICATION, AUTH_SIGN_OUT } from '../Type';

const INITIAL = {
    token: '',
    isLoggedIn: false,

    user: {},

    update_info_loading: false,
    update_info_success: false,

    plan_id: null,
    pay_per: null,

    update_password_loading: false,
    update_password_success: false,
}

export default (state = INITIAL, action) => {
    switch (action.type) {
        case REHYDRATE: {
            if (!action.payload) return state;
            const { Authentication } = action.payload;
            const { token, isLoggedIn, user, plan_id, pay_per } = Authentication;
            if (token && token.length > 0) {
                sessionStorage.setItem('token', token);
                return { token, isLoggedIn, user, plan_id, pay_per };
            } else {
                return state;
            }
        }
        case AUTH_UPDATE_TOKEN: {
            const { token } = action.data;
            sessionStorage.setItem('token', token);
            return {
                ...state,
                token,
                isLoggedIn: token && token.length > 0 ? true : false,
            }
		}
		case AUTH_SIGN_OUT: {
			sessionStorage.clear();
			return {
				...state,
				token: '',
				isLoggedIn: false,
				user: {},
				plan_id: null,
				pay_per: null
			}
		}
        case GET_MY_INFO: 
        case GET_MY_INFO_FAIL: {
            return {
                ...state,
                user: {}
            }
        }
        case GET_MY_INFO_SUCCESS: {
            return {
                ...state,
                user: action.data
            }
        }
        case UPDATE_MY_INFO: {
            return {
                ...state,
                update_info_loading: true,
                update_info_success: false
            }
        }
        case UPDATE_MY_INFO_SUCCESS: {
            return {
                ...state,
                update_info_loading: false,
                update_info_success: true,
                user: action.data
            }
        }
        case UPDATE_MY_INFO_FAIL: {
            return {
                ...state,
                update_info_loading: false,
                update_info_success: false,
            }
        }
        case AUTH_UPDATE_PLAN: {
            const { plan_id, pay_per } = action.data;
            return {
                ...state,
                plan_id: plan_id, 
                pay_per: pay_per
            }
        }
        case UPDATE_PASSWORD: {
            return {
                ...state,
                update_password_loading: true,
                update_password_success: false,
            }
        }
        case UPDATE_PASSWORD_SUCCESS: {
            return {
                ...state,
                update_password_loading: false,
                update_password_success: true,
            }
        }
        case UPDATE_PASSWORD_FAIL: {
            return {
                ...state,
                update_password_loading: false,
                update_password_success: false,
            }
		}
		case UPDATE_QUALIFICATION: {
			let { index, data } = action.data;
			let user = state.user;
			user.qualifications = user.qualifications || [];
			index === -1 ? user.qualifications.push(data) : user.qualifications[index] = data;
			return {
				...state,
				user: user
			}
		}
        default:
            return state;
    }
}