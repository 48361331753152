import { post, remove, put } from './Api';

// export function* getMyInfo(){
//     const jsonData = yield Api.get("/me");
//     return jsonData;
// }

// export function* updateMyInfo(data) {
//     const jsonData = yield Api.post('/me', data, true);
//     return jsonData;
// }

// export function* updatePassword(data) {
//     const jsonData = yield Api.post("/me", data);
//     return jsonData;
// }

// export const profileUpdate = (data) => {
//     return post("/me", data);
// };

export const scheduleLesson = (data) => {
    return post("/transactions", data);
};

export const cancelLesson = (data, id) => {
    return post(`/transactions/${id}`, data);
};

export const deleteTutor = (id) => {
    return remove(`/connections/${id}`);
};

export const postBlog = (data) => {
    return post("/blogs", data);
};

export const blogUpdate = (data, blog_id) => {
    return post(`/blogs/${blog_id}`, data);
};

export const tutorNote = (data, id) => {
    return post(`/transactions/${id}`, data);
};

export const blockScheduleUpdate = (data) => {
    return post("/transactions", data);
};

export const alertInteres = (data) => {
    return post("/interests", data);
};

export const signOut = () => {
    return post("/logout");
};

export const updateBank = (data) => {
    return post("/payments/bankaccount", data);
};

export const withdrawMoney = (data) => {
    return post("/payments/withdraw", data);
};

export const createMessage = (data) => {
    return post("/messages", data);
};

export const portToFrog = (data) => {
    return post("/portToFrog", data);
};