import React from 'react';
import './VideoModal.scss';
import { Modal } from 'react-bootstrap';
import { toggleVideoModal } from '../../Redux/Actions';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import add from '../../Assets/Icon/add.svg';
import playBtn from '../../Assets/Icon/music-player-play.png';
import { ReactSVG } from 'react-svg'

class VideoModal extends React.Component {
    state = {
        isOpen: false,
        info: {},
        playing: false,
    }

    static getDerivedStateFromProps(props, state) {
        return {
            isOpen: props.isOpen,
            info: props.info,
        }
    }

    closeModal = () => {
        this.props.toggleVideoModal(false);
    }

    onHide = () => {
    }

    playVideo = (e) => {
        this.videoPlayer.play();
    }

    render() {
        const { isOpen, info, playing } = this.state;
        return (
            <Modal show={isOpen} onHide={this.onHide} className="video-modal-component">
                <div className="video-content">
                    <div className="close-btn"> <ReactSVG src={ add } className='icon-close' onClick={ () => this.closeModal() }/> </div>
                    <div className="main-area">
                        {!playing && <img className="play-btn" onClick={e => this.playVideo(e)} src={playBtn} alt="play-btn"/>}
                        <video width="100%" height="100%" preload="metadata" ref={c => this.videoPlayer = c} controls={playing} onPlay={e => this.setState({playing: true})} onEnded={e => this.setState({playing: false})}>
                            <source src={`${info}#t=0.01`} type="video/mp4" />
                        </video>
                    </div>
                </div>
            </Modal>
        );
    }
}

function mapStateToProps(state) {
    return {
        isOpen: state.Modal.isVideoModalOpen,
        info: state.Modal.info,
    }
}
  
export default connect(mapStateToProps, { toggleVideoModal })(withRouter(VideoModal));
