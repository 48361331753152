import coin from '../../Assets/Icon/coin.svg';
import profile from '../../Assets/Icon/profile.svg';
import envelope from '../../Assets/Icon/envelope.svg';
import menu from '../../Assets/Icon/menu.svg';
import notificiation from '../../Assets/Icon/notification.svg';
import training from '../../Assets/Icon/training.svg';
import blog from '../../Assets/Icon/blog.svg';
import schedule from '../../Assets/Icon/schedule.svg';
import idea from '../../Assets/Icon/idea.svg';
import help from '../../Assets/Icon/help.svg';
import share from '../../Assets/Icon/share.svg';

export const Menus = [
    {
        id: 1, name: 'Alerts', link: '/alerts', opened: false, children: [], icon: notificiation, subItemActive: false,
    },
    {
        id: 2, name: 'My Lessons', link: '', opened: false, icon: menu, subItemActive: false,
        children: [
            { name: 'Pending', link: '/pending-lesson'},
            { name: 'Completed', link: '/completed-lesson'},
        ],
    },
    {
        id: 3, name: 'My Clients', link: '/clients', opened: false, children: [], icon: profile, subItemActive: false,
    },
    {
        id: 4, name: 'My Money', link: '', opened: false, icon: coin, subItemActive: false,
        children: [
            { name: 'Payment History', link: '/payment-history'},
            { name: 'Withdraw', link: '/withdraw'},
            { name: 'Biweekly Report', link: '/biweekly-report'},
        ],
    },
    {
        id: 5, name: 'Message', link: '/message', opened: false, children: [], icon: envelope, subItemActive: false,
    },
    {
        id: 6, name: 'Training', link: '/', opened: false, children: [], icon: training, subItemActive: false,
    },
    {
        id: 7, name: 'Blog', link: '/blog', opened: false, children: [], icon: blog, subItemActive: false,
    },
    {
        id: 8, name: 'Block Schedule', link: '/block-schedule', opened: false, children: [], icon: schedule, subItemActive: false,
    },
    {
        id: 9, name: 'Online Demo', link: '/online-demo', opened: false, children: [], icon: idea, subItemActive: false,
    },
    // {
    //     id: 10, name: 'Viral', link: '/viral', opened: false, children: [], icon: share, subItemActive: false,
    // },
    {
        id: 11, name: 'Help', link: '/help', opened: false, children: [], icon: help, subItemActive: false,
    },
    {
        id: 12, name: 'Port To Frogtutoring', link: '/port-to-frogtutoring', opened: false, children: [], icon: share, subItemActive: false,
    },
];