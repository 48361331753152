import React from 'react';
import './PaymentHistoryPage.scss';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { InputBox, RoundButton, SelectBox } from '../../Components';
import search from '../../Assets/Icon/search.svg';
import left from '../../Assets/Icon/left.svg';
import right from '../../Assets/Icon/right.svg';
import {
    getPaymentHistoryAction,
} from "../../Redux/Actions";
import moment from 'moment';

class PaymentHistoryPage extends React.Component { 
    state = {
        data: [],
        filteredList: [],
        
        searchText: '',
        currentPage: 1,
        totalPage: 1,
        displayCount: 10,
    }

    componentDidMount() {
        this.props.getPaymentHistoryAction().then(()=>{
            this.filterData('', 10)
        });
    }

    changeDisplayCount = (val) => {
        const { searchText } = this.state;
        this.filterData(searchText, val);
    }

    changeSearchText = (val) => {
        const { displayCount } = this.state;
        this.filterData(val, displayCount);
    }

    changeCurrentPage = (direction) => {
        const { currentPage, totalPage } = this.state;
        if ((direction === 'previous' && currentPage === 1) || (direction === 'next' && currentPage === totalPage)) return;
        this.setState({
            currentPage: direction === 'next' ? currentPage + 1 : currentPage - 1
        })
    }

    filterData = (searchText, displayCount) => {
        const { data } = this.state;
        const {paymentHistory} = this.props;
        const filteredList = paymentHistory.filter(item => item.description.toLowerCase().indexOf(searchText.toLowerCase()) > -1);
        const totalPage = Math.ceil(filteredList.length / displayCount);

        this.setState({
            searchText: searchText,
            filteredList: filteredList,
            totalPage: totalPage,
            displayCount: displayCount,
            currentPage: 1
        })
    }

    render() {
        const {paymentHistory} = this.props;
        const { searchText, displayCount, filteredList, currentPage, totalPage } = this.state;
        const startIndex = displayCount * (currentPage - 1);
        const displayList = filteredList.slice(startIndex, startIndex + displayCount);
        return (
            <div className="paymenthistory-page v-r">
                <div className="options-area">
                    <div className="input-container v-c">
                        <InputBox className="search-input" value={searchText} icon={search} placeholder="Search..." onChange={val=>this.changeSearchText(val)} />
                        <div className="credit-area show-mobile-flex v-c">
                            <span>Your Credit</span>&nbsp;&nbsp;<b>$0.00.</b>
                        </div>
                    </div>
                    <div className="count-area v-c">
                        <RoundButton icon={left} className="show-mobile-flex" onClick={e => this.changeCurrentPage('previous')}/>
                        <p className="page-count">Page {currentPage} of {totalPage  }</p>
                        <RoundButton icon={left} className="arrow-btn show-web-flex" onClick={e => this.changeCurrentPage('previous')}/>
                        <RoundButton icon={right} className="arrow-btn" onClick={e => this.changeCurrentPage('next')}/>
                        <div className="combo-area v-c">
                            <p>Items</p>
                            <SelectBox value={displayCount} onChange={val => this.changeDisplayCount(val)}/>
                        </div>
                    </div>
                </div>
                <div className="data-area v-r">
                    <div className="data-header show-web-flex v-c">
                        <div className="cell cell-1">AMOUNT</div>
                        <div className="cell cell-2">OLD CREDIT</div>
                        <div className="cell cell-3">NEW CREDIT</div>
                        <div className="cell cell-4">PAYMENT STATUS</div>
                        <div className="cell cell-5">NOTE</div>
                        <div className="cell cell-6">DATE</div>
                    </div>
                    <div className="data-list show-web-flex v-r">
                        {displayList.map((item, index) => <div className="data-row" key={index}>
                            <div className="cell cell-1">{item.amount}</div>
                            <div className="cell cell-2">{item.old_credit}</div>
                            <div className="cell cell-3">{item.new_credit}</div>
                            <div className="cell cell-4">{item.status}</div>
                            <div className="cell cell-5">{item.description}</div>
                            <div className="cell cell-6">{moment(item.payment_date).format("MMM D, YYYY hh:mm a")}</div>
                        </div>)}
                    </div>
                    <div className="show-mobile-flex v-r">
                        {displayList.map((item, index) => <div className="mobile-row v-r" key={index}>
                            <div className="data-row">
                                <div className="left-col v-r"> <div className="col-header">AMOUNT</div>{item.amount} </div>
                                <div className="right-col v-r"> <div className="col-header">OLD CREDIT</div>{item.old_credit}</div>
                            </div>
                            <div className="data-row">
                                <div className="left-col v-r"> <div className="col-header">NEW CREDIT</div>{item.new_credit}</div>
                                <div className="right-col v-r"> <div className="col-header">PAYMENT STATUS</div>{item.status}</div>
                            </div>
                            <div className="data-row">
                                <div className="left-col v-r"> <div className="col-header">NOTE</div>{item.description}</div>
                                <div className="right-col v-r"> <div className="col-header">DATE</div>{moment(item.payment_date).format("MMM DD, YYYY hh:mm a")}</div>
                            </div>
                        </div>)}
                    </div>
                </div>
            </div>
        );
    }
}

const mapStateToProps = (state) => ({
    paymentHistory: state.Core.paymentHistory,
});

export default connect(mapStateToProps, {
    getPaymentHistoryAction
 })(withRouter(PaymentHistoryPage));