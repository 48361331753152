import alexsander from '../../Assets/Tutor/Alexsander.png';
import sandeep from '../../Assets/Tutor/Sandeep.png';

export const SENDER_ID = 0;

export const TutorList = [
    { id: 1, name: 'Roland Omene', role: 'Manager', photo: null },
    { id: 2, name: 'Gera Omene', role: 'Manager', photo: null  },
    { id: 3, name: 'Sandeep Tiwari', role: 'Manager', photo: sandeep },
    { id: 4, name: 'Alexander Himstead', role: 'Manager', photo: alexsander },
];

export const AvailableTutorList = [
    { id: 1, name: 'Roland Omene', role: 'Manager', photo: null },
    { id: 5, name: 'Marta Yurkiv', role: 'Tutor', photo: null },
    { id: 3, name: 'Sandeep Tiwari', role: 'Manager', photo: sandeep },
    { id: 6, name: 'Valery Ostapenko', role: 'Tutor', photo: null },
];

export const MessageList = [
    {
        partner: 1,
        data: [
            {
                sender: {
                    id: 1,
                    name: 'Roland Omene',
                    photo: null
                },
                created_time: '2020-02-19 12:33:00',
                is_new: true,
                content: [
                    'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.',
                ]
            },
            {
                sender: {
                    id: SENDER_ID,
                    name: 'Alex Tyshchenko',
                    photo: null
                },
                created_time: '2020-02-19 12:31:00',
                is_new: false,
                content: [
                    'Yes, OK',
                ]
            },
            {
                sender: {
                    id: 1,
                    name: 'Roland Omene',
                    photo: null
                },
                created_time: '2020-02-19 12:30:00',
                is_new: false,
                content: [
                    'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.',
                ]
            },
            {
                sender: {
                    id: SENDER_ID,
                    name: 'Alex Tyshchenko',
                    photo: null
                },
                created_time: '2020-02-18 23:31:00',
                is_new: false,
                content: [
                    'Yes, OK',
                ]
            },
            {
                sender: {
                    id: 1,
                    name: 'Roland Omene',
                    photo: null
                },
                created_time: '2020-02-18 23:30:00',
                is_new: false,
                content: [
                    'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.',
                    'Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.'
                ]
            },
        ]
    },
    {
        partner: 3,
        data: [
            {
                sender: {
                    id: 3,
                    name: 'Sandeep Tiwari',
                    photo: sandeep
                },
                created_time: '2020-02-19 12:33:00',
                is_new: true,
                content: [
                    'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.',
                ]
            },
        ]
    }
];