import React from 'react';
import './Accordion.scss';
import add from '../../Assets/Icon/add.svg';
import { ReactSVG } from 'react-svg'

export default class Accordion extends React.Component {

    state = {
        isOpen: false
    }

    toggleAccordion = () => {
        this.setState({ isOpen: !this.state.isOpen });
    }

    render() {
        const { className, title, content } = this.props;
        const { isOpen } = this.state;
        return (
            <div className={`accordion-component ${className}`}>
                <div className="title-area" onClick={ () => this.toggleAccordion() }>
                    <div className="accordion-title">{title}</div>
                    <ReactSVG src={add} className={`icon ${ isOpen ? 'icon-close' : 'icon-open' }`}/>
                </div>
                <div className={`accordion-content ${isOpen ? 'active' : ''}`}>{content}</div>
            </div>
        );
    }
}
