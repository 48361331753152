import React from 'react';
import './BiweeklyReportPage.scss';
import search from '../../Assets/Icon/search.svg';
import { InputBox, Avatar, RoundButton } from '../../Components';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import left from '../../Assets/Icon/left.svg';
import right from '../../Assets/Icon/right.svg';
import moment from 'moment';
import {
    getMystudentsAction,
    getBiweeklyAction
} from "../../Redux/Actions";

const Months = [
    'January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'
];

const Earns = [
    {
        date: 5,
        hours: 2,
        earn: 40,
    },
    {
        date: 8,
        hours: 3,
        earn: 60
    },
    {
        date: 9,
        hours: 1,
        earn: 20,
    }
];

class BiweeklyReportPage extends React.Component {
    state = {
        Clients: [],
        searchText: '',
        depth: 0,
        selectedClient: {},

        month: new Date().getMonth(),
        year: new Date().getFullYear(),
        biweeklyData: '',
        selectClientId: '',
        total_hours: '0.0',
        total_earn: '0.00'
    }

    static getDerivedStateFromProps(props, state) {
        return {
            depth: props.depth
        }
    }

    componentDidMount() {
        this.props.getMystudentsAction()
        if (this.props.match.params.id) {
            let index = this.props.myStudents.findIndex(temp => temp.id === parseInt(this.props.match.params.id, 10));
            this.selectClient(this.props.myStudents[index]);
        }
        // if (this.props.match.params.id) {
        //     let index = this.state.Clients.findIndex(temp => temp.id === parseInt(this.props.match.params.id, 10));
        //     this.selectClient(this.state.Clients[index]);
        // }
    }

    selectClient = async (item) => {
        this.setState({selectClientId: item.id})
        // let temp = client || {};
        this.setState({ 
            selectedClient: item,
            month: new Date().getMonth(),
            year: new Date().getFullYear()
        });
        var month = new Date().getMonth()
        var year = new Date().getFullYear()
        await this.props.formatTitle();
        this.props.updateTitle('push', item.name, 2);
        this.getBiweeklyData(item.id, month + 1, year)
    }

    changeDate = (flag) => {
        let { month, year, selectClientId } = this.state;
        if (flag === 'previous') {
            year = month === 0 ? year - 1 : year;
            month = month === 0 ? 11 : month - 1;
        } else {
            year = month === 11 ? year + 1 : year;
            month = month === 11 ? 0 : month + 1;
        }
        this.setState({ month, year });
        this.getBiweeklyData(selectClientId, month+1, year)
    }

    getBiweeklyData = (id, month, year) => {
        this.props.loader(true);        
        const deploy ={
            student_id: id,
            from_date: `${year}-${month}-1`,
            to_date: `${year}-${month}-30`
        }
        this.props.getBiweeklyAction(deploy).then(()=>{
            this.props.loader(false);
            const {biweeklyData} =this.props
            if(biweeklyData){
                var total_hours = 0;
                var total_earn = 0;
                biweeklyData.map((data, index)=>{
                    total_hours += Number(data.hours);
                    total_earn += Number(data.amount);
                })
                this.setState({
                    biweeklyData,
                    total_hours,
                    total_earn
                });
            }
        })
    }

    render() {
        const {myStudents} = this.props;
        const { searchText, depth, selectedClient, Clients, month, year, biweeklyData, total_hours, total_earn } = this.state;
        const list = myStudents.filter(client => client.name.toLowerCase().indexOf(searchText.toLowerCase()) > -1);
        return (
            <div className="biweeklyreport-page">
                <div className={`client-list v-r ${depth === 0 ? 'activate' : ''}`}>
                    <InputBox className="search-contact" value={searchText} icon={search} placeholder="Search..." onChange={val=>this.setState({searchText:val})} />
                    <div className="clients v-r scroll-area">
                        {list.map((item, index) => <div className={`tutor-container ${selectedClient.id === item.id ? 'selected' : ''}`} key={index} onClick={e => this.selectClient(item)}>
                            <div className="tutor v-c">
                                <Avatar className="avatar" info={item} />
                                <div className="info v-c">
                                    <h4>{item.name}</h4>
                                </div>
                            </div>
                        </div>)}
                    </div>
                </div>
                <div className={`content-area v-r ${depth >= 1 ? 'activate' : ''}`}>
                    {biweeklyData && <div className="week-content v-r">
                        <div className="date-area v-c h-c">
                            <RoundButton icon={left} className="arrow-btn" onClick={e => this.changeDate('previous')}/>&nbsp;&nbsp;
                            <p>{Months[month]}</p>&nbsp;
                            <p>{year}</p>&nbsp;&nbsp;
                            <RoundButton icon={right} className="arrow-btn" onClick={e => this.changeDate('next')}/>
                        </div>
                        <div className="data-area v-r">
                            <div className="data-header show-web-flex v-c">
                                <div className="cell cell-1">CLIENT</div>
                                <div className="cell cell-2">DATE</div>
                                <div className="cell cell-3">HOURS</div>
                                <div className="cell cell-4">AMOUNT EARNED</div>
                            </div>
                            <div className="data-list show-web-flex v-r">
                                {biweeklyData.map((item, index) => <div className="data-row price-row" key={index}>
                                    <div className="cell cell-1">{item.student.name}</div>
                                    <div className="cell cell-2">{moment(item.sched).format('MMM D, YYYY hh:mm a')}</div>
                                    <div className="cell cell-3">{item.hours}</div>
                                    <div className="cell cell-4">${item.amount}</div>
                                </div>)}
                                <div className="data-row" >
                                    <div className="cell cell-1">Total</div>
                                    <div className="cell cell-2"></div>
                                    <div className="cell cell-3">{total_hours}</div>
                                    <div className="cell cell-4">${total_earn}</div>
                                </div>
                            </div>
                            <div className="show-mobile-flex v-r">
                                {biweeklyData.map((item, index) => <div className="mobile-row price-row v-r" key={index}>
                                    <div className="data-row">
                                        <div className="left-col v-r"> <div className="col-header">CLIENT</div>{item.student.name} </div>
                                        <div className="right-col v-r"> <div className="col-header">DATE</div>{item.sched}</div>
                                    </div>
                                    <div className="data-row">
                                        <div className="left-col v-r"> <div className="col-header">HOURS</div>{item.hours}</div>
                                        <div className="right-col v-r"> <div className="col-header">AMOUNT EARNED</div>${item.earn}</div>
                                    </div>
                                </div>)}
                                <div className="mobile-row v-r">
                                    <div className="data-row">
                                        <div className="left-col v-r"> <div className="col-header">CLIENT</div>Total</div>
                                        <div className="right-col v-r"> <div className="col-header">DATE</div></div>
                                    </div>
                                    <div className="data-row">
                                        <div className="left-col v-r"> <div className="col-header">HOURS</div>{total_hours}</div>
                                        <div className="right-col v-r"> <div className="col-header">AMOUNT EARNED</div>${total_earn}</div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>}
                </div>
            </div>
        );
    }
}
const mapStateToProps = (state) => ({
    myStudents: state.Core.myStudents,
    biweeklyData: state.Core.biweeklyData,
});

export default connect(mapStateToProps, {
    getMystudentsAction,
    getBiweeklyAction
})(withRouter(BiweeklyReportPage));