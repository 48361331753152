import React from 'react';
import './SettingsPage.scss';
import { InputBox, SearchBox, SelectBox, PhoneNumberInput, CheckSection, QualificationSection } from '../../Components';
import { ReactSVG } from 'react-svg';
import moment from 'moment';
import bin from '../../Assets/Icon/rubbish-bin.svg';
import { toast } from "react-toastify";
import _ from 'lodash'
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import {
    getMypofileInfoAction,
    getSchoolAction,
    profileUpdateAction,
    getCitiesAction,
    getLevelSubjectAction
} from "../../Redux/Actions";
import { Menus, HelpParams, Hours, WeekData, degrees, PhoneCarriers } from './Content';

class SettingsPage extends React.Component {
    state = {
        menu: this.props.location.state.menu,
        
        id: '',
        photo: '',
        first_name: '',
        last_name: '',        
        email: '',
        city: '',
        zip_code: '',
        phone: '',
        phone_carrier: '',
        phone_carrier_name: '',
        deliver_tutor: 'deliver1',
        fan_facts: '',
        about_me: '',
        experience: '',
        approach: '',
        profile_title: '',

        college: [],
        high_school: [],
        middle_school: [],
        elementary_school: [],
        test_prep: [],
        music_lesson: [],
        computer: [],
        language_lessons: [],

        school1: '',
        major: '',
        major_gpa: '',
        school2: '',
        degree2_type: '',
        degree2: '',
        school3: '',
        degree3_type: '',
        degree3: '',
        
        schedule_data: [],

        last_password: '',
        new_password: '',
        flagMenu: '',

    };

    componentDidMount() {
        this.props.loader(true);
        this.getSchools();
    };
    
    getSchools = () => {
        if(_.isEmpty(this.props.schools)){
            this.props.getSchoolAction().then(()=>{
                this.getProfileData();     
            });
        } else {
            this.getProfileData();
        };
    };

    getProfileData = () => {
        if(_.isEmpty(this.props.myProfileInfo)){
            this.props.getMypofileInfoAction().then(()=> {
                this.updateProfileState();
            });
        } else {
            this.updateProfileState();
        }
    }

    updateProfileState= () => {
        if(this.props.myProfileInfo){
            this.props.getCitiesAction('', this.props.myProfileInfo.region_id).then(() => {

                const {myProfileInfo, cities, schools} = this.props;                           
          
                var schedule_data = []
                myProfileInfo.schedule.map((data, index)=>{
                    schedule_data[index] = {...data, selected: false, status: "unavailable"}
                });

                this.setState({
                    id: myProfileInfo.id,
                    email: myProfileInfo.email,
                    photo: myProfileInfo.picture? process.env.REACT_APP_IMAGE_DOMAIN + myProfileInfo.picture : '',
                    first_name: myProfileInfo.first_name,
                    last_name: myProfileInfo.last_name,
                    email: myProfileInfo.email,
                    zip_code: myProfileInfo.address_zipcode,
                    phone: myProfileInfo.phone,
                    phone_carrier: myProfileInfo.phone_carrier,
                    phone_carrier_name: _.find(PhoneCarriers, {id: Number(myProfileInfo.phone_carrier)}).name,
                    fan_facts: myProfileInfo.fun_facts,
                    about_me: myProfileInfo.about_me,
                    experience: myProfileInfo.experience,
                    approach: myProfileInfo.approach,
                    profile_title: myProfileInfo.profile_title,
                    city: _.find(cities, {id: myProfileInfo.region_id}).name,
                    school1: _.find(schools, {id: Number(myProfileInfo.school1_id)}).name,
                    major: myProfileInfo.major || "",
                    major_gpa: myProfileInfo.major_gpa || "",
                    school2: myProfileInfo.school2_id > 0 ? _.find(schools, {id: Number(myProfileInfo.school2_id)}).name : '',
                    degree2: myProfileInfo.degree2 || "",
                    degree2_type: myProfileInfo.degree2_type || "",
                    school3: myProfileInfo.school3_id > 0 ?_.find(schools, {id: Number(myProfileInfo.school3_id)}).name : '',
                    degree3: myProfileInfo.degree3 || "",
                    degree3_type: myProfileInfo.degree3_type || "",
                    schedule_data: schedule_data
                });

                this.props.loader(false);

                if(_.isEmpty(this.props.level_subjecs)){
                    this.props.getLevelSubjectAction().then(()=> {
                        this.getLevelSubjects();                        
                    })
                } else {
                    this.getLevelSubjects();
                }
            });
        };
    }

    getLevelSubjects = () => {
        this.props.level_subjecs.map((level, level_index)=> {
            const {myProfileInfo } = this.props;    
            var level_schools = []
            level.subjects.map((subject, subject_index)=> {
                if( _.find(myProfileInfo.classifications, {subject_id: subject.id, level_id: level.id})){
                    level_schools[subject_index] ={...subject, checked: true, content:  _.find(myProfileInfo.classifications, {subject_id: subject.id}).content}
                }else{
                    level_schools[subject_index] ={...subject, checked: false, content: ''}
                };
            })
            if(level.id === 2){
                this.setState({college: level_schools});
            };
            if(level.id === 3){
                this.setState({high_school: level_schools})
            };
            if(level.id === 4){
                this.setState({middle_school: level_schools})
            };
            if(level.id === 5){
                this.setState({elementary_school: level_schools})
            };
            if(level.id === 6){
                this.setState({test_prep: level_schools})
            };
            if(level.id === 7){
                this.setState({music_lesson: level_schools})
            };
            if(level.id === 8){
                this.setState({computer: level_schools})
            };
            if(level.id == 9){
                this.setState({language_lessons: level_schools})
            };
        })
    }

    componentDidUpdate() {
        if(this.state.flagMenu !== this.props.location.state.menu){
            this.setState({menu: this.props.location.state.menu, flagMenu: this.props.location.state.menu})
        };
        
    };

    pickFileForPhoto = (e) => {
        e.preventDefault();
        if (!e.target.files[0]) return;
        this.setState({photo: URL.createObjectURL(e.target.files[0])});
        this.setState({upload_picture: e.target.files[0]});
    };

    getHour = (hour) => {
        return `${hour <= 12 ? hour : hour === 24 ? 12 : hour - 12} ${hour < 12 ? 'am' : hour === 24 ? 'am' : 'pm'}`;
    };

    checkSchedule = (d, h) => {
        let { schedule_data } = this.state;
        let currentIndex = schedule_data.findIndex(temp => temp.day === d && temp.hour === h);
        let prevIndex = schedule_data.findIndex(temp => temp.day === d && temp.hour === h - 1);
        let nextIndex = schedule_data.findIndex(temp => temp.day === d && temp.hour === h + 1);

        let last_time = null;
        let current = currentIndex === -1 ? '' : schedule_data[currentIndex].status;
        let prev = prevIndex === -1 ? '' : schedule_data[prevIndex].status;
        let next = nextIndex === -1 ? '' : schedule_data[nextIndex].status;
        if (current !== prev && current === next && current.length > 0) {
            for (let i = h + 1; i < 24; i++) {
                let tempIndex = schedule_data.findIndex(temp => temp.day === d && temp.hour === i);
                let tempVal = tempIndex === -1 ? '' : schedule_data[tempIndex].status;
                if (current === tempVal) {
                    last_time = i;
                } else {
                    break;
                };
            };
        };
        return {
            current, prev, next, last_time,
            selected: currentIndex === -1 ? false : schedule_data[currentIndex].selected,
        };
    };

    updateSchedule = (d, h, current) => {
        if (current.length > 0) return;
        let { schedule_data } = this.state;
        schedule_data.push({
            day: d,
            hour: h,
            status: 'unavailable',
            selected: false
        })
        this.setState({schedule_data})
        this.updateProfile("availability")
    };

    selectSchedule = (e, d, h, current) => {
        e.stopPropagation();
        let { schedule_data } = this.state;
        let index = schedule_data.findIndex(temp => temp.day === d && temp.hour === h);
        schedule_data[index].selected = !schedule_data[index].selected;
        this.setState({schedule_data});
    };

    delSchedule = (d, h) => {
        let { schedule_data } = this.state;
        let index = schedule_data.findIndex(temp => temp.day === d && temp.hour === h);
        schedule_data.splice(index, 1);
        this.setState({schedule_data});
        this.updateProfile("availability")
    };

    checkSelectedCount = () => {
        let { schedule_data } = this.state;
        return (schedule_data.filter(temp => temp.selected === true) || []).length;
    };

    deleteSelected = () => {
        let { schedule_data } = this.state;
        let filtered = schedule_data.filter(temp => temp.selected === true) || [];
        if (filtered.length > 0) {
            for (let item of filtered) {
                let index = schedule_data.findIndex(temp => temp.day === item.day && temp.hour === item.hour);
                schedule_data.splice(index, 1);
            }
        }
        this.setState({schedule_data});
    }

    updateProfile = (updating_category) => {
        this.props.loader(true);
        const {cities, schools} = this.props;
        const { upload_picture, email, first_name, last_name, city, zip_code, phone, phone_carrier, fan_facts, about_me, experience, approach, profile_title } = this.state;

        const formData = new FormData();  
        if(updating_category === 'profile'){     
            if(upload_picture){
                formData.append('picture', upload_picture);
            }
            formData.append('first_name', first_name);
            formData.append('last_name', last_name);
            formData.append('email', email);
            formData.append('region_id', _.find(cities, {name: city}).id);
            formData.append('address_zipcode', zip_code);
            formData.append('phone', phone);
            formData.append('phone_carrier', phone_carrier);
            formData.append('fun_facts', fan_facts);
            formData.append('about_me', about_me);
            formData.append('experience', experience);
            formData.append('approach', approach);
            formData.append('profile_title', profile_title);
        }

        if(updating_category === 'subject'){
            var classifications = []
            this.state.college.map((data, index)=> {
                if(data.checked){
                    classifications = [...classifications, {level_id: 2, subject_id: data.id, content: data.content} ]
                };
            });

            this.state.high_school.map((data, index)=> {
                if(data.checked){
                    classifications = [...classifications, {level_id: 3, subject_id: data.id, content: data.content} ]
                };
            });

            this.state.middle_school.map((data, index)=> {
                if(data.checked){
                    classifications = [...classifications, {level_id: 4, subject_id: data.id, content: data.content} ]
                };
            });

            this.state.elementary_school.map((data, index)=> {
                if(data.checked){
                    classifications = [...classifications, {level_id: 5, subject_id: data.id, content: data.content} ]
                };
            });

            this.state.test_prep.map((data, index)=> {
                if(data.checked){
                    classifications = [...classifications, {level_id: 6, subject_id: data.id, content: data.content} ]
                };
            });

            this.state.music_lesson.map((data, index)=> {
                if(data.checked){
                    classifications = [...classifications, {level_id: 7, subject_id: data.id, content: data.content} ]
                };
            });

            this.state.computer.map((data, index)=> {
                if(data.checked){
                    classifications = [...classifications, {level_id: 8, subject_id: data.id, content: data.content} ]
                };
            });

            this.state.language_lessons.map((data, index)=> {
                if(data.checked){
                    classifications = [...classifications, {level_id: 9, subject_id: data.id, content: data.content} ]
                };
            });

            var deploy = {
                classifications: classifications
            };
        };

        if(updating_category === 'education'){
            formData.append('school1_id', _.find(schools, {name: this.state.school1}).id);
            formData.append('major', this.state.major);
            formData.append('major_gpa', this.state.major_gpa);
            formData.append('school2_id', _.find(schools, {name: this.state.school2}).id);
            formData.append('degree2', this.state.school2 ? this.state.degree2 : "");
            formData.append('degree2_type', this.state.school2? this.state.degree2_type : "");
            formData.append('school3_id', _.find(schools, {name: this.state.school3}).id);
            formData.append('degree3', this.state.school3 ? this.state.degree3 : "");
            formData.append('degree3_type', this.state.school3? this.state.degree3_type : "");
        };

        if(updating_category === 'qualifications'){
            var classifications = []
            this.state.college.map((data, index)=> {
                classifications = [...classifications, {level_id: 2, subject_id: data.id, content: data.content} ]
            })

            this.state.high_school.map((data, index)=> {
                classifications = [...classifications, {level_id: 3, subject_id: data.id, content: data.content} ]
            })
            formData.append('classifications', classifications);
            var deploy = {
                classifications: classifications
            }
        };

        if(updating_category === 'availability'){
            var deploy = {
                schedule: this.state.schedule_data
            }
        };

        if(updating_category === 'password'){
            formData.append('old_password', this.state.last_password);
            formData.append('password', this.state.new_password);
        };

        this.props.profileUpdateAction(deploy || formData).then(()=> {
            const { profileUpdateStatus, profileUpdateErr } = this.props;
            if(profileUpdateStatus){
                this.props.loader(false);
                this.props.headerProfileInfo(profileUpdateStatus);
                toast("Successfully updating!");
            } else {
                this.props.loader(false);
                toast(profileUpdateErr);
            };
        });
    };

    cityTxt = (cityName) => {
        if(cityName.length%2 === 0){
            this.props.getCitiesAction(cityName, '');
        };
    };

    render() {
        const { 
            menu, 
            photo, first_name, last_name, email, city, zip_code, phone, phone_carrier, phone_carrier_name, deliver_tutor, fan_facts, about_me, experience, approach, profile_title,
            college, high_school, middle_school, elementary_school, test_prep, music_lesson, computer, language_lessons, 
            school1, major, major_gpa, school2, degree2_type, degree2, school3, degree3_type, degree3,
            last_password, new_password 
        } = this.state;
        const {cities, schools, subjects, levels} = this.props

        return (
            <div className="settings-page v-r">
                <div className="settings-header">
                    <div className="container settings-container header-container v-c">
                        {Menus.map((item, index) => <p className={`head-menu ${menu === item ? 'active' : ''}`} key={index} onClick={e => this.setState({menu: item})}>
                            {item}
                        </p>)}
                    </div>
                </div>
                <div className="settings-body">
                    <div className={`container settings-container ${menu === 'AVAILABILITY' ? 'availability-container' : ''}`}>
                        {menu === 'PROFILE' && <div id="section-profile" className="v-r">
                            <div className="option-group v-r picture-option">
                                <h2 className="option-title">Profile Picture</h2>
                                <div className="photo-area v-c">
                                    <div className="initial-name h-c v-c">
                                        {photo ? <img src={photo} style={{width:'100%',height:'100%', borderRadius:'50%'}} alt="avatar" /> 
                                                : <span>{first_name[0] || 'A'}{last_name[0] || 'L'}</span>}
                                    </div>
                                    <input type="file" accept="image/*" id="image_picker" style={{display:'none'}} onChange={e => this.pickFileForPhoto(e)}></input>
                                    <label htmlFor="image_picker"><div className="btn-2 btn-2-outline upload_btn v-c h-c">Upload Photo</div></label>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-lg-6">
                                    <div className="option-group v-r">
                                        <h2 className="option-title">First Name</h2>
                                        <InputBox value={first_name} placeholder="Type First Name" onChange={val => this.setState({first_name: val})}/>
                                    </div>
                                </div>
                                <div className="col-lg-6">
                                    <div className="option-group v-r">
                                        <h2 className="option-title">Last Name</h2>
                                        <InputBox value={last_name} placeholder="Type Last Name" onChange={val => this.setState({last_name: val})}/>
                                    </div>
                                </div>
                            </div>
                            <div className="option-group v-r">
                                <h2 className="option-title">Email</h2>
                                <InputBox value={email} placeholder="Type Email" onChange={val => this.setState({email: val})}/>
                            </div>
                            <div className="row">
                                <div className="col-lg-6">
                                    <div className="option-group v-r">
                                        <h2 className="option-title">City</h2>
                                        {/* <InputBox value={city} placeholder="Type City" onChange={val => this.setState({city: val})}/> */}
                                        <SearchBox 
                                            value={city} 
                                            placeholder="City"
                                            options={cities} 
                                            onChange={val => this.setState({city: val.name})}
                                            cityTxt ={e => this.cityTxt(e)}
                                        />
                                    </div>
                                </div>
                                <div className="col-lg-6">
                                    <div className="option-group v-r">
                                        <h2 className="option-title">Zipcode</h2>
                                        <InputBox value={zip_code} placeholder="Type Zipcode" onChange={val => this.setState({zip_code: val})}/>
                                    </div>
                                </div>
                            </div>
                            <div className="option-group v-r">
                                <h2 className="option-title">Phone</h2>
                                <InputBox value={phone} placeholder="Type Phone Number" onChange={val => this.setState({phone: val})}/>
                                {/* <PhoneNumberInput
                                    phone={phone}
                                    placeholder="Type Phone Number"
                                    onChange={val => this.setState({phone: val})}
                                />{" "}  */}
                            </div>
                            {/* <div className="option-group v-r">
                                <h2 className="option-title">How would you deliver tutoring?</h2>
                                <SelectBox value={deliver_tutor} className="option-select" onChange={val => this.setState({deliver_tutor: val})} options={['deliver1', 'deliver2']}/>
                            </div> */}
                            <div className="option-group v-r">
                                <h2 className="option-title">Phone Carrier</h2>
                                <SearchBox 
                                    value={phone_carrier_name} 
                                    placeholder="phone carrier"
                                    options={PhoneCarriers} 
                                    onChange={val => this.setState({phone_carrier_name: val.name, phone_carrier: val.id})}
                                />
                            </div>
                            <div className="option-group v-r">
                                <h2 className="option-title">Fan Facts</h2>
                                <textarea className="option-note" value={fan_facts} onChange={e => this.setState({fan_facts: e.target.value})} placeholder="Type fun facts..."/>
                            </div>
                            <div className="option-group v-r">
                                <h2 className="option-title">About me</h2>
                                <textarea className="option-note" value={about_me} onChange={e => this.setState({about_me: e.target.value})} placeholder="Type about you..."/>
                            </div>
                            <div className="option-group v-r">
                                <h2 className="option-title">Experience</h2>
                                <textarea className="option-note" value={experience} onChange={e => this.setState({experience: e.target.value})} placeholder="Type your experience..."/>
                            </div>
                            <div className="option-group v-r">
                                <h2 className="option-title">Approach</h2>
                                <textarea className="option-note" value={approach} onChange={e => this.setState({approach: e.target.value})} placeholder="Type your approach..."/>
                            </div>
                            <div className="option-group v-r">
                                <h2 className="option-title">Profile Title</h2>
                                <InputBox value={profile_title} placeholder="Type profile title" onChange={val => this.setState({profile_title: val})}/>
                            </div>
                            {first_name && last_name && email && city && zip_code && phone && phone_carrier_name && fan_facts && about_me && experience && approach && profile_title? 
                                <button className="btn-2 primary-btn submit-btn" onClick={(e) => this.updateProfile("profile")}>Save Changes</button>
                                :
                                <button className="btn-2 submit-btn">Save Changes</button>
                            }
                        </div>}
                        {menu === 'SUBJECTS' && <div id="section-profile" className="v-r">
                            <p className="subject-p">Please select what subjects you feel comfortable tutoring in. Please be as specific as possible. For example, if you feel comfortable tutoring high school algebra but you only select algebra under the college level, we will not show your profile to potential clients who are looking for high school level algebra tutors even though you’re capable of tutoring that subject.</p>
                            <CheckSection className="check-section" title="Elementary School" data={elementary_school} onChange={data => this.setState({elementary_school: data})}/>
                            <CheckSection className="check-section" title="Middle School" data={middle_school} onChange={data => this.setState({middle_school: data})}/>
                            <CheckSection className="check-section" title="High School" data={high_school} onChange={data => this.setState({high_school: data})}/>
                            <CheckSection className="check-section" title="College" data={college} onChange={data => this.setState({college: data})}/>
                            <CheckSection className="check-section" title="Test Prep" data={test_prep} onChange={data => this.setState({test_prep: data})}/>
                            <CheckSection className="check-section" title="Computer" data={computer} onChange={data => this.setState({computer: data})}/>
                            <CheckSection className="check-section" title="Language Lessons" data={language_lessons} onChange={data => this.setState({language_lessons: data})}/>
                            <CheckSection className="check-section" title="Music Lessons" data={music_lesson} onChange={data => this.setState({music_lesson: data})}/>
                            <button className="btn-2 primary-btn" onClick={(e) => this.updateProfile("subject")}>Save Changes</button>
                        </div>}
                        {menu === 'EDUCATION' && <div id="section-profile" className="v-r">
                            <p className="education-p">This allows you to showcase your background and degrees. You are required to upload a transcript and test prep scores if you are interested in tutoring test prep such as ACT, SAT, GRE, LSAT etc. The minimum GPA in your area of subject specialization is 3.0.</p>
                            <div className="education-group v-r">
                                <div className="option-group v-r">
                                    <h2 className="option-title">Undergraduate</h2>
                                    <SearchBox value={school1} placeholder="Select Graduate University" options={schools} onChange={val => this.setState({school1: val.name})}/>
                                </div>
                                <div className="row">
                                    <div className="col-lg-6">
                                        <div className="option-group v-r">
                                            <h2 className="option-title">Major</h2>
                                            {/* <SearchBox value={major} placeholder="Type Major" options={majors} onChange={val => this.setState({major: val.name})}/> */}
                                            <InputBox value={major} placeholder="Major" onChange={val => this.setState({major: val})}/>
                                        </div>
                                    </div>
                                    <div className="col-lg-6">
                                        <div className="option-group v-r">
                                            <h2 className="option-title">Major GPA</h2>
                                            <InputBox value={major_gpa} placeholder="Type Major GPA" onChange={val => this.setState({major_gpa: val})}/>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="education-group v-r">
                                <div className="option-group v-r">
                                    <h2 className="option-title">Graduate School(optional)</h2>
                                    <SearchBox value={school2} placeholder="Select Graduate School" options={schools} onChange={val => this.setState({school2: val.name})}/>
                                </div>
                                <div className="row">
                                    <div className="col-lg-6">
                                        <div className="option-group v-r">
                                            <h2 className="option-title">Degree</h2>
                                            <InputBox value={degree2} placeholder="Degree" onChange={val => this.setState({degree2: val})}/>
                                            {/* <SearchBox value={degree2_type} placeholder="Type Degree" options={degrees} onChange={val => this.setState({degree2_type: val.name})}/> */}
                                        </div>
                                    </div>
                                    <div className="col-lg-6">
                                        <div className="option-group v-r">
                                            <h2 className="option-title">Select a type</h2>
                                            <SearchBox value={degree2_type} placeholder="Select a type" options={degrees} onChange={val => this.setState({degree2_type: val.name})}/>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="education-group v-r">
                                <div className="option-group v-r">
                                    <h2 className="option-title">Graduate School(optional)</h2>
                                    <SearchBox value={school3} placeholder="Select Graduate School" options={schools} onChange={val => this.setState({school3: val.name})}/>
                                </div>
                                <div className="row">
                                    <div className="col-lg-6">
                                        <div className="option-group v-r">
                                            <h2 className="option-title">Degree</h2>
                                            {/* <SearchBox value={degree3} placeholder="Type Degree" options={degrees} onChange={val => this.setState({degree3: val.name})}/> */}
                                            <InputBox value={degree3} placeholder="Degree" onChange={val => this.setState({degree3: val})}/>
                                        </div>
                                    </div>
                                    <div className="col-lg-6">
                                        <div className="option-group v-r">
                                            <h2 className="option-title">Select a type</h2>
                                            <SearchBox value={degree3_type} placeholder="Select a type" options={degrees} onChange={val => this.setState({degree3_type: val.name})}/>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            {school1 && major && major_gpa?
                                <button className="btn-2 primary-btn"  onClick={(e) => this.updateProfile("education")}>Save Changes</button>
                                :
                                <button className="btn-2">Save Changes</button>
                            }
                        </div>}
                        {menu === 'QUALIFICATIONS' && <div id="section-profile" className="v-r">
                            <p className="subject-p">This allows you to enter a brief detailed description of why you are qualified to tutor each of the subjects you have selected. We recommend a minimum of three sentences per subject qualification description. If you need more help with this section of the application, please watch the help video located on the left side of the page.</p>
                            {_.find(college, {checked: true}) &&
                                <QualificationSection className="check-section" title="College" data={college} onChange={e => this.setState({college: e})}/>
                            }
                            {_.find(high_school, {checked: true}) &&
                                <QualificationSection className="check-section" title="High School" data={high_school} onChange={e => this.setState({high_school: e})}/>
                            }
                            {_.find(middle_school, {checked: true}) &&
                                <QualificationSection className="check-section" title="Middle School" data={middle_school} onChange={e => this.setState({middle_school: e})}/>
                            }
                            {_.find(elementary_school, {checked: true}) &&
                                <QualificationSection className="check-section" title="Elementary School" data={elementary_school} onChange={e => this.setState({elementary_school: e})}/>
                            }
                            {_.find(test_prep, {checked: true}) &&
                                <QualificationSection className="check-section" title="Test Prep" data={test_prep} onChange={e => this.setState({test_prep: e})}/>
                            }
                            {_.find(computer, {checked: true}) &&
                                <QualificationSection className="check-section" title="Computer" data={computer} onChange={e => this.setState({computer: e})}/>
                            }
                            {_.find(language_lessons, {checked: true}) &&
                                <QualificationSection className="check-section" title="Language Lessons" data={language_lessons} onChange={e => this.setState({language_lessons: e})}/>
                            }
                            {_.find(music_lesson, {checked: true}) &&
                                <QualificationSection className="check-section" title="Music Lessons" data={music_lesson} onChange={e => this.setState({music_lesson: e})}/>
                            }
                            <button className="btn-2 primary-btn" onClick={(e) => this.updateProfile("subject")}>Save Changes</button>
                        </div>}
                        {menu === 'AVAILABILITY' && <div id="section-profile" className="availavbility-section">
                            <div className="pre-setting">
                                <div className="help-area">
                                    {HelpParams.map((item, index) => <div className="help-item v-c" key={index}>
                                        <div className={`help-color ${item}`} />
                                        <p>{item}</p>
                                    </div>)}
                                    {this.checkSelectedCount() > 0 && <div className="v-c delete-all" onClick={e => this.deleteSelected()}>
                                        <ReactSVG src={bin} className="svg-icon" />
                                        <p>Delete Selected</p>
                                    </div>}
                                </div>
                                
                                <p className="pre-explain">This tab allows you to set your weekly availability schedule. White means you’re available and blue means you are not available” ...If you are available to tutor all days with the exception of Monday and Wednesday</p>
                            </div>
                            <div className="setting-area">
                                <table className="setting-table">
                                    <thead>
                                        <tr>
                                            <th className="start-col"></th>
                                            {WeekData.map((item, index) => <th key={index} className={`header-col ${item === moment(new Date()).format("ddd") ? 'active' : ''}`}>
                                                <p>{item}</p>
                                            </th>)}
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {Hours.map((hour, hrIndex) => <tr key={hrIndex}>
                                            <td className="start-col">{this.getHour(hour)}</td>
                                            {WeekData.map((item, index) => {
                                                let data = this.checkSchedule(item, hour);
                                                return (
                                                <td key={index} className={`${data.current}`} onClick={e => this.updateSchedule(item, hour, data.current)}>
                                                    {data.current.length > 0 && <div onClick={e => this.selectSchedule(e, item, hour, data.current)}
                                                        className={`inner-schedule ${data.current} ${data.current === data.prev ? 'has-prev' : ''} ${data.current === data.next ? 'has-next' : ''} ${data.selected ? 'selected' : ''} `} >
                                                        {data.current !== data.prev && <p>
                                                            <span>{this.getHour(hour)} - </span>
                                                            <span>{this.getHour(data.last_time ? data.last_time + 1 : hour + 1)}</span>
                                                        </p>}
                                                        {data.selected && <div className="del-btn" onMouseDown={e => this.delSchedule(item, hour)}><ReactSVG className="svg-icon" src={bin}/></div>}
                                                    </div>}
                                                </td>)
                                            })}
                                        </tr>)}
                                    </tbody>
                                </table>
                            </div>
                        </div>}
                        {menu === 'PASSWORD' && <div id="section-password" className="v-r">
                            <div className="option-group v-r">
                                <h2 className="option-title">Last Password</h2>
                                <InputBox type="password" value={last_password} placeholder="Type Last Password" onChange={val => this.setState({last_password: val})}/>
                            </div>
                            <div className="option-group v-r">
                                <h2 className="option-title">New Password</h2>
                                <InputBox type="password" value={new_password} placeholder="Type New Password" onChange={val => this.setState({new_password: val})}/>
                            </div>
                            {last_password && new_password?
                                <button className="btn-2 primary-btn"  onClick={(e) => this.updateProfile("password")}>Save Changes</button>
                                :
                                <button className="btn-2">Save Changes</button>
                            }
                        </div>}
                    </div>
                </div>
            </div>
        );
    }
}

const mapStateToProps = (state) => ({
    myProfileInfo: state.Core.myProfileInfo,
    schools: state.Core.schools,
    profileUpdateStatus: state.Core.profileUpdateStatus,
    profileUpdateErr: state.Core.profileUpdateErr,
    cities: state.Core.cities,
    level_subjecs: state.Core.level_subjecs,
});

export default connect(mapStateToProps, {
    getMypofileInfoAction,
    getSchoolAction,
    profileUpdateAction,
    getCitiesAction,
    getLevelSubjectAction,
})(withRouter(SettingsPage));