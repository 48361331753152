import React from 'react';
import './HelpPage.scss';
import { Accordion } from '../../Components';

const questions = [
    { title: 'What if I miss a tutoring session?', content: 'If you miss a tutoring session, it can negatively impact your effort score. Please try to make sure that you plan in advance so this does not happen.'},
    { title: 'How do I book a session?', content: 'To book a session, click on Schedule a Lesson on the upper top right and follow the steps to book a lesson'},
    { title: 'What do I do when I get a new client assigned to me?', content: 'Be sure you contact your new client as soon as possible (ideally within 3 hours) to introduce yourself, understand their needs, and work out a tutoring schedule.'},
    { title: 'What should I do when I get a new session booked?', content: 'When you get booked for a new session, make sure you add it to your personal calendar so you are prepared. Ensure that you know what the plan for the session is, and please remember to be on time'},
    { title: 'Can I book a lesson for my client?', content: 'Yes! If the client has tutoring credit in their account, you can book sessions on behalf of your clients.'},
    { title: 'How do I get paid?', content: 'Click on “ My Money” to connect your bank account. After each completed session, you will see your current balance is updated. You’re able to cash out whenever you want, but remember that it can take up to 3 days for the money to hit your account.'},
    { title: 'How do I increase my effort score?', content: 'Please click on training and watch the video on effort score to learn more about how to increase your effort score'},
    { title: 'Can I cancel my lesson?', content: 'Yes! But please be aware that undue cancellations might negatively impact your effort score and your standing with GradeGetter. In the event that you need to cancel, reach out to your client to reschedule as soon as possible.'},
];

export default class HelpPage extends React.Component {
    render() {
        return (
            <div className="help-page v-r">
                <div className="container help-container v-r">
                    {questions.map((item, index) => <Accordion className="accordion" title={item.title} content={item.content} key={index} />)}
                </div>
            </div>
        );
    }
}