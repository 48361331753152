import React from 'react';
import './OnlineDemoPage.scss';
import bg from '../../Assets/Blog/demobg.png';

export default class OnlineDemoPage extends React.Component {

    componentDidMount(){
        var stored = localStorage.getItem('demoRoom')

        if (!stored || stored.indexOf('room.sh') === -1) {
            stored = null
        }

        fetch("https://api.thelessonspace.com/v2/demo/", {
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({desired_room: stored})
        }).then(function (response) {
            response.json().then(function (j) {
                localStorage.setItem('demoRoom', j.url)
                document.getElementById("lessonspaceDemoIframe").src = j.url
            })
        });
    }
    
    render() {
        return (
            <div className="onlinedemo-page v-r">
                {/* <div className="bg-container show-web-flex">
                    <img src={bg} alt="demo" />
                </div>
                <div className="show-mobile-flex v-r v-c h-c iframe-container">IFRAME</div> */}
                <iframe id="lessonspaceDemoIframe" frameborder="0" allow="camera; microphone; display-capture" src="about:blank" style={{width:'100%', height:'100%'}}>
                </iframe>
            </div>            
        );
    }
}