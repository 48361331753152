import React from 'react';
import './PortToFrogtutoringPage.scss';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { InputBox } from '../../Components';
import { portToFrogAction } from '../../Redux/Actions';
import { toast } from 'react-toastify';
import _ from 'lodash'

class PortToFrogtutoringPage extends React.Component {
    state = {
        new_password: ''
    }

    porting = () => {
        const { new_password } = this.state
        if (new_password) {
            this.props.loader(true);
            const formData = new FormData();
            formData.append('password', new_password);
            this.props.portToFrogAction(formData).then(() => {
                this.props.loader(false);
                const { portToFrogStatus } = this.props
                if (!_.isEmpty(portToFrogStatus.errors)) {
                    toast(portToFrogStatus.errors[0])
                } else {
                    window.open(portToFrogStatus.redirect_url);
                };
            });
        };
    };

    render() {
        const { new_password } = this.state;
        return (
            <div className="porting-page v-r">
                <div className="porting-body">
                    <div className="container porting-container">
                        <div className="v-r">
                            <div className="option-group v-r">
                                <div className="option-title mb-5">Please enter a new password for your Frogtutoring account. We will copy your account over to Frogtutoring</div>
                                <h2 className="option-title">New Password</h2>
                                <InputBox type="password" value={new_password} placeholder="Type New Password" onChange={val => this.setState({ new_password: val })} />
                                <p>At least 8 characters</p>
                            </div>
                            <button
                                className={`btn-2 ${new_password ? 'primary-btn' : ''}`}
                                onClick={(e) => this.porting()}>
                                Porting to Frogtutoring
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

const mapStateToProps = (state) => ({
    portToFrogStatus: state.Auth.portToFrogStatus,
});

export default connect(mapStateToProps, {
    portToFrogAction,
})(withRouter(PortToFrogtutoringPage));