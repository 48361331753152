import { MOBILE_SIDEBAR_TOGGLE, SIDEBAR_TOGGLE } from '../Type';

const INITIAL = {
  isMobileOpen: false,
  isOpen: true,
}

export default (state = INITIAL, action) => {
  switch (action.type) {
    case MOBILE_SIDEBAR_TOGGLE: {
      const { isMobileOpen } = action.data;
      return { ...state, isMobileOpen };
    }

    case SIDEBAR_TOGGLE: {
      const { isOpen } = action.data;
      return { ...state, isOpen };
    }

    default:
      return state;
  }
}