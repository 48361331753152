import {
    GET_PROFILE_UPDATE,
    GET_PROFILE_UPDATE_SUCCESS,
    GET_PROFILE_UPDATE_FAIL,
    GET_MYSTUDENTS,
    GET_MYSTUDENTS_SUCCESS,
    GET_MYSTUDENTS_FAIL,
    GET_MYBLOGS,
    GET_MYBLOGS_SUCCESS,
    GET_MYBLOGS_FAIL,
    GET_MY_PROFILE_INFO,
    GET_MY_PROFILE_INFO_SUCCESS,
    GET_MY_PROFILE_INFO_FAIL,
    GET_PENDING_LESSON,
    GET_PENDING_LESSON_SUCCESS,
    GET_PENDING_LESSON_FAIL,
    GET_COMPLETED_LESSON,
    GET_COMPLETED_LESSON_SUCCESS,
    GET_COMPLETED_LESSON_FAIL,
    GET_CITIES,
    GET_CITIES_FAIL,
    GET_CITIES_SUCCESS,
    GET_SCHOOLS,
    GET_SCHOOLS_FAIL,
    GET_SCHOOLS_SUCCESS,
    GET_SUBJECTS,
    GET_SUBJECTS_FAIL,
    GET_SUBJECTS_SUCCESS,
    GET_LEVELS,
    GET_LEVELS_SUCCESS,
    GET_LEVELS_FAIL,
    GET_LEVEL_SUBJECTS,
    GET_LEVEL_SUBJECTS_SUCCESS,
    GET_LEVEL_SUBJECTS_FAIL,
    GET_TOPICLIST,
    GET_TOPICLIST_SUCCESS,
    GET_TOPICLIST_FAIL,
    GET_ALERTS,
    GET_ALERTS_SUCCESS,
    GET_ALERTS_FAIL,
    GET_BLOCK_SCHEDULE_LIST,
    GET_BLOCK_SCHEDULE_LIST_SUCCESS,
    GET_BLOCK_SCHEDULE_LIST_FAIL,
    GET_PAYMENT_HISTORY,
    GET_PAYMENT_HISTORY_SUCCESS,
    GET_PAYMENT_HISTORY_FAIL,
    GET_BIWEEKLY,
    GET_BIWEEKLY_SUCCESS,
    GET_BIWEEKLY_FAIL,
    GET_BANK_DETAIL,
    GET_BANK_DETAIL_SUCCESS,
    GET_BANK_DETAIL_FAIL,
    GET_MESSAGE,
    GET_MESSAGE_FAIL,
    GET_MESSAGE_SUCCESS,
    GET_MESSAGE_USER_LIST,
    GET_MESSAGE_USER_LIST_FAIL,
    GET_MESSAGE_USER_LIST_SUCCESS,
} from "../Type";
  
const INITIAL = {
    myStudents: [],
    myStudentsErr: '',
    myBlogs: [],
    myBlogsErr: '',
    myProfileInfo: null,
    
    profileUpdateStatus: null,

    pendingLesson: [],
    completedLesson: [],
    cities: [],
    schools: [],
    subjects: null,
    levels: [],
    level_subjecs: [],
    topicList: [],
    alert_list: [],
    blockScheduleList: [],
    paymentHistory: [],
    biweeklyData: [],
    bankDetail: '',
    messageData: [],
    messageUserList: []
};
  
export default (state = INITIAL, action) => {
    switch (action.type) {
        // MY STUDENTS
        case GET_MYSTUDENTS:
            return { ...state, myStudents: [] };
        case GET_MYSTUDENTS_SUCCESS:
            return { ...state, myStudents: action.payload};
        case GET_MYSTUDENTS_FAIL:
            return { ...state, myStudentsErr: action.payload};

        // MY BLOGS
        case GET_MYBLOGS:
            return { ...state, myBlogs: [] };
        case GET_MYBLOGS_SUCCESS:
            return { ...state, myBlogs: action.payload};
        case GET_MYBLOGS_FAIL:
            return { ...state, myBlogsErr: action.payload};

        // MY_PROFILE_INFO
        case GET_MY_PROFILE_INFO:
            return { ...state, myProfileInfo: null };
        case GET_MY_PROFILE_INFO_SUCCESS:
            return { ...state, myProfileInfo: action.payload };
        case GET_MY_PROFILE_INFO_FAIL:
            return { ...state, myProfileInfo: null };

        // PROFILE UPDATE
        case GET_PROFILE_UPDATE:
            return { ...state, profileUpdateStatus: null };
        case GET_PROFILE_UPDATE_SUCCESS:
            return { ...state, profileUpdateStatus: action.payload, myProfileInfo: action.payload };
        case GET_PROFILE_UPDATE_FAIL:
            return { ...state,profileUpdateStatus: null, profileUpdateErr: action.payload.error };

        // PENDING_LESSON
        case GET_PENDING_LESSON:
            return { ...state, pendingLesson: [] };
        case GET_PENDING_LESSON_SUCCESS:
            return { ...state, pendingLesson: action.payload };
        case GET_PENDING_LESSON_FAIL:
            return { ...state, pendingLesson: [] };
       
        // COMPLETED_LESSON
        case GET_COMPLETED_LESSON:
            return { ...state, completedLesson: [] };
        case GET_COMPLETED_LESSON_SUCCESS:
            return { ...state, completedLesson: action.payload };
        case GET_COMPLETED_LESSON_FAIL:
            return { ...state, completedLesson: [] };

        // CITIES
        case GET_CITIES:
            return { ...state, cities: [] };
        case GET_CITIES_SUCCESS:
            return { ...state, cities: action.payload };
        case GET_CITIES_FAIL:
            return { ...state, cities: [] };

        // SCHOOLS
        case GET_SCHOOLS:
            return { ...state, schools: [] };
        case GET_SCHOOLS_SUCCESS:
            return { ...state, schools: action.payload };
        case GET_SCHOOLS_FAIL:
            return { ...state, schools: [] };

        // SUBJECTS
        case GET_SUBJECTS:
            return { ...state, subjects: null };
        case GET_SUBJECTS_SUCCESS:
            return { ...state, subjects: action.payload };
        case GET_SUBJECTS_FAIL:
            return { ...state, subjects: null };
        
        // LEVELS
        case GET_LEVELS:
            return { ...state, levels: [] };
        case GET_LEVELS_SUCCESS:
            return { ...state, levels: action.payload };
        case GET_LEVELS_FAIL:
            return { ...state, levels: [] };

        // LEVEL AND SUBJECTS
        case GET_LEVEL_SUBJECTS:
            return { ...state, level_subjecs: null };
        case GET_LEVEL_SUBJECTS_SUCCESS:
            return { ...state, level_subjecs: action.payload };
        case GET_LEVEL_SUBJECTS_FAIL:
            return { ...state, level_subjecs: null };

        // TOPICLIST
        case GET_TOPICLIST:
            return { ...state, topicList: [] };
        case GET_TOPICLIST_SUCCESS:
            return { ...state, topicList: action.payload };
        case GET_TOPICLIST_FAIL:
            return { ...state, topicList: [] };

        // ALERTS
        case GET_ALERTS:
            return { ...state, alert_list: [] };
        case GET_ALERTS_SUCCESS:
            return { ...state, alert_list: action.payload };
        case GET_ALERTS_FAIL:
            return { ...state, alert_list: [] };

        // BLOCK SCHEDULE LIST
        case GET_BLOCK_SCHEDULE_LIST:
            return { ...state, blockScheduleList: [] };
        case GET_BLOCK_SCHEDULE_LIST_SUCCESS:
            return { ...state, blockScheduleList: action.payload };
        case GET_BLOCK_SCHEDULE_LIST_FAIL:
            return { ...state, blockScheduleList: [] };

        // PAYMETN HISTORY
        case GET_PAYMENT_HISTORY:
            return { ...state, paymentHistory: [] };
        case GET_PAYMENT_HISTORY_SUCCESS:
            return { ...state, paymentHistory: action.payload };
        case GET_PAYMENT_HISTORY_FAIL:
            return { ...state, paymentHistory: [] };

        // BIWEEKLY
        case GET_BIWEEKLY:
            return { ...state, biweeklyData: [] };
        case GET_BIWEEKLY_SUCCESS:
            return { ...state, biweeklyData: action.payload };
        case GET_BIWEEKLY_FAIL:
            return { ...state, biweeklyData: [] };

        // BANK DETAIL
        case GET_BANK_DETAIL:
            return { ...state, bankDetail: null };
        case GET_BANK_DETAIL_SUCCESS:
            return { ...state, bankDetail: action.payload };
        case GET_BANK_DETAIL_FAIL:
            return { ...state, bankDetail: null };

        // GET_MESSAGE
        case GET_MESSAGE:
            return { ...state, messageData: [] };
        case GET_MESSAGE_SUCCESS:
            return { ...state, messageData: action.payload };
        case GET_MESSAGE_FAIL:
            return { ...state, messageData: [] };

        // GET MESSAGE USER LIST
        case GET_MESSAGE_USER_LIST:
            return { ...state, messageUserList: [] };
        case GET_MESSAGE_USER_LIST_SUCCESS:
            return { ...state, messageUserList: action.payload };
        case GET_MESSAGE_USER_LIST_FAIL:
            return { ...state, messageUserList: [] };
  
        default:
            return state;
    }
};
  