import { 
    GET_ALERT_LIST, GET_ALERT_LIST_FAIL, GET_ALERT_LIST_SUCCESS, ALERT_SHOW_INTEREST, ALERT_SHOW_INTEREST_SUCCESS, ALERT_SHOW_INTEREST_FAIL, 
} from '../Type';

const INITIAL = {
    alert_list: [],
    alert_list_success: false,

    show_interest_loading: false,
	show_interest_success: false,
}

export default (state = INITIAL, action) => {
    switch (action.type) {
        case GET_ALERT_LIST: 
        case GET_ALERT_LIST_FAIL: {
            return {
                ...state,
                alert_list_success: false,
            }
        }
        case GET_ALERT_LIST_SUCCESS: {
            return {
                ...state,
                alert_list: action.data || [],
                alert_list_success: true,
            }
        }
        case ALERT_SHOW_INTEREST: {
            return {
                ...state,
                show_interest_loading: true,
                show_interest_success: false,
            }
        }
        case ALERT_SHOW_INTEREST_SUCCESS: {
            return {
                ...state,
                show_interest_loading: false,
                show_interest_success: true,
            }
        }
        case ALERT_SHOW_INTEREST_FAIL: {
            return {
                ...state,
                show_interest_loading: false,
                show_interest_success: false,
            }
		}
        default:
            return state;
    }
}