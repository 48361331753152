import {
    GET_PROFILE_UPDATE,
    GET_PROFILE_UPDATE_SUCCESS,
    GET_PROFILE_UPDATE_FAIL,
    GET_SCHEDULE_LESSON,
    GET_SCHEDULE_LESSON_SUCCESS,
    GET_SCHEDULE_LESSON_FAIL,
    GET_CANCEL_LESSON,
    GET_CANCEL_LESSON_SUCCESS,
    GET_CANCEL_LESSON_FAIL,
    GET_DELETE_TUTOR,
    GET_DELETE_TUTOR_SUCCESS,
    GET_DELETE_TUTOR_FAIL,
    GET_POST_BLOG,
    GET_POST_BLOG_SUCCESS,
    GET_POST_BLOG_FAIL,
    GET_BLOG_UPDATE,
    GET_BLOG_UPDATE_SUCCESS,
    GET_BLOG_UPDATE_FAIL,
    GET_TUTOR_NOTE,
    GET_TUTOR_NOTE_SUCCESS,
    GET_TUTOR_NOTE_FAIL,
    GET_BLOCK_SCHEDULE_UPDATE,
    GET_BLOCK_SCHEDULE_UPDATE_SUCCESS,
    GET_BLOCK_SCHEDULE_UPDATE_FAIL,
    GET_AUTH_SIGN_OUT,
    GET_AUTH_SIGN_OUT_SUCCESS,
    GET_AUTH_SIGN_OUT_FAIL,
    GET_ALERT_INTEREST,
    GET_ALERT_INTEREST_SUCCESS,
    GET_ALERT_INTEREST_FAIL,
    GET_UPDATE_BANK,
    GET_UPDATE_BANK_SUCCESS,
    GET_UPDATE_BANK_FAIL,
    GET_WITHDRAW_MONEY,
    GET_WITHDRAW_MONEY_SUCCESS,
    GET_WITHDRAW_MONEY_FAIL,
    GET_CREATE_MESSAGE,
    GET_CREATE_MESSAGE_FAIL,
    GET_CREATE_MESSAGE_SUCCESS,
    GET_PORT_TO_FROG,
    GET_PORT_TO_FROG_FAIL,
    GET_PORT_TO_FROG_SUCCESS,
} from "../Type";

const INITIAL = {
    profileUpdateStatus: null,
    profileUpdateErr: null,
    scheduleLessonStatus: null,
    scheduleLessonErr: '',
    cancelLessonStatus: null,
    cancelLessonErr: '',
    deleteTutorStatus: null,
    postBlogStatus: null,
    postBlogErr: '',
    blogUpdateStatus: null,
    blogUpdateErr: '',
    tutorNoteStatus: null,
    tutorNoteErr: '',
    blockScheduleUpdateStatus: null,
    blockScheduleUpdateErr: '',
    signOutStatus: null,
    interestUpdateStatus: null,
    interestUpdateErr: '',
    updateBankStatus: null,
    updateBankErr: '',
    withdrawMoneyStatus: null,
    withdrawMoneyErr: '',
    sendMessageStatus: null,
    portToFrogStatus: null
};

export default (state = INITIAL, action) => {
    switch (action.type) {

        // // PROFILE UPDATE
        // case GET_PROFILE_UPDATE:
        //     return { ...state, profileUpdateStatus: null };
        // case GET_PROFILE_UPDATE_SUCCESS:
        //     return { ...state, profileUpdateStatus: action.payload, myProfileInfo: action.payload };
        // case GET_PROFILE_UPDATE_FAIL:
        //     return { ...state,profileUpdateStatus: null, profileUpdateErr: action.payload.error };

        // SCHEDULE LESSON
        case GET_SCHEDULE_LESSON:
            return { ...state, scheduleLessonStatus: null };
        case GET_SCHEDULE_LESSON_SUCCESS:
            return { ...state, scheduleLessonStatus: action.payload.message };
        case GET_SCHEDULE_LESSON_FAIL:
            return { ...state, scheduleLessonStatus: null, scheduleLessonErr: action.payload.message };

        // CANCEL LESSON
        case GET_CANCEL_LESSON:
            return { ...state, cancelLessonStatus: null };
        case GET_CANCEL_LESSON_SUCCESS:
            return { ...state, cancelLessonStatus: action.payload.message };
        case GET_CANCEL_LESSON_FAIL:
            return { ...state, cancelLessonStatus: null, cancelLessonErr: action.payload };

        // DELETE TUTOR
        case GET_DELETE_TUTOR:
            return { ...state, deleteTutorStatus: null };
        case GET_DELETE_TUTOR_SUCCESS:
            return { ...state, deleteTutorStatus: action.payload.message };
        case GET_DELETE_TUTOR_FAIL:
            return { ...state, deleteTutorStatus: null, deleteTutorErr: action.payload };

        //  POST BLOG
        case GET_POST_BLOG:
            return { ...state, postBlogStatus: null };
        case GET_POST_BLOG_SUCCESS:
            return { ...state, postBlogStatus: action.payload};
        case GET_POST_BLOG_FAIL:
            return { ...state, postBlogStatus: null, postBlogErr: action.payload };

        //  BLOG UPDATE
        case GET_BLOG_UPDATE:
            return { ...state, blogUpdateStatus: null };
        case GET_BLOG_UPDATE_SUCCESS:
            return { ...state, blogUpdateStatus: action.payload};
        case GET_BLOG_UPDATE_FAIL:
            return { ...state, blogUpdateStatus: null, blogUpdateErr: action.payload };

        // TUTOR NOTE
        case GET_TUTOR_NOTE:
            return { ...state, tutorNoteStatus: null };
        case GET_TUTOR_NOTE_SUCCESS:
            return { ...state, tutorNoteStatus: action.payload.message};
        case GET_TUTOR_NOTE_FAIL:
            return { ...state, tutorNoteStatus: null, tutorNoteErr: action.payload };
            
        // BLOCK SCHEDULE UPDATE
        case GET_BLOCK_SCHEDULE_UPDATE:
            return { ...state, blockScheduleUpdateStatus: null };
        case GET_BLOCK_SCHEDULE_UPDATE_SUCCESS:
            return { ...state, blockScheduleUpdateStatus: action.payload.message };
        case GET_BLOCK_SCHEDULE_UPDATE_FAIL:
            return { ...state,blockScheduleUpdateStatus: null, blockScheduleUpdateErr: action.payload.message };

        // ALERT INTEREST
        case GET_ALERT_INTEREST:
            return { ...state, interestUpdateStatus: null };
        case GET_ALERT_INTEREST_SUCCESS:
            return { ...state, interestUpdateStatus: action.payload };
        case GET_ALERT_INTEREST_FAIL:
            return { ...state,interestUpdateStatus: null, interestUpdateErr: action.payload};

        // UPDATE BANK ACCOUNT
        case GET_UPDATE_BANK:
            return { ...state, updateBankStatus: null };
        case GET_UPDATE_BANK_SUCCESS:
            return { ...state, updateBankStatus: action.payload.message };
        case GET_UPDATE_BANK_FAIL:
            return { ...state,updateBankStatus: null, updateBankErr: action.payload.message};

        // WITHDRAW MONEY
        case GET_WITHDRAW_MONEY:
            return { ...state, withdrawMoneyStatus: null };
        case GET_WITHDRAW_MONEY_SUCCESS:
            return { ...state, withdrawMoneyStatus: action.payload.message };
        case GET_WITHDRAW_MONEY_FAIL:
            return { ...state,withdrawMoneyStatus: null, withdrawMoneyErr: action.payload.message};

        // SIGNOUT
        case GET_AUTH_SIGN_OUT:
            sessionStorage.clear();
            localStorage.removeItem("token");
            localStorage.removeItem("user");
            return { ...state, signOutStatus: null };
        case GET_AUTH_SIGN_OUT_SUCCESS:
            return { ...state, signOutStatus: action.payload };
        case GET_AUTH_SIGN_OUT_FAIL:
            return { ...state, signOutStatus: null };

        //CREATE MESSAGE
        case GET_CREATE_MESSAGE:
            return { ...state, sendMessageStatus: null };
        case GET_CREATE_MESSAGE_SUCCESS:
            return { ...state, sendMessageStatus: action.payload };
        case GET_CREATE_MESSAGE_FAIL:
            return { ...state, sendMessageStatus: null };

        //GET PORT TO FROG
        case GET_PORT_TO_FROG:
            return { ...state, portToFrogStatus: null };
        case GET_PORT_TO_FROG_SUCCESS:
            return { ...state, portToFrogStatus: action.payload };
        case GET_PORT_TO_FROG_FAIL:
            return { ...state, portToFrogStatus: null };

        default:
            return state;
    }
};