import React from 'react';
import './RoundButton.scss';
import { ReactSVG } from 'react-svg'

export default class RoundButton extends React.Component {
    state = {
        buttonState: ''
    }

    render() {
        const { className, icon, onClick } = this.props;
        const { buttonState } = this.state;
        return (
            <div className={`roundbutton-component ${className || ''} ${buttonState}`} onClick={onClick} onMouseDown={e => this.setState({buttonState: 'down'})}  onMouseUp={e => this.setState({buttonState: 'up'})}>
                <ReactSVG src={icon} className="svg-icon" />
            </div> 
        );
    }
}
