import React from 'react';
import './CreditCardPage.scss';
import master from '../../Assets/CreditCard/master.png';
import visa from '../../Assets/CreditCard/visa.png';

export default class CreditCardPage extends React.Component { 
    state = {
        saveText: 'Save Changes'
    }

    saveChange = () => {
        this.setState({saveText: 'Saved'});
        setTimeout(() => {
            this.setState({saveText: 'Save Changes'});
        }, 1000);
    }

    render() {
        const { saveText } = this.state;
        return (
            <div className="creditcard-page v-c h-c v-r">
                <p>Change Your Credit Card</p>
                <div className="box-container v-r">
                    <div className="card-box v-r">
                        <p>Card Number</p>
                        <div className="row">
                            <div className="col-3 sub-col"> <input className="sub-input" maxLength="4"/> </div>
                            <div className="col-3 sub-col"> <input className="sub-input" maxLength="4"/> </div>
                            <div className="col-3 sub-col"> <input className="sub-input" maxLength="4"/> </div>
                            <div className="col-3 sub-col"> <input className="sub-input" maxLength="4"/> </div>
                        </div>
                        <p className="second-line">Date validity</p>
                        <div className="row">
                            <div className="col-3 sub-col"> <input className="sub-input" maxLength="2"/> <div className="slash">/</div> </div>
                            <div className="col-3 sub-col"> <input className="sub-input" maxLength="2"/> </div>
                            <div className="col-3 sub-col v-c h-c"> <img src={master} alt="master" /> </div>
                            <div className="col-3 sub-col v-c h-c"> <img src={visa} alt="visa" /> </div>
                        </div>
                    </div>
                    <div className="card-box v-r">
                        <div className="break-line" />
                        <div className="row second-line">
                            <div className="col-3 sub-col" /><div className="col-3 sub-col" /><div className="col-3 sub-col" />
                            <div className="col-3 sub-col h-c v-r"> <p>CVV2</p> <input className="sub-input" maxLength="4"/> </div>
                        </div>
                    </div>
                </div>
                <button className="btn-2 btn-2-outline save-btn" onClick={e => this.saveChange()}>{saveText}</button>
            </div>
        );
    }
}