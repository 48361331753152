import React from 'react';
import './QualificationSection.scss';
import { ReactSVG } from 'react-svg';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import down from '../../Assets/Icon/arrow-down.svg'
import add from '../../Assets/Icon/add.svg';
import tick from '../../Assets/Icon/tick.svg';
import { toggleQualificationModal } from '../../Redux/Actions';

class QualificationSection extends React.Component {
    state = {
        show_all: false,
        data: this.props.data || []
    }

    componentDidUpdate(prevProps) {
        if (prevProps.isOpen && !this.props.isOpen) {
            let { data } = this.state;
            let index = data.findIndex(temp => temp.id === this.props.qualification_info.id);
            if (index > -1) {
                data[index] = this.props.qualification_info;
                this.setState({ data });
                this.props.onChange(data);
            }
        }
    }

    editContent = (item) => {
        this.props.toggleQualificationModal(true, item);
    }

    render() {
        const { className, title } = this.props;
        const { show_all, data } = this.state;
        return (
            <div className={`qualificationsection-component v-r ${className || ''} `}>
                <div className="qualificationsection-name v-c" onClick={e => this.setState({show_all: !show_all})}>
                    <div className="qualificationsection-title">{title}</div>
                    <ReactSVG src={down} className={`svg-icon ${show_all ? 'reversed' : ''}`}/>
                </div>
                {show_all && <table className="qualificationsection-table">
                    <thead>
                        <tr>
                            <th>Subject</th>
                            <th>Qualification</th>
                            <th>Status</th>
                        </tr>
                    </thead>
                    <tbody>
                        {data.map((item, index) => 
                            item.checked && <tr key={index}>
                                <td>{item.name}</td>
                                <td><p onClick={e => this.editContent(item)}>{item.content ? 'Edit' : 'Enter Content'}</p></td>
                                <td><ReactSVG src={item.content ? tick : add} className={`svg-icon ${item.content ? 'completed' : 'incompleted'}`}/></td>
                            </tr>
                        )}
                    </tbody>
                </table>}
            </div>
        )
    };
};

function mapStateToProps(state) {
    return {
        isOpen: state.Modal.isQualificationModalOpen,
        qualification_info: state.Modal.qualification_info,
    }
}
  
export default connect(mapStateToProps, { toggleQualificationModal })(withRouter(QualificationSection));
