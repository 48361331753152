import React from 'react';
import './ViralPage.scss';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import facebook from '../../Assets/Icon/facebook-logo.png';
import twitter from '../../Assets/Icon/twitter-logo.png';
import gplus from '../../Assets/Icon/google-plus.png';
import youtube from '../../Assets/Icon/youtube.png';
import instagram from '../../Assets/Icon/instagram.png';
import glassdoor from '../../Assets/Icon/glassdoor.png';
import yelp from '../../Assets/Icon/yelp.png';

const Virals = [
    {
        id: 1,
        title: 'Share',
        description: 'Share-Lorem ipsum dolor',
        content_title: 'Share Your Profile'
    },
    {
        id: 2,
        title: 'Follow',
        description: 'Follow-Lorem ipsum dolor',
        content_title: 'Follow us on social media'
    },
    {
        id: 3,
        title: 'Reviews',
        description: 'Reviews-Lorem ipsum dolor',
        content_title: 'Leave us a review '
    },
    {
        id: 4,
        title: 'Blogger',
        description: 'Blogger-Lorem ipsum dolor',
        content_title: 'Increase your effort score & pay '
    },
    {
        id: 5,
        title: 'Flyers & Banners',
        description: 'Flyers & Banners-Lorem ipsum dolor',
        content_title: 'Share flyers and banners and make $$$$'
    },
]

class ViralPage extends React.Component {
    state = {
        depth: 0,
        selected_viral: {}
    }

    static getDerivedStateFromProps(props, state) {
        return {
            depth: props.depth
        }
    }

    selectViral = async (viral) => {
        this.setState({ 
            selected_viral: viral,
        });
        await this.props.formatTitle();
        this.props.updateTitle('push', 'Viral', 2);
    }

    navigateTo = (page) => {
        this.props.history.push(page);
    }

    render() {
        const { depth, selected_viral } = this.state;
        return (
            <div className="virals-page">
                <div className={`viral-list v-r scroll-area ${depth === 0 ? 'activate' : ''}`}>
                    {Virals.map((item, index) => <div className={`viral-container v-c ${selected_viral.title === item.title ? 'selected' : ''}`} key={index} onClick={e => this.selectViral(item)}>
                        {item.title}
                    </div>)}
                </div>
                <div className={`content-area v-r ${depth >= 1 ? 'activate' : ''}`}>
                    {(selected_viral.title && selected_viral.title.length > 0) && <div className="viral-content v-r">
                        <div className="viral-header v-c">
                            <h4>{selected_viral.title}</h4>
                            <div className="viral-description v-r h-c v-c">
                                <p>?</p>
                                <div className="description-text">{selected_viral.description}</div>
                            </div>
                        </div>
                        <div className="viral-details v-r h-c v-c">
                            <h2>{selected_viral.content_title}</h2>
                            <div className={`btn-area v-c h-c area-${selected_viral.id}`}>
                                {selected_viral.title === 'Share' && <React.Fragment>
                                    <div className="sub-btn" onClick={e => this.navigateTo('/profile')}>Your Profile</div>
                                </React.Fragment>}
                                {selected_viral.title === 'Follow' && <React.Fragment>
                                    <div className="sub-btn"><img src={facebook} alt="btn-icon" />Facebook</div>
                                    <div className="sub-btn"><img src={twitter} alt="btn-icon" />Twitter</div>
                                    <div className="sub-btn"><img src={gplus} alt="btn-icon" />Google plus</div>
                                    <div className="sub-btn"><img src={youtube} alt="btn-icon" />YouTube</div>
                                    <div className="sub-btn"><img src={instagram} alt="btn-icon" />Instagram</div>
                                </React.Fragment>}
                                {selected_viral.title === 'Reviews' && <React.Fragment>
                                    <div className="sub-btn"><img src={glassdoor} alt="btn-icon" />Glassdoor</div>
                                    <div className="sub-btn"><img src={yelp} alt="btn-icon" />Yelp</div>
                                    <div className="sub-btn"><img src={facebook} alt="btn-icon" />Facebook</div>
                                    <div className="sub-btn"><img src={gplus} alt="btn-icon" />Google plus</div>
                                </React.Fragment>}
                                {selected_viral.title === 'Blogger' && <React.Fragment>
                                    <div className="sub-btn" onClick={e => this.navigateTo('/blog')}>Write a Blog</div>
                                </React.Fragment>}
                                {selected_viral.title === 'Flyers & Banners' && <React.Fragment>
                                    <div className="sub-area">
                                        <div className="sub-btn">Your profile</div>
                                        <div className="sub-btn">Job referral</div>
                                    </div>
                                    <div className="sub-btn big-btn">Social media banners</div>
                                    <div className="sub-btn big-btn">Social profile picture frame</div>
                                    <div className="sub-btn big-btn">Profile promo on facebook</div>
                                    <div className="sub-btn big-btn">Job referral promo on facebook</div>
                                </React.Fragment>}
                            </div>
                            {selected_viral.title === 'Flyers & Banners' && <p>If you change profile, click here to regenerate profile flyer</p>}
                        </div>
                    </div>}
                </div>
            </div>
        );
    }
}

export default connect()(withRouter(ViralPage));