import { get, post } from "./Api";

export const getMystudents = () => {
    return get("/connections/mystudents");
};

export const getMyblogs = () => {
    return get("/blogs");
};

export const profileUpdate = (data) => {
    return post("/me", data);
};

export const getMypofileInfo = () => {
    return get("/me");
};

export const getPendingLesson = () => {
    return get("/transactions?status=pending");
};

export const getCompletedLesson = () => {
    return get("/transactions?status=finished");
};

export const getCities = (city = '', id = '') => {
    return get(`/cities?name=${city}&id=${id}`);
};

export const getSchools = () => {
    return get("/schools");
};

export const getSubjects = (level_id = 1) => {
    return get(`/subjects?level_id=${level_id}`);
};

export const getLevelSubjects = () => {
    return get("/subjects/group");
};

export const getLevels = () => {
    return get("/levels");
};

export const getTopicList = () => {
    return get("/blogs/topics");
};

export const getAlerts = () => {
    return get("/alerts");
};

export const getBlockScheduleList = () => {
    return get("/transactions");
};

export const getPaymentHistory = () => {
    return get("/payments");
};

export const getBiweekly = (data) => {
    return get(`/transactions/report?student_id=${data.student_id}&from_date=${data.from_date}&to_date=${data.to_date}`);
};

export const getBankDetail = () => {
    return get("/payments/bankaccount");
};

export const getMessage = (id) => {
    return get(`/messages?user_id=${id}`);
};

export const getMessageUserList = (id) => {
    return get("/messages");
};

