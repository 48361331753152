import React from 'react';
import './PendingLessonPage.scss';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { InputBox, RoundButton, SelectBox, ConfirmModal, SubmitNoteModal} from '../../Components';
import search from '../../Assets/Icon/search.svg';
import left from '../../Assets/Icon/left.svg';
import right from '../../Assets/Icon/right.svg';
import play_button from '../../Assets/Icon/play-button.svg';
import { ReactSVG } from 'react-svg';
import moment from 'moment';
import { toggleVideoModal, toggleJoinLesson } from '../../Redux/Actions';
import _ from 'lodash'

import {
    getPendingLessonAction,
    cancelLessonAction,
    tutorNoteAction
} from "../../Redux/Actions";
import { toast } from 'react-toastify';

function innerFunc(item) {
    return {__html: item}
}

class PendingLessonPage extends React.Component {
    timer = null
    
    state = {
        id: '',
        data: [],
        filteredList: [],
        
        searchText: '',
        currentPage: 1,
        totalPage: 1,
        displayCount: 10,

        depth: 0,
        selectedLesson: '',

        selected_id: 0,
        incomingLessonInfo: {},

        seconds: 10,
        tutor_note: '',

        isOpenModal: false,
        isSubmitNoteModal: false,
        cancelItem: '',

    }

    static getDerivedStateFromProps(props, state) {
        const id = (props.match.params.id && props.match.params.id.length > 0) ? props.match.params.id : '';
        return {
            id: id,
            depth: props.depth,
            incomingLessonInfo: props.incomingLessonInfo
        };
    }

    componentDidMount() {
        this.props.getPendingLessonAction().then(()=>{
            this.filterData('', 10)
            if(this.props.match.params.id){
                const {pendingLesson} = this.props
                var selectedLesson = _.find(pendingLesson, {id: Number(this.props.match.params.id)})
                this.setState({selectedLesson})
            }
        })
    }

    componentWillUnmount() {
        clearInterval(this.timer);
        this.timer = null;
    }

    componentDidUpdate(prevProps, prevState) {
        const prevID = (prevProps.match.params.id && prevProps.match.params.id.length > 0) ? prevProps.match.params.id : '';
        const newID = (this.props.match.params.id && this.props.match.params.id.length > 0) ? this.props.match.params.id : '';
        if (prevID === newID && prevID !== '' && newID !== '') {
            if (this.state.depth !== 1 && prevState.depth === 0) {
                this.props.updateTitle('push', 'Pending Lessons', 2);
            }
        }
    }

    changeDisplayCount = (val) => {
        const { searchText } = this.state;
        this.filterData(searchText, val.name);
    }

    changeSearchText = (val) => {
        const { displayCount } = this.state;
        this.filterData(val, displayCount);
    }

    changeCurrentPage = (direction) => {
        const { currentPage, totalPage } = this.state;
        if ((direction === 'previous' && currentPage === 1) || (direction === 'next' && currentPage === totalPage)) return;
        this.setState({
            currentPage: direction === 'next' ? currentPage + 1 : currentPage - 1
        })
    }

    filterData = (searchText, displayCount) => {
        const { data } = this.state;
        const {pendingLesson} = this.props;
        const filteredList = pendingLesson.filter(item => item.student.name.indexOf(searchText) > -1);
        const totalPage = Math.ceil(filteredList.length / displayCount);
        
        this.setState({
            searchText: searchText,
            filteredList: filteredList,
            totalPage: totalPage,
            displayCount: displayCount,
            currentPage: 1
        })
    }

    viewItem = (e, item) => {
        this.setState({selectLesson: item})
        e.stopPropagation();
        this.setState({
            tutor_note: ''
        })
        this.props.history.push(`/pending-lesson/${item.id}`);
    }

    openVideoModal = (item) => {
        this.props.toggleVideoModal(true, item.actions);
    }

    cancelLesson = (item) => {
        this.setState({cancelItem: item})
        this.setState({isOpenModal: true})
    }

    selectLesson = (e, item) => {
        
    }

    navigateToClient = (e, client_id) => {
        e.stopPropagation();
        this.props.history.push(`/clients/${client_id}`);
    }

    formatTime = (sec) => {
        let second = sec;
        const hour = Math.floor(second / 3600);
        second = second - hour * 3600;
        const minute = Math.floor(second / 60);
        second = second - minute * 60;
        return `${hour}:${minute < 10 ? '0'+minute : minute}:${second < 10 ? '0'+second : second}`;
    }

    joinLesson = (item) => {
        window.open(item.tutor_online_link);
    }

    confirmSubmitNote = () => {
        if(this.state.tutor_note.length < 300){
            toast("Error: 300 character count or more")
        } else {
            this.setState({isSubmitNoteModal: true});
        }
    }

    submitNote = (status) => {
        const { selectedLesson, tutor_note } = this.state;
        this.setState({isSubmitNoteModal: false});
        if(status){
            this.props.loader(true);
            const formData = new FormData()        
            formData.append('tutor_note', tutor_note);
            formData.append('_method', "PUT");
            this.props.tutorNoteAction(formData, selectedLesson.id).then(()=>{
                const { tutorNoteStatus } = this.props;
                if(tutorNoteStatus){
                    // this.setState({...selectedLesson, tutor_note: tutor_note})
                    selectedLesson.tutor_note = tutor_note
                    this.setState({selectedLesson, tutor_note: ''})
                    this.props.loader(false);
                    toast(tutorNoteStatus);
                    this.props.history.push(`/pending-lesson`);
                } else {
                    this.props.loader(false);
                    toast("Sorry, Faild !")
                }
            })
        } else {
            this.cancelLesson(selectedLesson)
        }

    }

    deleteConfirm =(deleteStatus, reason) => {
        this.setState({isOpenModal: false});
        if(deleteStatus){
            const formData = new FormData();
            formData.append('action', 'cancel');
            formData.append('reason', reason);
            formData.append('_method', 'PUT');
            this.props.cancelLessonAction(formData, this.state.cancelItem.id).then(()=>{
                if(this.props.cancelLessonStatus){
                    toast("Cancel Successfully !");
                    // this.props.getPendingLessonAction();
                    this.props.history.push(`/pending-lesson`);
                }
            })
        }
    }

    closeModal = () => {
        this.setState({isOpenModal: false, isSubmitNoteModal: false});
    }

    seeRecording = (item) => {
        if(item.recorded_online_link){
            window.open(item.recorded_online_link);
        }
    }

    render() {        
        const { searchText, displayCount, filteredList, currentPage, totalPage, depth, selectedLesson, selected_id, seconds, tutor_note } = this.state;
        const startIndex = displayCount * (currentPage - 1);
        const displayList = filteredList.slice(startIndex, startIndex + displayCount);
        return (
            <div className="pendinglesson-page v-r">
                {depth === 0 && <div className="options-area">
                    <InputBox className="search-input" value={searchText} icon={search} placeholder="Search..." onChange={val=>this.changeSearchText(val)} />
                    <div className="count-area v-c">
                        <RoundButton icon={left} className="show-mobile-flex" onClick={e => this.changeCurrentPage('previous')}/>
                        <p className="page-count">Page {currentPage} of {totalPage  }</p>
                        <RoundButton icon={left} className="arrow-btn show-web-flex" onClick={e => this.changeCurrentPage('previous')}/>
                        <RoundButton icon={right} className="arrow-btn" onClick={e => this.changeCurrentPage('next')}/>
                        <div className="combo-area v-c">
                            <p>Items</p>
                            <SelectBox value={displayCount} onChange={val => this.changeDisplayCount(val)}/>
                        </div>
                    </div>
                </div>}
                {depth === 0 && <div className="data-area v-r">
                    <div className="data-header show-web-flex v-c">
                        <div className="cell cell-1">CLIENT</div>
                        <div className="cell cell-2">SCHEDULE</div>
                        <div className="cell cell-3">HOURS</div>
                        {/* <div className="cell cell-4">TYPE</div> */}
                        <div className="cell cell-5">CANCELLED</div>
                        <div className="cell cell-6">ACTIONS</div>
                    </div>
                    <div className="data-list show-web-flex v-r">
                        {displayList.map((item, index) => <div className={`data-row ${Math.floor((new Date(item.sched) - new Date()) / 1000) > 0 && Math.floor((new Date(item.sched) - new Date()) / 1000) < 86400 ? 'selected' : ''}`} key={index} onClick={e => this.selectLesson(e, item)} onBlur={e=>this.setState({selected_id:0})} tabIndex={index}>
                            <div className="cell cell-1"><p style={{cursor:'pointer'}} onClick={e => this.navigateToClient(e, item.student.id)}>{item.student.name}</p></div>
                            <div className="cell cell-2"><span style={{cursor:'pointer'}} onClick={e => this.viewItem(e, item)}>{moment(item.sched).format('MMM D, YYYY hh:mm a')}</span></div>
                            <div className="cell cell-3">{item.hours}</div>
                            {/* <div className="cell cell-4">{item.type}</div> */}
                            <div className="cell cell-5">{item.cancel_time ? 'YES' : 'NO'}</div>
                            <div className="cell ">
                                <div className={`v-c ${item.cancel_time ? '' : 'active'}`}>
                                    {/* {!item.cancel_time &&<p>{Math.floor((new Date() - new Date(item.sched)) / 1000) > 0 ? 'Error: No link to online session' : `Online tutoring starts in ${moment(item.sched).format('MMM D, YYYY hh:mm a')}`}</p>} */}
                                    {!item.cancel_time ? <React.Fragment>
                                        {Math.floor((new Date() - new Date(item.sched)) / 1000) > 0 ?
                                            <div className="v-c" onClick={e => this.seeRecording(item)}>
                                                <ReactSVG src={play_button} className="svg-icon"/> {item.recorded_online_link? "See Recording" : "No link to online session"}
                                            </div>
                                            :
                                            <p>{`Online tutoring starts in ${moment(item.sched).local().format('MMM D, YYYY hh:mm a')}`}</p>
                                        }
                                    </React.Fragment> : 'No link to online session'}
                                </div>
                            </div>
                            <div className="cell cell-6 v-c">
                                {(!item.cancel_time && Math.floor((new Date() - new Date(item.sched)) / 1000) - (3600 * Number(item.hours)) > 0) && <button className="btn-2 btn-2-outline cancel-btn outline-btn note-btn" onClick={e => this.viewItem(e,item)}>Leave note</button>}
                                {( Math.floor((new Date() - new Date(item.sched)) / 1000) > -1800 && Math.floor((new Date() - new Date(item.sched)) / 1000) < (3600 * Number(item.hours)) ) && <button className="btn-2 btn-2-outline cancel-btn outline-btn note-btn" onClick={e => this.joinLesson(item)}>Join</button>}
                                <button className="btn-2 btn-2-outline cancel-btn outline-btn" onClick={e => this.cancelLesson(item)}>Cancel</button>
                            </div>
                        </div>)}
                    </div>
                    <div className="show-mobile-flex v-r">
                        {displayList.map((item, index) => <div className="mobile-row v-r" key={index}>
                            <div className="data-row">
                                <div className="left-col v-r"> <div className="col-header">CLIENT</div> <p className="active" onClick={e => this.navigateToClient(e, item.student.id)}>{item.student.name}</p> </div>
                                <div className="right-col v-r"> <div className="col-header">SCHEDULE</div> <p className="active" onClick={e => this.viewItem(e, item)}>{moment(item.sched).format('MMM D, YYYY hh:mm a')}</p> </div>
                            </div>
                            <div className="data-row">
                                <div className="left-col v-r"> <div className="col-header">HOURS</div> <p>{item.hours}</p> </div>
                                {/* <div className="right-col v-r"> <div className="col-header">TYPE</div> <p>{item.type}</p> </div> */}
                            </div>
                            <div className="data-row">
                                <div className="left-col v-r"> <div className="col-header">CANCELLED</div> <p>{item.cancel_time ? item.cancel_time : 'NO'}</p> </div>
                                <div className="right-col v-r"> 
                                    {/* {!item.cancel_time &&<p>{Math.floor((new Date() - new Date(item.sched)) / 1000) > 0 ? 'Error: No link to online session' : `Online tutoring starts in ${moment(item.sched).format('MMM D, YYYY hh:mm a')}`}</p>} */}
                                    <div className={`v-c ${item.cancel_time ? '' : 'active'}`}>
                                        {!item.cancel_time ? 
                                            <React.Fragment>
                                                {Math.floor((new Date() - new Date(item.sched)) / 1000) > 0 ?
                                                    <div className="v-c" onClick={e => this.seeRecording(item)}>
                                                        <ReactSVG src={play_button} className="svg-icon"/> {item.recorded_online_link? "See Recording" : "No link to online session"}
                                                    </div>
                                                    :
                                                    <p>{`Online tutoring starts in ${moment(item.sched).local().format('MMM D, YYYY hh:mm a')}`}</p>
                                                }
                                            </React.Fragment>
                                            : 
                                            'No link to online session'
                                        }
                                    </div>
                                 </div>
                            </div>

                            {/* <div className="cell cell-6 v-c"> */}
                                {(!item.cancel_time && Math.floor((new Date() - new Date(item.sched)) / 1000) - (3600 * Number(item.hours)) > 0) && <button className="btn-2 btn-2-outline cancel-btn outline-btn" onClick={e => this.viewItem(e,item)}>Leave note</button>}
                                {( Math.floor((new Date() - new Date(item.sched)) / 1000) > -1800 && Math.floor((new Date() - new Date(item.sched)) / 1000) < (3600 * Number(item.hours)) ) && <button className="btn-2 btn-2-outline cancel-btn outline-btn note-btn" onClick={e => this.joinLesson(item)}>Join</button>}
                                <button className="btn-2 btn-2-outline cancel-btn outline-btn" onClick={e => this.cancelLesson(item)}>Cancel</button>
                            {/* </div> */}
                        </div>)}
                    </div>
                </div>}
                {depth === 1 && <div className="pending-detail v-r">
                    <h1 className="show-web">Tutoring Session</h1>
                    {/* {Math.floor((new Date(selectedLesson.sched) - new Date()) / 1000) > 0 && Math.floor((new Date(selectedLesson.sched) - new Date()) / 1000) < 1800 && <div className="notification show-mobile-flex v-c">
                        <p>Online tutoring starts in {this.formatTime(seconds)}</p>
                        <button className="join-btn" onClick={e => this.joinLesson()}>Join</button>
                    </div>} */}
                    <div className="detail-list v-r">
                        <div className="detail-row v-c">
                            <div className="detail-title">Client</div>
                            <div className="detail-content name"><p style={{cursor:'pointer'}} onClick={e => this.navigateToClient(e, selectedLesson.student.id)}>{selectedLesson? selectedLesson.student.name : ''}</p></div>
                        </div>
                        <div className="detail-row v-c">
                            <div className="detail-title">Start Time</div>
                            <div className="detail-content">{moment(selectedLesson.sched).format('MMM D, YYYY hh:mm a')}</div>
                        </div>
                        <div className="detail-row v-c">
                            <div className="detail-title">Length</div>
                            <div className="detail-content">{selectedLesson.hours}</div>
                        </div>
                        {/* <div className="detail-row v-c">
                            <div className="detail-title">At home</div>
                            <div className="detail-content">Yes</div>
                        </div>
                        <div className="detail-row v-c">
                            <div className="detail-title">Money paid</div>
                            <div className="detail-content">$50.00</div>
                        </div> */}
                        <div className="detail-row v-c">
                            <div className="detail-title">Booked on</div>
                            <div className="detail-content">{moment(selectedLesson.sched).format('MMM D, YYYY hh:mm a')}</div>
                        </div>
                        <div className="detail-row v-c">
                            <div className="detail-title">Pay</div>
                            <div className="detail-content">${selectedLesson.amount}</div>
                        </div>
                        <div className="detail-row v-c">
                            <div className="detail-title">Cancellation</div>
                            <div className="detail-content"><button className="btn-2 btn-2-outline cancel-btn outline-btn" onClick={e => this.cancelLesson(selectedLesson)}>Cancel</button></div>
                        </div>
                    </div>
                    <h1> Description </h1>
                    <div className="group">
                        <h2>Client</h2>
                        <div>{selectedLesson.client_note}</div>
                    </div>
                    <div className="group">
                        <h2>Leave your note</h2>
                        {selectedLesson.tutor_note && <div dangerouslySetInnerHTML={innerFunc(selectedLesson.tutor_note)} className="tutor-note"/>}
                    </div>
                    <div className="group">
                        <h2>Your note (at least 300 characters)</h2>
                        <textarea className="textarea-note" value={tutor_note} onChange={e => this.setState({tutor_note: e.target.value})} placeholder="Note guide: &#13;&#10;1.What did you accomplished during this lesson? &#13;&#10;2.What were the student strengths and weaknesses? &#13;&#10;3.What do you have planned for your next session?"/>
                        {tutor_note? 
                            <button className="btn-2 primary-btn submit-btn" onClick={e => this.confirmSubmitNote()}>Submit</button>
                            :
                            <button className="btn-2 submit-btn">Submit</button>
                        }
                    </div>
                </div>}
                <ConfirmModal
                    isOpenModal={this.state.isOpenModal}
                    deleteConfirm={(e, r) => this.deleteConfirm(e, r)}
                    closeModal={()=>this.closeModal()}
                    cancelTitle = "Delete lesson?"
                />
                <SubmitNoteModal
                    isSubmitNoteModal={this.state.isSubmitNoteModal}
                    submitNote={(e) => this.submitNote(e)}
                    closeModal={()=>this.closeModal()}
                />
            </div>
        );
    }
}

const mapStateToProps = (state) => ({
    incomingLessonInfo: state.Lesson.info || {},
    pendingLesson: state.Core.pendingLesson,
    cancelLessonStatus: state.Auth.cancelLessonStatus,
    tutorNoteStatus: state.Auth.tutorNoteStatus,
});

export default connect(mapStateToProps, { 
    toggleVideoModal, 
    toggleJoinLesson,
    getPendingLessonAction,
    cancelLessonAction,
    tutorNoteAction,
})(withRouter(PendingLessonPage));