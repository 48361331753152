import React from 'react';
import './ScheduleLessonPage.scss';
import search from '../../Assets/Icon/search.svg';
import cancel from '../../Assets/Icon/cancel.svg';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { InputBox, SelectBox, Avatar, TimeZone, ConfirmModal } from '../../Components';
import { TutorList } from './TutorList';
import { ReactSVG } from 'react-svg';
import left from '../../Assets/Icon/left.svg';
import right from '../../Assets/Icon/right.svg';
import success from '../../Assets/Icon/success.png';
import Calendar from 'react-calendar';
import moment from 'moment-timezone';
import _ from 'lodash'
import {
    getMystudentsAction,
    scheduleLessonAction,
    deleteTutorAction,
    getMypofileInfoAction
} from "../../Redux/Actions";
import { toast } from 'react-toastify';

const timeList = [
    {time24: '00:00:00', value: 0},
    {time24: '00:30:00', value: 0},
    {time24: '01:00:00', value: 1},
    {time24: '01:30:00', value: 1},
    {time24: '02:00:00', value: 2},
    {time24: '02:30:00', value: 2},
    {time24: '03:00:00', value: 3},
    {time24: '03:30:00', value: 3},
    {time24: '04:00:00', value: 4},
    {time24: '04:30:00', value: 4},
    {time24: '05:00:00', value: 5},
    {time24: '05:30:00', value: 5},
    {time24: '06:00:00', value: 6},
    {time24: '06:30:00', value: 6},
    {time24: '07:00:00', value: 7},
    {time24: '07:30:00', value: 7},
    {time24: '08:00:00', value: 8},
    {time24: '08:30:00', value: 8},
    {time24: '09:00:00', value: 9},
    {time24: '09:30:00', value: 9},
    {time24: '10:00:00', value: 10},
    {time24: '10:30:00', value: 10},
    {time24: '11:00:00', value: 11},
    {time24: '11:30:00', value: 11},
    {time24: '12:00:00', value: 12},
    {time24: '12:30:00', value: 12},
    {time24: '13:00:00', value: 13},
    {time24: '13:30:00', value: 13},
    {time24: '14:00:00', value: 14},
    {time24: '14:30:00', value: 14},
    {time24: '15:00:00', value: 15},
    {time24: '15:30:00', value: 15},
    {time24: '16:00:00', value: 16},
    {time24: '16:30:00', value: 16},
    {time24: '17:00:00', value: 17},
    {time24: '17:30:00', value: 17},
    {time24: '18:00:00', value: 18},
    {time24: '18:30:00', value: 18},
    {time24: '19:00:00', value: 19},
    {time24: '19:30:00', value: 19},
    {time24: '20:00:00', value: 20},
    {time24: '20:30:00', value: 20},
    {time24: '21:00:00', value: 21},
    {time24: '21:30:00', value: 21},
    {time24: '22:00:00', value: 22},
    {time24: '22:30:00', value: 22},
    {time24: '23:00:00', value: 23},
    {time24: '23:30:00', value: 23},
];

const TutorLengths1 = [
    {id: "1", name: "0.5 hr"},
    {id: "2", name: "1 hr"},
    {id: "3", name: "1.5 hrs"},
    {id: "4", name: "2 hrs"},
    {id: "5", name: "2.5 hrs"},
    {id: "6", name: "3 hrs"},
    {id: "7", name: "3.5 hrs"},
    {id: "8", name: "4 hrs"},
    {id: "9", name: "4.5 hrs"},
    {id: "10", name: "5 hrs"},
    {id: "11", name: "5.5 hrs"},
    {id: "12", name: "6 hrs"},
    {id: "13", name: "6.5 hrs"},
    {id: "14", name: "7 hrs"},
    {id: "15", name: "7.5 hrs"},
    {id: "16", name: "8 hrs"},
];

const TutorLengths2 = [
    {id: "2", name: "1 hr"},
    {id: "3", name: "1.5 hrs"},
    {id: "4", name: "2 hrs"},
    {id: "5", name: "2.5 hrs"},
    {id: "6", name: "3 hrs"},
    {id: "7", name: "3.5 hrs"},
    {id: "8", name: "4 hrs"},
    {id: "9", name: "4.5 hrs"},
    {id: "10", name: "5 hrs"},
    {id: "11", name: "5.5 hrs"},
    {id: "12", name: "6 hrs"},
    {id: "13", name: "6.5 hrs"},
    {id: "14", name: "7 hrs"},
    {id: "15", name: "7.5 hrs"},
    {id: "16", name: "8 hrs"},
];

const TutorRepeats = [
    {id: "1", name: "One time only", value: "0"},
    {id: "2", name: "2 Weeks", value: "2"},
    {id: "3", name: "4 Weeks", value: "4"},
    {id: "4", name: "6 Weeks", value: "6"},
    {id: "5", name: "8 Weeks", value: "8"},
    {id: "6", name: "10 Weeks", value: "10"},
    {id: "7", name: "12 Weeks", value: "12"},
    {id: "8", name: "4 Months", value: "16"},
    {id: "9", name: "6 Months", value: "24"},
];

class ScheduleLessonPage extends React.Component {
    state = {
        Tutors: TutorList,
        searchText: '',
        depth: 0,
        selectStudent: {},
        tutorLengths: TutorLengths1,

        tutor_date: '',
        selected_time: '',

        tutor_length: '0.5 hr',
        tutor_type: '',
        tutor_repeat: 'One time only',
        tutor_note: '',

        current_timezone: moment.tz.guess(true),
        isOpenModal: false,
        availability: []
    }

    componentDidMount() {
        this.props.getMystudentsAction().then(()=>{
            this.props.getMypofileInfoAction().then(()=> {  
                const {myProfileInfo, myStudents} = this.props;
                if(myProfileInfo){
                    this.setState({availability: myProfileInfo.schedule})
                    if (this.props.match.params.tutor_id) {
                        let index = myStudents.findIndex(temp => temp.id === parseInt(this.props.match.params.tutor_id, 10));
                        this.selectTutor(myStudents[index]);
                    } else {
                        if(myStudents && myStudents.length > 0){
                            this.selectTutor(this.props.myStudents[0]);
                        }
                    }
                }
            })
        })
    }

    formatInitialData = (can_book_trial) => {
        this.setState({
            tutor_date: '',
            selected_time: '',

            tutor_length: can_book_trial? '0.5 hr' : '1 hr',
            tutor_type: '',
            tutor_repeat: 'One time only',
            tutor_note: ''
        })
    }

    static getDerivedStateFromProps(props, state) {
        return {
            depth: props.depth
        }
    }

    selectTutor = async (student) => {
        let temp = student || {};
        this.setState({ 
            selectStudent: temp,
        });
        this.formatInitialData(student.can_book_trial);
        await this.props.formatTitle();
        this.props.updateTitle('push', 'Schedule a lesson', 2);

        if(student.can_book_trial){
            this.setState({tutorLengths: TutorLengths1});
        } else {
            this.setState({tutorLengths: TutorLengths2});
        };
    }

    deleteTutor = (e, item, index) => {
        e.stopPropagation();
        // let temp = this.state.Tutors;
        // let index = temp.findIndex(a => a.id === item.id)
        // temp.splice(index, 1);
        // this.setState({
        //     Tutors: temp,
        // })
        // if (item.id === this.state.selectStudent.id) {
        //     this.formatInitialData();
        //     this.props.formatTitle();
        // }
        this.setState({isOpenModal: true});
        this.setState({delectE: e});
        this.setState({delectItem: item});
        this.setState({delectIndex: index});

    }

    deleteConfirm =(deleteStatus) => {
        this.setState({isOpenModal: false});
        this.setState({loading: true})
        const {delectE, delectItem, delectIndex} = this.state
        if(deleteStatus){
            this.props.loader(true)
            delectE.stopPropagation();
            this.props.deleteTutorAction(delectItem.id).then(() => {
                this.props.loader(false)

                if(this.props.deleteTutorStatus){
                    this.props.mytutors.splice(delectIndex, 1);
                    this.setState({loading: false})
                    if (delectItem.id === this.state.selectedTutor.id) {
                        this.setState({ 
                            selectedTutor: {},
                        });
                        this.props.formatTitle();
                    }
                }
            });
        }
    }

    selectDate = (date) => {
        if(Math.floor((new Date() - new Date(date))) / 1000 <= 24*3600 ){
            this.setState({ 
                tutor_date : date,
                selected_time: '',
            })
        } else {
            this.setState({ 
                tutor_date : '',
                selected_time: '',
            })
        }
    }

    submit = () => {
        this.props.loader(true);

        if(this.state.selectStudent.id){
            const formData = new FormData()
            formData.append('student_id', this.state.selectStudent.id);
            formData.append('sched', `${moment(this.state.tutor_date).format('YYYY-MM-DD')} ${this.state.selected_time}`);
            formData.append('timezone', moment.tz(moment.utc(), this.state.current_timezone).utcOffset()/60);
            formData.append('hours', Number(this.state.tutor_length.split(' ')[0]));
            // formData.append('repeat_until', _.find(TutorRepeats, {name: this.state.tutor_repeat}).value);     
            formData.append('client_note', this.state.tutor_note);
            this.props.scheduleLessonAction(formData).then(() => {
                if(this.props.scheduleLessonStatus){
                    this.props.loader(false);
                    this.props.updateTitle('push', 'Schedule a lesson', 4)
                } else {
                    this.props.loader(false);
                    toast(this.props.scheduleLessonErr)
                }
            })
        } else {
            toast("Please select student!")
        }
    }

    render() {
        const {myStudents} = this.props;
        const { searchText, depth, selectStudent, Tutors, tutor_date, selected_time, tutor_length, tutor_type, tutor_repeat, tutor_note, current_timezone, availability, tutorLengths } = this.state;
        const list = myStudents.filter(tutor => tutor.name.toLowerCase().indexOf(searchText.toLowerCase()) > -1);
        return (
            <div className="schedulelesson-page">
                <div className={`tutor-list v-r ${depth === 0 ? 'activate' : ''}`}>
                    <InputBox className="search-contact" value={searchText} icon={search} placeholder="Search..." onChange={val=>this.setState({searchText:val})} />
                    <div className="tutors v-r scroll-area">
                        {list.map((item, index) => <div className={`tutor-container ${selectStudent.id === item.id ? 'selected' : ''}`} key={index} onClick={e => this.selectTutor(item)}>
                            <div className="tutor v-c">
                                <Avatar className="avatar" info={item} />
                                <div className="info v-c">
                                    <h4>{item.name}</h4>
                                    {/* <p>{item.school}</p> */}
                                </div>
                                {/* <ReactSVG src={cancel} className="svg-icon" onClick={e => this.deleteTutor(e, item)} /> */}
                            </div>
                        </div>)}
                    </div>
                </div>
                <div className={`content-area scroll-area v-r ${depth >= 1 ? 'activate' : ''}`}>
                    {depth === 1 && <div className="date-content">
                        <div className="date-select-area v-r">
                            <Calendar
                                className="calendar"
                                onChange={this.selectDate}
                                value={tutor_date || new Date()}
                                calendarType="US"
                                prevLabel={<ReactSVG src={left} className="svg-icon" />}
                                nextLabel={<ReactSVG src={right} className="svg-icon" />}
                            />
                            <TimeZone value={current_timezone} onChange={val => this.setState({current_timezone: val})}/>
                        </div>
                        {tutor_date.toString().length > 0 && <div className="time-select v-r">
                            <div className="time-header v-c v-r">
                                <h1>{moment(tutor_date).format('dddd, MMMM D, YYYY')}</h1>
                                <p>Please select start time</p>
                            </div>
                            <div className="time-list v-r">
                                {timeList.map((item, index) => 
                                _.find(availability, {day: moment(tutor_date).format("ddd"), hour: item.value})?
                                    null
                                    :
                                    <div className={`time-container v-c ${selected_time === item.time24 ? 'active' : ''}`} key={index}>
                                        <div className="time-item sub-item v-c h-c" onClick={e => this.setState({selected_time: item.time24})}>{moment(item.time24, ["HH:mm"]).format("hh:mm A")}</div>
                                        <div className="confirm-item sub-item v-c h-c" onClick={e => this.props.updateTitle('push', 'Schedule a lesson', 3)}>Confirm</div>
                                    </div>
                                )}
                            </div>
                        </div>}
                    </div>}
                    {depth >= 2 && <div className="schedule-options v-r">
                        <div className="selected-date v-c">
                            <ReactSVG src={left} className="svg-icon show-web-flex" onClick={e => this.props.updateTitle('pop')} />
                            <h2>{moment(tutor_date).format('dddd, MMMM D')}, Start Time: {moment(selected_time, ["HH:mm A"]).format("hh:mm A")}, {current_timezone}</h2>
                        </div>
                        {depth === 2 && <div className="option-list v-r">
                            <div className="option-group v-r">
                                <h2 className="option-title">Length of tutoring session</h2>
                                <SelectBox className="option-select" value={tutor_length} options={tutorLengths} onChange={val => this.setState({tutor_length: val.name})}/>
                            </div>
                            {/* <div className="option-group v-r">
                                <h2 className="option-title">Repeat this schedule to the next</h2>
                                <SelectBox className="option-select" value={tutor_repeat} options={TutorRepeats} onChange={val => this.setState({tutor_repeat: val.name})}/>
                            </div> */}
                            <div className="option-group v-r">
                                <h2 className="option-title">Note for tutor (optional)</h2>
                                <textarea className="option-note" value={tutor_note} onChange={e => this.setState({tutor_note: e.target.value})} resize="none" placeholder=""/>
                            </div>
                            <button 
                                className="btn-2 primary-btn submit-btn" 
                                onClick={e => this.submit()}>
                                Submit
                            </button>
                        </div>}
                        {depth === 3 && <div className="success-area v-c h-c">
                            <div className="success-content v-r h-c v-c">
                                <h2>Success!</h2>
                                <img src={success} alt="success" />
                                <p>Thanks for scheduling a lesson. Your student will be notify by email and SMS.</p>
                            </div>
                        </div>}
                    </div>}
                </div>
                <ConfirmModal
                    isOpenModal={this.state.isOpenModal}
                    deleteConfirm={(e) => this.deleteConfirm(e)}
                    cancelTitle = "Delete this Student?"

                />
            </div>
        );
    }
}

const mapStateToProps = (state) => ({
    myStudents: state.Core.myStudents,
    scheduleLessonStatus: state.Auth.scheduleLessonStatus,
    scheduleLessonErr: state.Auth.scheduleLessonErr,    
    deleteTutorStatus: state.Auth.deleteTutorStatus,
    myProfileInfo: state.Core.myProfileInfo,
});

export default connect(mapStateToProps, {
    getMystudentsAction,
    scheduleLessonAction,
    deleteTutorAction,
    getMypofileInfoAction,
})(withRouter(ScheduleLessonPage));
