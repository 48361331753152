import React from 'react';
import './AutoChargePage.scss';
import { CheckBox } from '../../Components';

export default class AutoChargePage extends React.Component { 
    state = {
        checked: false,
        saveText: 'Save Changes'
    }

    saveChange = () => {
        this.setState({saveText: 'Saved'});
        setTimeout(() => {
            this.setState({saveText: 'Save Changes'});
        }, 1000);
    }

    render() {
        const { checked, saveText } = this.state;
        return (
            <div className="autocharge-page v-r v-c h-c">
                <p> When your frogtutoring credit runs low, you can enable Frogtutoring to charge your credit card when your sessions finish. So you can continue uninterrupted. </p>
                <div className="check-area">
                    <CheckBox className="check-box" checked={checked} onChange={val => this.setState({checked: val})} />
                    <div className="explain">Yes, I want GradeGetter to be able to auto charge my credit card.</div>
                </div>
                <button className="btn-2 btn-2-outline save-btn" onClick={e => this.saveChange()}>{saveText}</button>
            </div>
        );
    }
}