/* @flow */

import { combineReducers } from 'redux';

import Sidebar from './Sidebar';
import Modal from './Modal';
import Lesson from './Lesson';
import Authentication from './Authentication';
import Other from './Other';
import Core from "./Core";
import Auth from "./Auth";

export default combineReducers ({
    Sidebar,
    Modal,
    Lesson,
    Authentication,
    Other,
    Auth,
    Core
})