import { JOIN_LESSON_TOGGLE } from '../Type';

const INITIAL = {
  isOpen: false,
}

export default (state = INITIAL, action) => {
  switch (action.type) {
    case JOIN_LESSON_TOGGLE: {
      const { isOpen, info } = action.data;
      return { ...state, isOpen, info };
    }

    default:
      return state;
  }
}