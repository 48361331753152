import React from 'react';
import PhoneInput from 'react-phone-input-2'
import 'react-phone-input-2/lib/style.css'
import './PhoneNumberInput.scss';
import { ReactSVG } from 'react-svg'

export default class PhoneNumberInput extends React.Component {
    state = {
        value: ''
    }

    componentDidMount() {
        this.setState({
            value: this.props.value || ''
        })
    }

    changeText = (phone) => {
        this.setState({
            value: phone
        })
        this.props.onChange(phone);
    }

    render() {
        const { className, icon, placeholder, type, phone } = this.props;
        const { value } = this.state;
        return (
            <div className={`inputbox-component ${className || ''}`}>
                <div className={`phoneInput-container`}>
                    {icon && <ReactSVG src={icon} style={{fill: '#C5CBD7'}}/> }
                    <PhoneInput
                        placeholder="dddd"
                        inputClass="w-100 phone"    
                        dropdownClass="drop-down"
                        buttonClass="buttonClass"
                        dropdownClass="dropdown"       
                        country={'us'}
                        value={phone || value}
                        onChange={e => this.changeText(e)}
                    />
                </div>
            </div> 
        );
    }
}
