export const Menus = [
    'PROFILE', 'SUBJECTS', 'EDUCATION', 'QUALIFICATIONS', 'AVAILABILITY', 'PASSWORD'
]

export const HelpParams = [
    'available', 'unavailable'
];

export const Hours = [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23];

export const WeekData = ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'];

export const degrees = [
    {id: 0, name: ''},
    {id: 1, name: 'Bachelor'},
    {id: 2, name: 'Masters'},
    {id: 3, name: 'PhD'},
    {id: 4, name: 'Dr'},
]

export const PhoneCarriers = [
    {id:  0, name: 'No carrier'},
    {id:  1, name: 'Alltel'},
    {id:  2, name: 'AT&T'},
    {id:  3, name: 'Boost Mobile'},
    {id:  4, name: 'Bellsouth'},
    {id:  5, name: 'CellularOne'},
    {id:  6, name: 'Cingular'},
    {id:  7, name: 'Edge Wireless'},
    {id:  8, name: 'Nextel'},
    {id:  9, name: 'Sprint PCS'},
    {id: 10, name: 'T-Mobile'},
    {id: 11, name: 'Metro PCS'},
    {id: 12, name: 'O2'},
    {id: 13, name: 'Orange'},
    {id: 14, name: 'Qwest'},
    {id: 15, name: 'Rogers Wireless'},
    {id: 16, name: 'US Cellular'},
    {id: 17, name: 'Verizon'},
    {id: 18, name: 'Virgin Mobile'},
    {id: 19, name: 'Cricket'},
    {id: 20, name: 'Consumer Cellular'},
    {id: 21, name: 'Airfire Mobile'},
    {id: 22, name: 'Alaska Communications'},
    {id: 23, name: 'Ameritech'},
    {id: 24, name: 'Bluegrass Cellular'},
    {id: 25, name: 'Cellcom'},
    {id: 26, name: 'Cellular South'},
    {id: 27, name: 'Chariton Valley Wireless'},
    {id: 28, name: 'Cleartalk'},
    {id: 29, name: 'C Spire Wireless'},
    {id: 30, name: 'Element Mobile'},
    {id: 31, name: 'Esendex'},
    {id: 32, name: 'Kajeet'},
    {id: 33, name: 'LongLines'},
    {id: 34, name: 'Nextech'},
    {id: 35, name: 'Nextel'},
    {id: 36, name: 'Page Plus Cellular (Verizon MVNO)'},
    {id: 37, name: 'Pioneer Cellular'},
    {id: 38, name: 'Qwest Wireless'},
    {id: 39, name: 'Red Pocket Mobile (AT&T MVNO)'},
    {id: 40, name: 'Simple Mobile'},
    {id: 41, name: 'Southernlinc'},
    {id: 42, name: 'South Central Communications'},
    {id: 43, name: 'Straight Talk'},
    {id: 44, name: 'Syringa Wireless'},
    {id: 45, name: 'Teleflip'},
    {id: 46, name: 'Unicel'},
    {id: 47, name: 'USA Mobility'},
    {id: 48, name: 'Viaero'},
    {id: 49, name: 'West Central Wireless'},
    {id: 50, name: 'XIT Communications'},
    {id: 51, name: 'TracFone (prepaid)'},
    {id: 52, name: 'Centennial Wireless'},
    {id: 53, name: 'Union Wireless'},
    {id: 54, name: 'Virgin Mobile'},
    {id: 55, name: 'Telstra'},
    {id: 56, name: 'SunCom'},
    {id: 57, name: 'Intercel'},
    {id: 58, name: 'Mid Missouri Wireless'},
    {id: 59, name: 'Midwest Wireless'},
    {id: 60, name: 'MTA Wireless'},
    {id: 61, name: 'Plateau Wireless'},
    {id: 62, name: 'Rural Cellular Corporation'},
    {id: 63, name: 'Alaskan Communications Services'},
    {id: 64, name: 'Consumer Cellular'}
];