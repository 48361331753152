import React from 'react';
import './WithdrawPage.scss';
import { InputBox, SelectBox } from '../../Components';
import left from '../../Assets/Icon/arrow-left.png';
import Slider from 'rc-slider';
import 'rc-slider/assets/index.css';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import {
    updateBankAction,
    withdrawMoneyAction,
    getMypofileInfoAction,
    getBankDetailAction
} from "../../Redux/Actions";
import { toast } from 'react-toastify';
import _ from 'lodash'

const Months = [
    {id: 1, name: 'January'}, 
    {id: 2, name:'February'}, 
    {id: 3, name:'March'}, 
    {id: 4, name:'April'}, 
    {id: 5, name:'May'}, 
    {id: 6, name:'June'}, 
    {id: 7, name:'July'}, 
    {id: 8, name:'August'},
    {id: 9, name:'September'},
    {id: 10, name:'October'},
    {id: 11, name: 'November'},
    {id: 12, name:'December'}
];
const Days = [
    {id: 1, name: 1},    
    {id: 2, name: 2},    
    {id: 3, name: 3},    
    {id: 4, name: 4},    
    {id: 5, name: 5},    
    {id: 6, name: 6},    
    {id: 7, name: 7},    
    {id: 8, name: 8},    
    {id: 9, name: 9},    
    {id: 10, name: 10},    
    {id: 11, name: 11},    
    {id: 12, name: 12},    
    {id: 13, name: 13},    
    {id: 14, name: 14},    
    {id: 15, name: 15},    
    {id: 16, name: 16},    
    {id: 17, name: 17},    
    {id: 18, name: 18},    
    {id: 19, name: 19},    
    {id: 20, name: 20},    
    {id: 21, name: 21},    
    {id: 22, name: 22},    
    {id: 23, name: 23},    
    {id: 24, name: 24},    
    {id: 25, name: 25},    
    {id: 26, name: 26},    
    {id: 27, name: 27},    
    {id: 28, name: 28},    
    {id: 29, name: 29},    
    {id: 30, name: 30},    
    {id: 31, name: 31},    
];

const Account_types = [
    {id: 1, name: 'CHECKING'},
    {id: 1, name: 'SAVINGS '},
]

class WithdrawPage extends React.Component {
    state = {
        depth: null,
        YEARS: [],

        month: 'January',
        selected_month: 1,
        day: 1,
        year: new Date().getFullYear() - 20,
        address: '',
        zip_code: '',
        city: '',
        state: '',
        name: '',

        routing_number: '',
        account_number: '',
        account_type: 'CHECKING',

        withdraw_amount: '0',
        max_withdraw_amount: ''
    }

    componentDidMount() {
        let currentYear = new Date().getFullYear();
        let temp = [];
        for (let i = 0; i <= 80; i++) {
            temp.push({id: i, name: currentYear - i});
        }
        this.setState({
            YEARS: temp
        });

        this.props.getBankDetailAction().then(()=> {
            const { bankDetail } = this.props;
            if(bankDetail && !_.isEmpty(bankDetail)){
                this.setState({
                    depth: '2',
                    routing_number: bankDetail.routing,
                    account_number: bankDetail.bankAccountId,
                    account_type: bankDetail.bankAccountPurpose,

                })
            } else {
                this.setState({
                    depth: '0',
                })
            }
        });

        this.props.getMypofileInfoAction().then(()=> {
            const { myProfileInfo } = this.props;
            this.setState({
                name: myProfileInfo.name,
                address: myProfileInfo.address,
                city: myProfileInfo.city,
                state: myProfileInfo.state,
                zip_code: myProfileInfo.address_zipcode,
                max_withdraw_amount: Number(myProfileInfo.credit) > 1? Number(myProfileInfo.credit)-1 : 0
            });
        });
    }

    // static getDerivedStateFromProps(props, state) {
    //     if(props.depth == 1){
    //         bankUpdate()
    //     }
    //     return {
    //         depth: props.depth
    //     };
    // }

    moveToNextStep = (depth) => {
        this.props.updateTitle('push', 'Withdraw');
        if(depth === '2'){
            this.bankUpdate()
        } else {
            this.setState({depth});
        }
    }

    handle = (props) => {
        const { value, dragging, index, ...restProps } = props;
        const Handle = Slider.Handle;
        return (
            <Handle value={value} {...restProps}>
                <div className="inner-handle" />
            </Handle>
        );
    };

    bankUpdate(){
        this.props.loader(true);
        const { selected_month, day, year, address, city, state, zip_code, routing_number, account_number, account_type, withdraw_amount, } = this.state;
        const formData = new FormData();  
        formData.append('date_of_birth', `${year}-${selected_month}-${day}`);
        formData.append('address', address);
        formData.append('city', city);
        formData.append('state', state);
        formData.append('zipcode', zip_code);
        formData.append('bank_account', account_number);
        formData.append('routing', routing_number);
        formData.append('account_type', account_type);
        this.props.updateBankAction(formData).then(()=> {
            this.props.loader(false);
            const { updateBankStatus, updateBankErr } = this.props;
            if(updateBankStatus){
                toast(updateBankStatus);
                this.setState({depth: '2'})
            } else {
                toast(updateBankErr);
            }
        })
    }

    withdraw = () => {
        this.props.loader(true);
        const formData = new FormData();  
        formData.append('amount', this.state.withdraw_amount);
        this.props.withdrawMoneyAction(formData).then(()=> {
            this.props.loader(false);
            const { withdrawMoneyStatus, withdrawMoneyErr } = this.props;
            if(withdrawMoneyStatus){
                toast(withdrawMoneyStatus)
            } else {
                toast(withdrawMoneyErr)
            }
        })
    }

    render() {
        const { depth, name, selected_month, month, day, year, YEARS, address, city, state, zip_code, routing_number, account_number, account_type, withdraw_amount, max_withdraw_amount } = this.state;
        return (
            <div className="withdraw-page v-r v-c">
                {depth === '0' && <div className="sub-section v-r" id="section-1">
                    <h3>Register Your Information</h3>
                    <p className="section-info name">Name: <b>{name}</b></p>
                    <div className="option-list v-r">
                        <div className="option-group v-r">
                            <h2 className="option-title">Birthdate</h2>
                            <div className="date-area">
                                <SelectBox className="option-select" value={month} options={Months} onChange={val => this.setState({month: val.name, selected_month: val.id})}/>
                                <SelectBox className="option-select" value={day} options={Days} onChange={val => this.setState({day: val.name})}/>
                                {YEARS.length > 0 && <SelectBox className="option-select" value={year} options={YEARS} onChange={val => this.setState({year: val.name})}/>}
                            </div>
                        </div>
                        <div className="option-row">
                            <div className="option-group v-r">
                                <h2 className="option-title">Address</h2>
                                <InputBox value={address} placeholder="Type your address" onChange={val => this.setState({address: val})}/>
                            </div>
                            <div className="option-group v-r">
                                <h2 className="option-title">City</h2>
                                <InputBox value={city} placeholder="Type your city" onChange={val => this.setState({city: val})}/>
                            </div>
                        </div>
                        <div className="option-row">
                            <div className="option-group v-r">
                                <h2 className="option-title">State</h2>
                                <InputBox value={state} placeholder="Type your state" onChange={val => this.setState({state: val})}/>
                            </div>
                            <div className="option-group v-r">
                                <h2 className="option-title">Zip Code</h2>
                                <InputBox value={zip_code} placeholder="Type your zip code" onChange={val => this.setState({zip_code: val})}/>
                            </div>
                        </div>
                    </div>
                    {/* <p className="section-info name">City: <b>Kansas City, KS</b></p> */}
                    {(address && city && state && zip_code) ?
                        <button className="btn-2 primary-btn" onClick={e => this.moveToNextStep('1')}>Submit</button>
                        :
                        <button className="btn-2">Submit</button>
                    }
                </div>}
                {depth === '1' && <div className="sub-section v-r" id="section-1">
                    <img src={left} className="svg-icon back-icon" onClick={e =>this.setState({depth: '0'})}/> 
                    <h3>Add Your Bank Account</h3>
                    <div className="option-list v-r">
                        <div className="option-group v-r">
                            <h2 className="option-title">Routing Number (9 digits)</h2>
                            <InputBox value={routing_number} placeholder="Type routing number (9 digits)" type="number" onChange={val => this.setState({routing_number: val})}/>
                        </div>
                        <div className="option-group v-r">
                            <h2 className="option-title">Bank Account Number</h2>
                            <InputBox value={account_number} placeholder="Type bank account number" type="number" onChange={val => this.setState({account_number: val})}/>
                        </div>
                        <div className="option-group v-r">
                            <h2 className="option-title">Account Type</h2>
                            <SelectBox className="option-select" value={account_type} options={Account_types} onChange={val => this.setState({account_type: val.name})}/>
                        </div>
                    </div>
                    {(routing_number && account_number) ?
                        <button className="btn-2 primary-btn" onClick={e => this.moveToNextStep('2')}>Submit</button>
                        :
                        <button className="btn-2" >Submit</button>
                    }
                </div>}
                {depth === '2' && <div className="sub-section v-r" id="section-1">
                    <h3>Bank Account</h3>
                    <p className="section-info">Status: <b>Activated</b></p>
                    
                    <p className="section-info name">Account #: <b>{account_number} (routing #: {routing_number})</b> </p>
                    <div className="edit-btn" onClick={e =>this.setState({depth: '0'})}>Edit</div>
                    
                    <p className="sub-title">Withdraw</p>

                    <p>
                        There is a $1 fee to withdraw. Please be aware it can take up to three business days for the money to get to your bank account<br />
                        {/* Payment processing is done with HyperWallet. To read HyperWallet’s legal terms of service and privacy policy, please click here. */}
                    </p>
                    <Slider 
                        min={0} 
                        max={max_withdraw_amount} 
                        value={withdraw_amount}
                        handle={(props) => this.handle(props)} 
                        onChange={e => this.setState({withdraw_amount: e})}>
                    </Slider>
                    <div className="option-list v-r">
                        <div className="option-group v-r">
                            <h2 className="option-title">Withdraw amount : ${withdraw_amount}</h2>
                            {/* <InputBox value={withdraw_amount} placeholder="Type withdraw amount" type="number" onChange={val => this.setState({withdraw_amount: val})}/> */}
                        </div>
                    </div>
                    { withdraw_amount > 0 ?
                        <button className="btn-2 primary-btn" onClick={e => this.withdraw()}>Withdraw</button>
                        :
                        <button className="btn-2">Withdraw</button>
                    }
                    <p>Payment proccessing is done by HyperWallet. To read HW legal terms of service and privacy policy, <a href="https://www.hyperwallet.com/privacy-policy/" target="_blank" rel="noopener noreferrer">please click</a></p>
                </div>}
            </div>
        );
    }
}

const mapStateToProps = (state) => ({
    updateBankStatus: state.Auth.updateBankStatus,
    updateBankErr: state.Auth.updateBankErr,
    withdrawMoneyStatus: state.Auth.withdrawMoneyStatus,
    withdrawMoneyErr: state.Auth.withdrawMoneyErr,
    myProfileInfo: state.Core.myProfileInfo,
    bankDetail: state.Core.bankDetail,
});

export default connect(mapStateToProps, {
    updateBankAction,
    withdrawMoneyAction,
    getMypofileInfoAction,
    getBankDetailAction
})(withRouter(WithdrawPage));