import React from 'react';
import './App.scss';
import { Sidebar, MobileSidebar, VideoModal, JoinLesson, CreditModal, QualificationModal, Loader} from './Components';
import { 
  MyClientsPage, RatePlanPage, AutoChargePage, CreditCardPage, ReceiptPage, ScheduleLessonPage, SettingsPage, MessagePage, CompletedLessonPage, PendingLessonPage, 
  AlertsPage, TrainingPage, PaymentHistoryPage, BiweeklyReportPage, WithdrawPage, BlogPage, BlockSchedulePage, OnlineDemoPage, HelpPage, ViralPage, PortToFrogtutoringPage
} from './Pages';
import Page from './Pages/Page';
import { BrowserRouter, Switch, Route } from "react-router-dom";
import { connect } from 'react-redux';
import { ToastContainer} from 'react-toastify';
import { Helmet } from 'react-helmet';
import 'react-toastify/dist/ReactToastify.css';
// import Pusher from 'pusher-js';
// import {NotificationContainer, NotificationManager} from 'react-notifications';
// import 'react-notifications/lib/notifications.css';
// var pusher = new Pusher('cad8fda894e2bdb5f537', {
//   cluster: 'mt1',
//   forceTLS: true,
//   authEndpoint: 'https://api.gradegetter.com/broadcasting/auth'
// });

// var channel = pusher.subscribe('private-App.User.4');
// channel.bind('LessonStartSoon', function(data) {
//   // alert(JSON.stringify(data));
//   console.log("===============================JSON.stringify(data)=======================", data)
//   console.log("==================res.status=========================", data.status)
// });

const pages = [
  { url: '/', title: 'Training', page: props => <TrainingPage {...props} /> },
  { url: '/clients', title: 'My Clients', page: props => <MyClientsPage {...props} /> },
  { url: '/clients/:id', title: 'My Clients', page: props => <MyClientsPage {...props} /> },
  { url: '/rate-plan', title: 'Rate Plan', page: props => <RatePlanPage {...props} /> },
  { url: '/auto-charge', title: 'Auto Charge Settings', page: props => <AutoChargePage {...props} /> },
  { url: '/credit-card', title: 'Credit Card', page: props => <CreditCardPage {...props} /> },
  { url: '/receipt', title: 'Receipt', page: props => <ReceiptPage {...props} /> },
  { url: '/receipt/:id', title: 'Receipt', page: props => <ReceiptPage {...props} /> },
  { url: '/schedule-lesson', title: 'Schedule a lesson', page: props => <ScheduleLessonPage {...props} /> },
  { url: '/schedule-lesson/:tutor_id', title: 'Schedule a lesson', page: props => <ScheduleLessonPage {...props} /> },
  { url: '/profile', title: 'My Settings', page: props => <SettingsPage {...props} /> },
  { url: '/message', title: 'Message', page: props => <MessagePage {...props} /> },
  { url: '/completed-lesson', title: 'Completed Lessons', page: props => <CompletedLessonPage {...props} /> },
  { url: '/completed-lesson/:id', title: 'Completed Lessons', page: props => <CompletedLessonPage {...props} /> },
  { url: '/pending-lesson', title: 'Pending Lessons', page: props => <PendingLessonPage {...props} /> },
  { url: '/pending-lesson/:id', title: 'Pending Lessons', page: props => <PendingLessonPage {...props} /> },
  { url: '/alerts', title: 'Alerts', page: props => <AlertsPage {...props} />},
  { url: '/alerts/:id', title: 'Alerts', page: props => <AlertsPage {...props} />},
  { url: '/training', title: 'Training', page: props => <TrainingPage {...props} />},
  { url: '/payment-history', title: 'Payment History', page: props => <PaymentHistoryPage {...props} />},
  { url: '/biweekly-report', title: 'Biweekly Report', page: props => <BiweeklyReportPage {...props} />},
  { url: '/withdraw', title: 'Withdraw', page: props => <WithdrawPage {...props} />},
  { url: '/blog', title: 'Blog', page: props => <BlogPage {...props} />},
  { url: '/block-schedule', title: 'Block Schedule', page: props => <BlockSchedulePage {...props} />},
  { url: '/online-demo', title: 'Online Demo', page: props => <OnlineDemoPage {...props} />},
  { url: '/help', title: 'Help', page: props => <HelpPage {...props} />},
  { url: '/viral', title: 'Viral', page: props => <ViralPage {...props} />},
  { url: '/port-to-frogtutoring', title: 'Port To Frogtutoring', page: props => <PortToFrogtutoringPage {...props} />},
];

class App extends React.Component {
  state = {
    isBlur: false,
    isSidebarOpen: true,
    isSidebarPrevOpen: true,
    isResize: false,
    loading: false
  }

  static getDerivedStateFromProps(props, state) {
    return {
      isBlur: props.isMobileSidebarOpen || props.isModalOpen,
      isSidebarOpen: props.isSidebarOpen,
      isSidebarPrevOpen: state.isSidebarOpen,
      isResize: props.isJoinLessonOpen
    }
  }

  loading = (value) => {
    this.setState({loading: value})
  }

  componentDidMount(){
    // const token = localStorage.getItem('token');
    // console.log("token", token)
  }

  render() {
    const { isBlur, isSidebarOpen, isSidebarPrevOpen, isResize } = this.state;

    if (isBlur) {
      if (document.body) {
        const body = document.body
        body.classList.add('modal-open');
      }
    } else {
      if (document.body) {
        const body = document.body
        body.classList.remove('modal-open');
      }
    }
    
    return (
      <BrowserRouter basename="/">
        <div className="App">
        <Loader loading={this.state.loading}/>
          <JoinLesson />
          <div id="main" className={`${isBlur? 'blur': ''} ${isResize ? 'resize' : ''}`}>
            <MobileSidebar />
            <Helmet>
              <title>
                Access Hundreds Of Online Tutoring Jobs | GradeGetter
              </title>
              <meta
                property="description"
                content="Become A Tutor In Under 30 Minutes And Access Hundreds Of Online Tutoring Jobs For K-12, Test Prep, and College"
              />

              <meta
                itemprop="name"
                content="Access Hundreds Of Online Tutoring Jobs | GradeGetter"
              />
              <meta
                itemprop="description"
                content="Become A Tutor In Under 30 Minutes And Access Hundreds Of Online Tutoring Jobs For K-12, Test Prep, and College"
              />
              <meta
                itemprop="keywords"
                content="online tutoring, Math tutors, private tutors"
              />

              <meta name="twitter:card" content="summary" />
              <meta
                name="twitter:title"
                content="Access Hundreds Of Online Tutoring Jobs | GradeGetter"
              />
              <meta
                name="twitter:description"
                content="Become A Tutor In Under 30 Minutes And Access Hundreds Of Online Tutoring Jobs For K-12, Test Prep, and College"
              />

              <meta
                property="og:title"
                content="Access Hundreds Of Online Tutoring Jobs | GradeGetter"
              />
              <meta
                property="og:description"
                content="Become A Tutor In Under 30 Minutes And Access Hundreds Of Online Tutoring Jobs For K-12, Test Prep, and College"
              />
              <meta property="og:image" content="https://tutor.gradegetter.com/gradegetter.png" />
              <meta property="og:url" content={window.location.origin} />
              <meta
                property="og:site_name"
                content="Access Hundreds Of Online Tutoring Jobs | GradeGetter"
              />
              <meta property="og:locale" content="en_US" />
              <meta property="og:type" content="website" />
            </Helmet>
            <div className={`content-wrapper ${isSidebarOpen ? isSidebarPrevOpen ? '' : 'open' : 'closed'}`}>
              <Switch>
                {pages.map((item, index) => <Route exact path={item.url} key={index} render={(props) => <Page sendLoading={(v)=>this.loading(v)} title={item.title} page={item.page} />} />)}
              </Switch>
            </div>
            <Sidebar />
            <VideoModal />
            <CreditModal />
            <QualificationModal />
          </div>
        </div>
        <ToastContainer />
      </BrowserRouter>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    isMobileSidebarOpen: state.Sidebar.isMobileOpen,
    isSidebarOpen: state.Sidebar.isOpen,
    isModalOpen: state.Modal.isVideoModalOpen || state.Modal.isCreditModalOpen || state.Modal.isReviewModalOpen || state.Modal.isQualificationModalOpen,
    isJoinLessonOpen: state.Lesson.isOpen,
  }
}

export default connect(mapStateToProps, {})(App);
